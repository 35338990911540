<template>
	<div id="app">
		<div class="topNav"  :class="setBackgroundColor($PayNameImg)" v-if="!this.$route.meta.noNav">
			<div class="navbar flexX flexcenter">
				<!-- <div class="logo" @click="showDataAll">数据总览</div> -->
				<div class="logo" @click="showDataAll">
					<span v-if="$PayNameImg == 'mark'"><i class="el-icon-coin cdanger"></i> MarkPay</span>
					<span v-else-if="$PayNameImg == 'rb'"><i class="el-icon-coin cdanger"></i> RbPay</span>
					<span v-else-if="$PayNameImg == 'bitong'"><i class="el-icon-coin cdanger"></i> 币通</span>
					<span v-else-if="$PayNameImg == 'qixing'"><i class="el-icon-coin cdanger"></i> 柒星</span>
					<span v-else-if="$PayNameImg == 'aipay'"><i class="el-icon-coin cdanger"></i> AiPay</span>
					<span v-else><i class="el-icon-coin cdanger"></i> Ttpay</span>
					
				</div>
				<!-- <div style="padding: 0 15px;" @click="toggleClick">
					<div class="hamburger" :class="{'is-active':showSide==='hide'}">
						<div class="icon"></div>
					</div>
				</div> -->
			</div>
			<div class="systemCount " v-if="false">
				<span class="mr20 pointer" @click="gotopageDifference()"><span class="cnavTop">差值卡：</span><span class="cwarning fs20">{{systemcount.card_money_diff_count?systemcount.card_money_diff_count:'0'}}</span></span>
				<span class="mr20 pointer" @click="gotopageorderbankCard()"><span class="cnavTop">掉线卡：</span><span class="cwarning fs20">{{systemcount.offline_card_count?systemcount.offline_card_count:'0'}}</span></span>
				<!-- <span class="mr20"><span class="cnavTop">交易订单未自动关闭数：</span><span class="cwarning fs20">{{systemcount.order_count?systemcount.order_count:'0'}}</span></span> -->
				<span class="mr20 pointer" @click="gotopageorderPayMain()"><span class="cnavTop">代付主订单待处理：</span><span class="cwarning fs20">{{systemcount.repay_order_count?systemcount.repay_order_count:'0'}}</span></span>
				<span class="mr20 pointer" @click="gotopageorderPayMain()"><span class="cnavTop">代付子订单未取卡：</span><span class="cwarning fs20">{{systemcount.sub_repay_order_count?systemcount.sub_repay_order_count:'0'}}</span></span>
				<span class="mr20 pointer" @click="gotopageOwnOrderPay()"><span class="cnavTop">手动代付待处理：</span><span class="cwarning fs20">{{systemcount.manual_repay_order_count?systemcount.manual_repay_order_count:'0'}}</span></span>
				<span class="mr20 pointer" @click="gotopagePayNot()"><span class="cnavTop">代付停用卡：</span><span class="cwarning fs20">{{systemcount.repay_card_disable_count?systemcount.repay_card_disable_count:'0'}}</span></span>
				<!-- <span class="mr20 pointer" @click="gotopageWithdraw()"><span class="cnavTop">搬砖工提润待审核：</span><span class="cwarning fs20">{{systemcount.carder_tx_money_order_count?systemcount.carder_tx_money_order_count:'0'}}</span></span> -->
				<!-- <span class="mr20 pointer" @click="gotopageOwnOrderPay()"><span class="cnavTop">手动代付待审核：</span><span class="cwarning fs20">{{systemcount.trader_repay_order_count?systemcount.trader_repay_order_count:'0'}}</span></span> -->
				<span class="mr20 pointer"><span class="cnavTop">连续失败10次的卡：</span><span class="cwarning fs20">{{systemcount.card_fail_count?systemcount.card_fail_count:'0'}}</span></span>
			</div>
			<div class="flexX flexcenter">
				<span class="mr20 username pointer" v-if="showTirun" @click="gotoPageTirun">平台提润</span>
				<!-- <span class="mr20 username">账号：{{username}}</span> -->
				<span class="mr40 username flexX flexcenter"><el-avatar size="small" style="background-color: #5bc0de; margin-right:10px"> {{username}} </el-avatar> {{username}}</span>
				<!-- <el-button type="warning" @click="logout">退出登录</el-button> -->
				
				<el-button style="font-size:12px" size ="mini" type="danger" @click="logout">登出</el-button>
			</div>
		</div>
		<div class="bottomMain" v-if="!this.$route.meta.noNav">
			<div class="sideBox" :class="[{sideHiden:showSide==='hide',sideShow:showSide==='show'},setBackgroundColor($PayNameImg)]">
				<div v-for="(item,index) in nav" v-if="nav.length>0" :key="index" class="itemBox firstItemBox">
					<div class="navItem firstNav show pageNav" :class="{active:nowPath.includes(item.path) && item.meta.islink}"
					 @click="gotopage(item,index,'showOne')">						
						<span><span  :class="item.meta.iconS"  style="color: #00a8ff;"></span> {{item.meta.title}}</span>
						<span v-if="!item.meta.islink" :class="{'el-icon-arrow-right':!(show.showOne===index),'el-icon-arrow-down':(show.showOne === index)}"></span>
					</div>
					<div v-if="item.children && item.children.length > 0" class="childNav">
						<div v-for="(item2,index2) in item.children" :key="index2" class="itemBox">
							<div class="navItem twotNav pageNav" :class="{active:nowPath.includes(item2.path) && item2.meta.islink,ractive:show.showTwo===index2 && !item2.meta.islink,show:show.showOne === index}"
							 @click="gotopage(item2,index2,'showTwo')">
								<span>{{item2.meta.title}}</span>
								<span v-if="!item2.meta.islink" :class="{'el-icon-arrow-right':show.showTwo!=index2}"></span>
							</div>
							
						</div>
					</div>
				</div>
			</div>
			<div class="threeDirectoryBox" :class="{noSide:showSide==='hide',hasSide:showSide==='show'}">
				<div v-if="threeDirectory.length > 0" class="threeDirectory">
					<div class="threeChildNav">
						<!-- <div v-if="threeDirectory.length>0" v-for="(item3,index3) in threeDirectory" :key="index3" class="itemBox" :class="{active:nowPath===item3.path }"> -->
						<div v-if="threeDirectory.length>0" v-for="(item3,index3) in threeDirectory" :key="index3" class="itemBox" :class="{active:nowPath===item3.path||nowPath=== item3.path+'sub'}">
							<div class="navItem pageNav" @click="gotopage(item3,index3,'showThree')">{{item3.meta.title}}</div>
						</div>
					</div>
				</div>
				<!-- <el-breadcrumb class="breadCrumb" separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item v-for="item in ParentDirector" v-if="ParentDirector.length>0">{{item.title}}</el-breadcrumb-item>
				</el-breadcrumb> -->
			</div>

			<div class="main-container" :class="{noSide:showSide==='hide',hasSide:showSide==='show',hasThreeDic:threeDirectory.length > 0}">
				<router-view />
			</div>
		</div>
		<div class="loginMain" v-if="this.$route.meta.noNav">
			<!-- 登录 -->
			<router-view />
		</div>
	</div>
</template>
<!-- <script>
	window.onload = function() {
	　　alert('你好，欢迎光临');//各浏览器均正常弹出
	}
</script> -->
<script>
	import local from './util/local.js'
	export default {
		name: 'Home',
		components: {
			// HelloWorld
		},
		mounted () {
			let beginTime = 0;//开始时间
			let differTime = 0;//时间差
			window.onunload = function (){
			  differTime = new Date().getTime() - beginTime;
			  if(differTime <= 3){
					window.localStorage.removeItem("username");
					window.localStorage.removeItem("tid");
			  };
			};
			window.onbeforeunload = function (){
			  beginTime = new Date().getTime();
			};
			// window.addEventListener( 'beforeunload', e => this.set() );
		    // window.onbeforeunload = function (e) {
		    //     var message = 'some word';
		    //     e = e || window.event;
		    //     if (e) {
		    //         e.returnValue = message;
		    //     }
		    //     return "浏览器关闭！";
		    // };
		},
		data() {
			return {
				nav: [],
				show: {
					showOne: '',
					showTwo: '',
					showThree: ''
				},
				showSide: '', //是否显示左边导航
				nowPath: '',
				threeDirectory: [],
				ParentDirector: [],
				username:'',
				t:null,
				hasInterval:false,
				systemcount:{},
				showTirun:false,
			}
		},
		created() {
			this.getNav()
			this.nowPath = this.$route.path
			this.getTreeDirectoru()
			this.getBreadCrumb()
			this.username = local.get("username")
			// this.cardDealstate();
		},
		methods: {
			getTreeDirectoru() {
				// console.log('dssssdddd', this.$route.path)
				for (let i = 0; i < this.nav.length; i++) {
					if (this.nav[i].children.length > 0) {
						for (let j = 0; j < this.nav[i].children.length; j++) {
							if (this.nowPath.includes(this.nav[i].children[j].path)) {
								if (this.nav[i].children[j].meta.leven === 1) {
									this.threeDirectory = this.nav[i].children[j].children
								}
							}
						}
					}
				}
				// console.log(this.threeDirectory,'=====')
			},
			getBreadCrumb() {
				for (let i = 0; i < this.nav.length; i++) {
					if (this.nowPath == this.nav[i].path && this.nav[i].meta.islink) {
						this.show.showOne = i
						this.ParentDirector = [{
							title: this.nav[i].meta.title,
							path: this.nav[i].path
						}]
						break
					} else {
						let navChild = this.nav[i].children;
						for (let j = 0; j < navChild.length; j++) {
							if (this.nowPath == navChild[j].path && navChild[j].children.length == 0) {
								this.show.showOne = i
								this.show.showTwo = j
								this.ParentDirector = [{
									title: this.nav[i].meta.title,
									path: this.nav[i].path
								}, {
									title: navChild[j].meta.title,
									path: navChild[j].path
								}]
								break
							} else {
								let threeChild = navChild[j].children;
								for (let k = 0; k < threeChild.length; k++) {
									if (this.nowPath == threeChild[k].path) {
										this.show.showOne = i
										this.show.showTwo = j
										this.ParentDirector = [{
											title: this.nav[i].meta.title,
											path: this.nav[i].path
										}, {
											title: navChild[j].meta.title,
											path: navChild[j].path
										}, {
											title: threeChild[k].meta.title,
											path: threeChild[k].path
										}]
										break
									}
								}
							}
						}
					}
				}
			},
			getNav() {
				this.showTirun = this.$local.get('tiRun');
				let manage = this.$local.get('manage');
				
				this.$router.options.routes.forEach(item=>{
					if (item.path =='/userSys'||item.path =='/characterSys') {
						item.directory = manage
					}
				})
				let routes = this.$router.options.routes;
				let navAll = routes.filter(item => {
					if (item.directory) {
						return item
					}
				})
				let navArr = []
				for (let i = 0; i < navAll.length; i++) {
					if (navAll[i].meta.parentId === 0) {
						navAll[i].children = []
						navArr.push(navAll[i])
						navAll.splice(i, 1)
						i = i - 1
					}
				}
				for (let i = 0; i < navAll.length; i++) {
					if (navAll[i].meta.parentId != 0 && navAll[i].meta.leven === 1) {
						for (let j = 0; j < navArr.length; j++) {
							if (navAll[i].meta.parentId === navArr[j].meta.id) {
								navAll[i].children = []
								navArr[j].children.push(navAll[i])
							}
						}
					}
				}
				// 三级目录
				for (let i = 0; i < navAll.length; i++) {
					if (navAll[i].meta.parentId != 0 && navAll[i].meta.leven === 2) {
						for (let j = 0; j < navArr.length; j++) {
							for (let k = 0; k < navArr[j].children.length; k++) {
								if (navAll[i].meta.parentId === navArr[j].children[k].meta.id) {
									navArr[j].children[k].children.push(navAll[i])
								}
							}
						}
					}
				}
				this.nav = navArr
				// console.log(navAll, navArr)
			},
			gotopage(item, index, showNum) {
				if (this.showSide == 'show') {
					this.showSide = ""
				}
				this.showChildren(index, showNum, item.meta.islink)
				if (this.$route.path === item.path && item.meta.islink) {
					this.$router.push({path:'/replace'})					
					return
				}
				if (item.meta.islink) {
					this.$router.push(item.path)
					// this.$router.push({path: item.path, replace: true})
				}
				if (item.meta.leven === 1) {
					this.threeDirectory = item.children
				}
				// console.log('=====+++++======',this.$router)
			},
			toggleClick() {
				if (this.showSide === 'hide') {
					this.showSide = 'show'
				} else {
					this.showSide = 'hide'
				}
			},
			showChildren(index, showNum, islink) {
				if (showNum === 'showOne') {
					this.show.showTwo = ''
				}
				if (this.show[showNum] === index) {
					if (!islink) {
						this.show[showNum] = ''
					}
				} else {
					this.show[showNum] = index
				}
			},
			logout() {
				// this.$local.remove()
				this.$confirm('确认退出?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						username: this.$local.get('username')
					}
					this.$api.logout(data).then(res => {
						// console.log(res)
						if (res.status === 1) {
							this.$local.remove('tid')
							this.$local.remove('username')
							this.$router.push('/')
						} else {
							this.$message({
								type: 'info',
								message: res.msg
							});
						}

					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			getData(){
				this.t = setInterval(()=>{
					this.getSystemCountAll()
				},30000)
			},
			getSystemCountAll(){
				this.$api.systemCountAll({}).then(res=>{
					if(res.status === 1){
						this.systemcount = res.data
					}else{
						this.$message({type:"error",message:res.msg})
					}
				})
				
			},
			gotopageDifference(){
				this.$router.push({path:'/bankCardDifference'})
				if (this.$route.path === '/bankCardDifference') {
					location.reload();
				}
				
			},
			gotopageorderPayMain(){
				this.$router.push({path:'/dorderPay',query:{state:2}})
				if (this.$route.path === '/dorderPay') {
					location.reload();
				}
			},
			gotopageorderbankCard(){
				this.$router.push({path:'/bankCard',query:{is_online:2}})
				if (this.$route.path === '/bankCard') {
					location.reload();
				}
			},
			gotopagePayNot(){
				this.$router.push({path:'/bankCard',query:{is_repay_fail:1}})
				if (this.$route.path === '/bankCard') {
					location.reload();
				}
			},
			gotopageWithdraw(){
				this.$router.push({path:'/withdraw',query:{state:3}})
				if (this.$route.path === '/withdraw') {
					location.reload();
				}
			},
			gotoPageTirun(){
				// this.$router.push({path:'/Tirun', replace: true})
				
				if (this.$route.path === '/Tirun') {
					this.$router.push({path:'/replace'})
					return
				}
				this.$router.push({path:'/Tirun'})
			},
			gotopageOwnOrderPay(){
				this.$router.push({path:'/ownOrderPay'})
				if (this.$route.path === '/ownOrderPay') {
					location.reload();
				}
			},
			showDataAll(){
				if (this.$route.path === 'index') {
					this.$router.push({
						path: '/replace'
					})
					return
				}
				this.threeDirectory = []
				// this.$router.push({path:'/index', replace: true})
				this.$router.push('/index')
			},
			setBackgroundColor(name){
				if(name == 'wanding'){
					return "wanding"
				}else if(name == 'laike-YL'){
					return "laike-YL"
				}else{
					return "default"
				}
				
			},
		},
		watch: {
			
			$route(to, from) {
				this.username = local.get("username")
				this.nowPath = this.$route.path
				this.getBreadCrumb()
				this.getTreeDirectoru()
				if(this.nowPath =="/login" || this.nowPath =="/googleVerification"){
					this.hasInterval = false
					clearInterval(this.t)
				}else{
					this.getSystemCountAll()
					this.hasInterval = true
				}
				// console.log('dddkkkk',this.$router.options.routes)
			},
			hasInterval(val,old){
				if(this.hasInterval){
					this.getData()
				}
			}
		}
	}
</script>
<style lang="scss">
	@import '@/style/variables.scss';
	* {
		padding: 0;
		margin: 0;
	}
	.default{
		background: #e9ffeb;  /* fallback for old browsers */
	}
	#app {
		// font-family: Avenir, Helvetica, Arial, sans-serif;
		font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		// text-align: center;
		color: #303133;
		font-size: 16px;
	}

	#nav {
		padding: 30px;
	}

	.topNav {
		height: 45px;
		// background-color: $menuBg;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 99;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: 20px;
		// color: $navColor;
		.username{
			color:$navColor
		}
		.systemSet {
		    color: #fff;
		    cursor: pointer
		}
	}

	.hamburger {
		display: inline-block;
		vertical-align: middle;
		width: 20px;
		height: 20px;
	}

	.hamburger .icon {
		background-color: transparent;
		border-bottom: 20px solid $warning;
		border-right: 20px solid transparent;
		transform: rotate(45deg);
	}

	.hideSidebar .hamburger .icon {
		background-color: transparent;
		border-bottom: 20px solid $warning;
		border-right: 20px solid transparent;		
		transform: rotate(-135deg);
	}

	.hamburger.is-active {
		transform: rotate(180deg);
	}

	.sideBox {
		color: $navColor;
		text-align: left;


		.itemBox {
			padding-left: 20px;
			cursor: pointer;

			

			&.firstItemBox {
				padding-left: 0;

			}

			.childNav {
				// background-color: $subMenuBg;
				// font-size: 18px;
			}

			.navItem {
				padding: 12px 10px;
				display: none;

				align-items: center;
				justify-content: space-between;
				&.twotNav {
					font-size: 15px;
					color: $navSubColor;
					padding-left: 20px;
				}
				&.firstNav {
					border-bottom: 1px solid rgba(107, 108, 109, 0.19);

				}

				&.show {
					display: flex;
				}

				&.active {
					color: $primary;
				}

				&.ractive {
					color: #f5f5f5;
				}
			}
		}

	}

	.threeDirectoryBox {
		.threeChildNav {
			display: flex;
			height: 40px;
			background-color: $threeNavbackColor;
			align-items: center;
			 box-sizing: border-box;
			 margin: 10px 10px 0;
			 border: 1px solid #ddd;
			.itemBox {
				// margin-right: 20px;
				padding: 0 10px;
				height: 100%;
				display: flex;
				align-items: center;
				border-bottom: solid 2px rgba(0, 0, 0, 0);
				cursor: pointer;
				margin-bottom: -1px;
				&.active {
					color: $primary;
					background: #fff;
					border-left: solid 1px $tableBorderColor;
					border-right: solid 1px $tableBorderColor;
				}
				&:first-child.active{
					border-left:none
				}
			}
		}
	}

	.activeClass {
		color: $warning;
	}

	.loginMain {
		min-height: 100vh;
	}
</style>
