<template>
	<div>
		<div class="queryBox">
			<div class="mb20">
				<span class="mr20"><b>今日统计:</b></span>
				<span class="inlineBlock mr20">总金额：<span class="cdanger">{{countAll.total_money | tofixed}}</span></span>
				<span class="inlineBlock mr20">成功金额：<span class="cdanger">{{countAll.success_money | tofixed}}</span></span>
				<span class="inlineBlock mr20">总笔数：<span class="cdanger">{{countAll.total_count}}</span></span>
				<span class="inlineBlock mr20">成功笔数：<span class="cdanger">{{countAll.success_count}}</span></span>
			</div>
			<div class="mb10">
				<el-cascader v-model="carder_id" class="mr20 mb10" :show-all-levels="true" placeholder="搬砖工" size="mini" :options="options" filterable
				 :props="{ checkStrictly: true,expandTrigger:'hover',emitPath: false,value:'id',label:'name' }" clearable @change="agentChang"></el-cascader>
				 <el-select v-model="pay_type" placeholder="请选择通道" size="mini" class="mr20 mb10" clearable>
					<el-option v-for="item in paywayArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				

				<!-- {{stateArr}} -->
				<el-select v-model="state" placeholder="请选择状态" size="mini" class="mr20 mb10" clearable>
					<el-option v-for="(item,index) in stateArr" :key="index" :label="item" :value="index"></el-option>
				</el-select>
				 <!-- <el-input size="mini" placeholder="请输入四方商户订单号" v-model="trader_order_id" clearable class="mr20 mb10" style="width: 200px"></el-input>
				<el-input size="mini" placeholder="请输入订单号" v-model="order_id" clearable class="mr20 mb10" style="width: 200px"></el-input>
				<el-input size="mini" placeholder="请输入交易单号" v-model="water_no" clearable class="mr20 mb10" style="width: 200px"></el-input> -->
				<el-input size="mini" placeholder="请输入商家号" v-model="business_no" clearable class="mr20 mb10" style="width: 200px"></el-input>
				<el-input style="width: 200px;" size="mini" v-model="alipay_account" placeholder="请输入账号" class="mr20 mb10" clearable></el-input>
				<!-- <el-input size="mini" placeholder="请输入账号所属人姓名" v-model="alipay_true_name" clearable class="mr20 mb10" style="width: 200px"></el-input> -->
				<!-- <el-input size="mini" placeholder="请输入商家名称" v-model="business_name" clearable class="mr20 mb10" style="width: 200px"></el-input> -->
				<el-input size="mini" placeholder="请输入提交金额" v-model="add_gold" clearable class="mr20 mb10" style="width: 200px"></el-input>
				<el-input size="mini" placeholder="请输入IP" v-model="req_ip" clearable class="mr20 mb10" style="width: 200px"></el-input>
				<el-input size="mini" placeholder="付款人" v-model="seach_pay_name" clearable class="mr20 mb10" style="width: 200px"></el-input>
			
			</div>
			<div class="mb10">
				<el-button size="mini" plain class="mr20 mb10" @click="setDate(-1)" :disabled="disLoading">前一天</el-button>
				<el-date-picker size="mini" v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
				 end-placeholder="结束日期" class="mr20 mb10"></el-date-picker>
				<el-button size="mini" plain class="mr20 mb10" @click="setDate(1)" :disabled="disLoading">后一天</el-button>
				<el-select v-model="has_qr_url" placeholder="是否空单" size="mini" class="mr20 mb10" clearable>
					<el-option v-for="item in hasArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<el-input size="mini" placeholder="商户/四方/系统订单号" v-model="order_water_no" clearable class="mr20 mb10" style="width: 200px"></el-input>
				
				
				<!-- <el-select v-model="bank" size="mini" class="mr20 mb10" clearable placeholder="请选择银行">
					<el-option v-for="(item,index) in bankListArr" :key="index" :label="item" :value="index"></el-option>
				</el-select> -->
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getData"
				 :disabled="disLoading" class="mr20 mb10">查询</el-button>
				<el-button size="mini" type="primary" icon="el-icon-document" @click="getExcel" :disabled="tableData.length == 0" class="mr20 mb10">导出Excel</el-button>
			</div>
		</div>
		<template>
			<el-table :max-height="height" :data="tableData" stripe v-loading="loading" :show-overflow-tooltip="true">
				<!-- <el-table-column type="index" label="序号"></el-table-column> -->
				<el-table-column fixed="left" prop="trader_order_id" label="四方商户订单编号" width="130px" >
					<template slot-scope="scope">
						<div class="" v-if="scope.row.id">		
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">商户:{{scope.row.trader_order_id}}</span>
								<span> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.trader_order_id)"></i></span>
							</div>
							<div class="flexX flexJustifyStart">
								<span class="whiteEllipsisHover">四方{{scope.row.order_id}}</span>
								<span> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.order_id)"></i></span>
							</div>
							<div class="flexX flexJustifyStart">
								<span class="whiteEllipsisHover">系统:{{scope.row.water_no}}</span>
								<span> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.water_no)"></i></span>
							</div>
						</div>
						<div class="" v-if="!scope.row.id">
							<span v-if="scope.$index == tableData.length-2">当页总计</span>
							<span v-if="scope.$index == tableData.length-1">总计</span>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="order_id" label="四方交易号" width="145px" >
					<template slot-scope="scope">
						<div class="" v-if="scope.row.id">
							{{scope.row.order_id}}
						</div>
					</template>
				</el-table-column> -->
				<el-table-column label="商家" width="115px" >
					<template slot-scope="scope">
						<div v-if="scope.row.id">
							<div class="whiteEllipsisHover">
								{{scope.row.business_no}}
							</div>
							<div class="whiteEllipsisHover">
								{{scope.row.business_name}}
							</div>
							
						</div>
					</template>
				</el-table-column>
				<el-table-column label="搬砖工" >
					<template slot-scope="scope">
						<div v-if="scope.row.id">
							<div class="flexX flexJustifyStart">
								<span class="whiteEllipsisHover">名称:{{scope.row.carder_name}}</span>
							</div>
							<div class="flexX flexJustifyStart">
								<span class="whiteEllipsisHover">账号:{{scope.row.carder_username}}</span>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="账号">
					<template slot-scope="scope">
						<div class="" v-if="scope.row.id">
							<div class="flexX flexJustifyStart">
								<span class="">{{scope.row.alipay_true_name}}</span>
							</div>
							<div class="flexX flexJustifyStart">
								<span class="">{{scope.row.alipay_account}}</span>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="订单状态"  width="130px">
					<template slot-scope="scope">
						<div v-if="scope.row.id">
							<div  :class="scope.row.state==1?'csuccess':scope.row.state==7?'cdanger':''">{{stateArr[scope.row.state]}}</div>
							<div class="mt10" v-if="scope.row.pay_type == 106 && scope.row.callback_status">
								<el-tooltip class="item" effect="dark" content="回调状态" placement="right">
									<el-tag>{{scope.row.callback_status}}</el-tag>
								</el-tooltip>
								
							</div>
						</div>
						
					</template>
				</el-table-column>
				<el-table-column label="订单金额" >
					<template slot-scope="scope">
						{{scope.row.submit_gold |  tofixed}}
					</template>
				</el-table-column>
				<el-table-column label="金额" >
					<template slot-scope="scope">
						<div class="flexX flexJustifyStart">				
							<span class="whiteEllipsisHover">实付:{{scope.row.gold | tofixed}}</span>
						</div>
						
						<div class="flexX flexJustifyStart">				
							<span class="whiteEllipsisHover">平台:{{scope.row.fee_gold_tra | tofixed}}</span>
						</div>
						<div class="flexX flexJustifyStart">				
							<span class="whiteEllipsisHover">商户:{{scope.row.trader_gold | tofixed}}</span>
						</div>
						<!-- {{scope.row.gold | tofixed}} -->
					</template>
				</el-table-column>
				<!-- <el-table-column label="收款二维码" >
					
					<template slot-scope="scope" v-if="scope.row.qr_url">
						<div v-if="scope.row.redKey" class="fs12" style="letter-spacing: 1px;">
							<el-tag type="success" effect="plain">{{scope.row.redKey}}</el-tag>
						</div>
						<el-image
							style="width: 30px; height: 50px"
							v-if="scope.row.qr_url != ''"
							:src="scope.row.qr_url"
							fit="fill"
							:preview-src-list="[scope.row.qr_url]">
						</el-image>
					</template>
				</el-table-column> -->
				<!-- <el-table-column label="驳回原因" width="80px" >
					<template slot-scope="scope">
						<div v-if="scope.row.id" :class="scope.row.state==1?'csuccess':scope.row.state==7?'cdanger':''">{{scope.row.close_reason}}</div>
					</template>
				</el-table-column> -->
				<el-table-column label="时间 (是否扫码)" width="168px" >
					<template slot-scope="scope">
						<div class="" v-if="scope.row.id">
							<div>ip:{{scope.row.req_ip}} {{scope.row.is_scaned==1?'是':'否'}}</div>
							
							<div>提交：{{scope.row.add_time}}</div>
							<div>完成：{{scope.row.action_time}}</div>
							<div :class="scope.row.state==7?'cwarning':(scope.row.state==1?(scope.row.replenish_auditor?'cdanger':'csuccess'):'')" v-if="scope.row.add_time && scope.row.action_time">耗时：{{getTimeDifference(scope.row.add_time,scope.row.action_time)}}</div>

						</div>

					</template>
				</el-table-column>

				<el-table-column label="付款人" >
					<template slot-scope="scope">
						{{scope.row.pay_name}}
					</template>
				</el-table-column>
				<!-- <el-table-column label="处理时间" width="100px" >
					<template slot-scope="scope">
						<div v-if='scope.row.id && scope.row.action_time' class="">
							{{getTimeDifference(scope.row.add_time,scope.row.action_time)}}
							
						</div>
						<div v-if="scope.row.action_time">
							{{scope.row.action_time.split(" ")[0]}}
							<br>
							{{scope.row.action_time.split(" ")[1]}}
						</div>
					</template>
				</el-table-column> -->
				<!-- <el-table-column prop="water_no" label="商家" min-width="200px"></el-table-column> -->
				<el-table-column label="代收方式"  prop="pay_type_name" >
					<template slot-scope="scope">
						<div class="" v-if="scope.row.id">
							{{scope.row.pay_type_name}}
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column label="Fee" >
					<template slot-scope="scope">
						<div class="">
							{{scope.row.fee_gold_tra | tofixed}}
						</div>
					</template>
				</el-table-column> -->
				<!-- <el-table-column label="实际到账(元)" >
					<template slot-scope="scope">
						{{scope.row.trader_gold | tofixed}}
					</template>
				</el-table-column> -->
				<!-- <el-table-column prop="pay_type_name" label="交易方式/账号"></el-table-column> -->
				<el-table-column label="备注"  prop="auditor" >
					<template slot-scope="scope">
						<el-popover
						v-if="scope.row.id"
						  placement="right"
						  trigger="click">
						  <el-table :data="operatorLogData">
						    <el-table-column width="150" property="add_time" label="时间"></el-table-column>
						    <el-table-column width="100" property="operator" label="操作人"></el-table-column>
						    <el-table-column width="300" property="remark" label="备注"></el-table-column>
						  </el-table>
						  <div class="flexX  mt20 pageBox2">
							<el-pagination :hide-on-single-page="true" @current-change="handleCurrentChangeLog"
							:current-page="pageLog" :page-size="pageSizeLog" layout="total, prev, pager, next, jumper"
							:total="totalLog"></el-pagination>
						  </div>
						  <el-button size="mini" type="primary" plain slot="reference" @click="getOrderPayOperatorLog(scope.row.id)">日志</el-button>
						</el-popover>
						<!-- <div v-if="scope.row.id" class="">
						
							<div>{{scope.row.auditor}}</div>
							<div v-if="scope.row.replenish_auditor">补单人：{{scope.row.replenish_auditor}}</div>
							<div v-if="scope.row.cancel_order_auditor">撤单人：{{scope.row.cancel_order_auditor}}</div>
						</div> -->
					</template>
				</el-table-column>
				<!-- <el-table-column label="补单操作人" width="100px" prop="auditor" >
					<template slot-scope="scope">
						<div v-if="scope.row.id" class="">
							{{scope.row.replenish_auditor}}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="撤单操作人" width="100px" prop="auditor" >
					<template slot-scope="scope">
						<div v-if="scope.row.id" class="">
							{{scope.row.cancel_order_auditor}}
						</div>
					</template>
				</el-table-column> -->
				<el-table-column fixed="right" label="操作" >
					<template slot-scope="scope">
						<div v-if="scope.row.id">
							<div v-if="scope.row.state == 1">
								<!-- <div v-if="scope.row.replenish_state==0">{{scope.row.bill_no}}</div> -->
								<!-- <div v-if="scope.row.replenish_state>0 || scope.row.auditor!=''">{{scope.row.auditor}}</div> -->
								<div v-if="scope.row.replenish_state>0">{{replenishmentStateObj[scope.row.replenish_state]}}</div>
								<div>
									<el-link type="primary" @click="noticeIn(scope.row,scope.$index)">发送回调</el-link>
								</div>
							</div>
							<div v-if="scope.row.state == 7">
								<el-link v-if="scope.row.card && scope.row.is_manual == 0" type="primary" @click="showDia(scope.row,scope.$index)">补单</el-link>
								<el-link v-if="scope.row.qr_url && scope.row.pay_type ==77 && scope.row.is_manual == 0" type="primary" @click="showDia(scope.row,scope.$index)">补单</el-link>
							
							</div>

						</div>
						<!-- <div v-if="scope.row.state == 1">
							<el-link v-if="scope.row.card && scope.row.is_cancel == 0" type="primary" @click="ordercancel(scope.row,scope.$index)">撤单</el-link>
						</div> -->
					</template>
				</el-table-column>
				<!-- <el-table-column prop="water_no" label="交易号" width="170px" >
					<template slot-scope="scope">
						<div class="" v-if="scope.row.id">
							{{scope.row.water_no}}
						</div>
					</template>
				</el-table-column> -->
			</el-table>
		</template>

		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>
	
		<el-dialog width="800px" center top="150px" title="补单" :visible.sync="bddialogVisible" @close="bdclose"
		 :close-on-click-modal="false">
			<div class="formBoxbd" v-loading="bddialogloading">
				<div>
					<el-form label-position="right" label-width="100px">
						<el-form-item label="订单编号">
							{{item.order_id}}
						</el-form-item>
						<el-form-item label="交易号">
							{{item.water_no}}
						</el-form-item>
						<el-form-item label="商家名称">
							{{item.business_name}}
						</el-form-item>
						<el-form-item label="订单金额">
							{{item.submit_gold | tofixed}}元
						</el-form-item>
						<el-form-item label="补单类型">
							<el-select v-model="replenState" placeholder="请选择补单类型" size="mini" class="mr20" clearable>
								<el-option v-for="(item,index) in replenishmentStateObj" :key="index" :label="item" :value="index"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="补单金额">
							<el-input v-model="amount" placeholder="请输入补单金额"></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmit">确定</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog width="800px" center top="150px" :title="billTitle" :visible.sync="billbddialogVisible" @close="billbdclose"
			 :close-on-click-modal="false">
				<div class="formBoxbd" v-loading="billbddialogloading">
					<div v-if="billType == 'billadd'">
						<el-form label-position="right" label-width="100px">
							<el-form-item label="金额">
								{{billbditem.amount | tofixed}}元
							</el-form-item>
							<el-form-item label="订单编号">
								<!-- {{bditem.order_id}} -->
								<el-select v-model="failOrder" filterable placeholder="请选择订单编号" size="mini" class="mr20" clearable>
									<el-option v-for="(item) in failOrderList" :key="item.id" :label="item.trader_order_id+item.order_id" :value="item.id">
										 <span>{{item.trader_order_id}} | {{item.order_id}} | {{item.business_name}} | {{item.add_gold | tofixed}} | {{item.add_time}}</span>
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="补单类型">
								<el-select v-model="replenState" placeholder="请选择补单类型" size="mini" class="mr20" clearable>
									<el-option v-for="(item,index) in replenishmentStateObj" :key="index" :label="item" :value="index"></el-option>
								</el-select>
							</el-form-item>
						</el-form>
					</div>
					<div v-if="billType == 'billloss'">
						<el-form label-position="right" label-width="100px">
							
							<el-form-item label="姓名">
								{{item.true_name}}
							</el-form-item>
							<el-form-item label="银行">
								{{item.bank}}
							</el-form-item>
							<el-form-item label="卡号">
								{{item.card}}
							</el-form-item>
							<el-form-item label="搬砖工">
								{{infoTotalData.name}}
							</el-form-item>
							<!-- <el-form-item label="差值">
								<span>{{item.true_quota - item.sys_quota  | tofixed}}</span>
							</el-form-item> -->
							<el-form-item label="金额">
								{{billbditem.amount | tofixed}}元
							</el-form-item>
							
							<el-form-item label="报损类型">
								<el-select v-model="losseType" filterable placeholder="请选择报损类型" size="mini" class="mr20" clearable>
									<el-option v-for="(item) in losseTypeList" :key="item.id" :label="item.label" :value="item.id"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="备注">
								<el-input size="mini" v-model="losseNote" placeholder="请输入备注" style="width: 200px;"></el-input>
							</el-form-item>
						</el-form>
					</div>
					<div class="textCenter">
						<el-button type="primary" @click="billonSubmit">确定</el-button>
					</div>
				</div>
		</el-dialog>
	</div>
</template>

<script>
	import local from '@/util/local'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				tableData: [],
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				diapageSizesArr: [20, 50, 100, 200],
				diapageSize: 20,
				diatotal: 0,
				diapage: 1,
				nodepageSizesArr: [20, 50, 100, 200],
				nodepageSize: 20,
				nodetotal: 0,
				nodepage: 1,
				outpageSizesArr: [20, 50, 100, 200],
				outpageSize: 20,
				outtotal: 0,
				outpage: 1,
				billpageSizesArr: [20, 50, 100, 200],
				billpageSize: 20,
				billtotal: 0,
				billpage: 1,
				order_id: '',
				water_no: '',
				business_no: '',
				card_name: '',
				alipay_true_name: '',
				alipay_account: '',
				business_name: '',
				add_gold: '',
				req_ip: '',
				pay_type: '',
				state: '',
				paywayArr: [],
				stateArr: {},
				// stateMap:{},
				date: null,
				countAll: {
					total_money: 0,
					success_money: 0,
					total_count: 0,
					success_count: 0,
				},
				dialogVisible: false,
				dialogloading: false,
				item: {},
				infoTotalData: {}, //搬砖工
				cardListData: [], //银行卡列表
				cardNoteData: [], //银行卡记录  流水
				cardNoteOutData: [], //卡出账记录 
				cardBillData: [], //卡账单
				bill_update_time: '', //卡账单 最后更新时间
				activeName: 'info',
				//补单
				replenState: '',
				replenishmentStateObj: {}, //补单类型
				bddialogVisible: false, //补单弹框
				amount: '', //补单金额
				item: {}, //当前补单的数据
				index: {}, //当前item 索引
				// orderType:"",//订单操作类型  cancle 撤单
				bddialogloading: false,
				trader_order_id: '',
				order_water_no: '',
				//卡账单补单
				// replenState: '',
				// replenishmentStateObj: {}, //补单类型
				billbddialogVisible: false, //补单弹框
				billbditem: {}, //当前补单的数据
				// index: {}, //当前item 索引
				// orderType:"",//订单操作类型  cancle 撤单
				billbddialogloading: false,
				failOrderList:[],
				failOrder:'',
				card:'',//银行卡号搜索
				bank: '',//银行筛选项
				bankListArr: [],
				//报损
				lossmoney:'',
				losseType:'',
				losseTypeList:[{id:'1',label:'从搬砖工扣除'},{id:'2',label:'从平台扣除'}],
				losseNote:'',
				//卡账单报损
				billType:'',//billadd 卡账单补单   billloss 卡账单报损
				billTitle:'',//账单弹出框title
				height: 0,
				dateIn:null,
				dateOut:null,
				disLoadingIn:false,
				disLoadingOut:false,
				options: [],
				carder_id: '',
				seach_pay_name: '',
				// 筛选是否上传转账码
				hasArr:[
					{id:'1',name:'实单'},
					{id:'2',name:'空单'},
					],
				has_qr_url: '',
				cardListdisLoading:false,
				//日志列表 
				operatorLogData:[],
				pageLog:1,
				pageSizeLog:10,
				totalLog:0,
				orderId:'',				
			};
		},
		created() {
			this.date = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),
				true)];
			this.treeList()
			this.bankList()
			this.paywayAll()
			this.stateList()
			this.orderAllList()
			this.orderCountAll()
			this.replenishmentState()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// console.log('sssssssssss', (value * 1).toFixed(2) + '')
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			agentChang(value) {
				this.carder_id = value
			},
			treeList() {
				this.$api.treeList({}).then(res => {
					if (res.status === 1) {
						this.options = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			setDate(day) {
				let data = this.date ? this.date[0] : this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate, newDate]
				this.getData()
			},
			getTimeDifference(startTime, endTime) {
				return this.$util.intervalTime(startTime, endTime)
			},
			//搜索按钮
			getData() {
				this.page = 1;
				this.orderAllList();
			},
			//总计
			orderCountAll() {
				this.$api.orderCountAll({}).then(res => {
					console.log(res)
					if (res.status == 1) {
						this.countAll = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//导出Excel
			getExcel() {
				//manager/order/exportList
				console.log(this.$baseURL)
				// let data = {
				let username = local.get('username');
				let tid = local.get('tid')
				let start_time = this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), true) : '';
				let end_time = this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), true) : '';
				// let carder_children = this.carder_id;
				let carder_id = this.carder_id;
				// }
				let href =
					`${this.$baseURL}/manager/order/exportList?username=${username}&tid=${tid}&order_id=${this.order_id}&water_no=${this.water_no}&business_no=${this.business_no}&carder_id=${carder_id}&card_name=${this.card_name}&alipay_true_name=${this.alipay_true_name}&alipay_account=${this.alipay_account}&business_name=${this.business_name}&add_gold=${this.add_gold}&req_ip=${this.req_ip}&pay_type=${this.pay_type}&state=${this.state}&start_time=${start_time}&end_time=${end_time}`
				window.location.href = href
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.orderAllList()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.orderAllList()
			},
			diahandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.diapage = 1
				this.diapageSize = val
				this.cardList()
			},
			diahandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.diapage = val
				this.cardList()
			},

			nodehandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.nodepage = 1
				this.nodepageSize = val
				this.moneyList()
			},
			nodehandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.nodepage = val
				this.moneyList()
			},
			outhandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.outpage = 1
				this.outpageSize = val
				this.outList()
			},
			outhandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.outpage = val
				this.outList()
			},
			billhandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.billpage = 1
				this.billpageSize = val
				this.cardBill()
			},
			billhandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.billpage = val
				this.cardBill()
			},

			paywayAll() {
				this.$api.paywayAll({
					hasRepay: 2
				}).then(res => {
					if (res.status === 1) {
						this.paywayArr = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			stateList() {
				this.$api.stateList({}).then(res => {
					if (res.status === 1) {
						this.stateArr = res.data
						// this.stateArr.forEach(item=>{
						// 	this.stateMap[item.id] = item.note
						// })
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			orderAllList() {
				let data = {
					order_id: this.order_id,
					water_no: this.water_no,
					business_no: this.business_no,
					card_name: this.card_name,
					business_name: this.business_name,
					add_gold: this.add_gold,
					req_ip: this.req_ip,
					pay_type: this.pay_type,
					state: this.state,
					trader_order_id: this.trader_order_id,
					order_water_no: this.order_water_no,
					// start_time: this.date?this.date[0]:'',
					// end_time:  this.date?this.date[1]:'',
					start_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), true) : '',
					end_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), true) : '',
					page: this.page,
					size: this.pageSize,
					bank:this.bank,
					card:this.card,
					// carder_children:this.carder_id,
					carder_id:this.carder_id,
					alipay_true_name:this.alipay_true_name,
					alipay_account:this.alipay_account,
					pay_name: this.seach_pay_name,
					has_qr_url:this.has_qr_url
				}
				// this.loading = true
				this.disLoading = true
				console.log('disLoading', this.disLoading)
				this.$api.orderAllList(data).then(res => {
					this.loading = false;
					this.disLoading = false;
					if (res.status === 1) {
						this.tableData = res.data.data;
						this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			showInfo(item) {
				// this.$router.push({path:'/orderInfo'})
				this.dialogVisible = true;
				this.item = item
				this.infoTotal(item)
			},
			getInfoData(val) {
				if (val.name == 'info') {
					if (JSON.stringify(this.infoTotalData) == '{}') {
						this.infoTotal()
					}
				}
				if (val.name == 'bankList') {
					if (this.cardListData.length == 0) {
						this.cardList()
					}
				}
				if (val.name == 'cardNote') {
					// this.dateIn = [this.$util.timestampToTime(new Date().getTime(), true) + ' 00:00:00', this.$util.timestampToTime(new Date().getTime(), true) + ' 23:59:59']
					if (this.cardNoteData.length == 0) {
						this.moneyList()
					}
				}
				if (val.name == 'cardNoteOut') {
					// this.dateIn = [this.$util.timestampToTime(new Date().getTime(), true) + ' 00:00:00', this.$util.timestampToTime(new Date().getTime(), true) + ' 23:59:59']					
					if (this.cardNoteOutData.length == 0) {
						this.outList()
					}
				}
				if (val.name == 'cardBill') {
					if (this.cardBillData.length == 0) {
						this.cardBill()
					}
				}
				console.log('val', val)
			},
			infoTotal(item) {
				let data = {
					id: item.alipay_id,
					type: 3
				}
				// this.dialogloading = true
				this.$api.infoTotal(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.infoTotalData = res.data
					} else {
						this.$message({
							"type": "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			cardList(item) {
				let data = {
					carder_id: this.infoTotalData.id,
					size: this.diapageSize,
					page: this.diapage,
					// type:2
				}

				// this.dialogloading = true
				this.$api.cardList(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.cardListData = res.data.data
						this.diatotal = res.data.total

					} else {
						this.$message({
							"type": "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			close() {
				this.dialogVisible = false
				this.item = {}
				this.infoTotalData = {} //搬砖工
				this.cardListData = [] //银行卡列表
				this.cardNoteData = [] //银行卡记录  流水
				this.cardNoteOutData = []
				this.cardBillData = []
				this.activeName = 'info'
				this.diapage = 1
				this.nodepage = 1
				this.outpage = 1
				this.billpage = 1
				this.dateIn=null
				this.dateOut=null
				this.disLoadingIn=false
				this.disLoadingOut=false
			},

			//补单按钮
			replenishmentState() {
				this.$api.replenishmentState({}).then(res => {
					if (res.status == 1) {
						this.replenishmentStateObj = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//通知商家
			noticeIn(item, index) {
				this.$confirm('确认发送回调吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					this.$api.noticeIn(data).then(res => {
						this.loading = false;
						if (res.status == 1) {
							this.$message({
								type: 'success',
								message: "发送回调成功"
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消发送回调'
					});
				});
			},

			showDia(item, index) {
				this.item = item
				this.index = index
				// this.orderType=type
				this.bddialogVisible = true
				if (JSON.stringify(this.replenishmentStateObj) == '{}') {
					this.replenishmentState()
				}

			},
			//确认补单
			onSubmit() {
				if (isEmpty(this.replenState, '请选择补单类型')) {
					return
				}
				if (isEmpty(this.amount, '请选输入补单金额')) {
					return
				}
				if (!isMoney(this.amount, '补单金额只能是数字，且最多保留两位小数')) {
					return
				}
				let data = {
					id: this.item.id,
					state: this.replenState,
					amount: this.amount
				}
				this.bddialogloading = true;
				this.$api.replenishment(data).then(res => {
					this.bddialogloading = false;
					if (res.status == 1) {
						this.orderAllList()
						this.bdclose();
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(() => {
					this.bddialogloading = false;
				})
			},
			bdclose() {
				this.item = {}
				this.bddialogVisible = false
				this.replenState = ''
				this.amount = ''
			},
			moneyList() {
				let data = {
					page: this.nodepage,
					size: this.nodepageSize,
					id: this.item.alipay_id,
					startdate:this.dateIn?this.$util.timestampToTime(Date.parse(new Date(this.dateIn[0]))):"",
					endate:this.dateIn?this.$util.timestampToTime(Date.parse(new Date(this.dateIn[1]))):""
				}
				this.disLoadingIn = true
				// this.dialogloading = true
				this.$api.moneyList(data).then(res => {
					this.dialogloading = false
					this.disLoadingIn = false
					if (res.status == 1) {
						this.cardNoteData = res.data.data
						this.nodetotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {					
					this.disLoadingIn = false
					this.dialogloading = false
				})
			},
			outList() {
				let data = {
					page: this.outpage,
					size: this.outpageSize,
					id: this.item.bank_card_id,
					startdate:this.dateOut?this.$util.timestampToTime(Date.parse(new Date(this.dateOut[0]))):"",
					endate:this.dateOut?this.$util.timestampToTime(Date.parse(new Date(this.dateOut[1]))):""
				}
				
				this.disLoadingOut = true
				// this.dialogloading = true
				this.$api.outList(data).then(res => {
					this.dialogloading = false
					this.disLoadingOut = false
					if (res.status == 1) {
						this.cardNoteOutData = res.data.data
						this.outtotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
					this.disLoadingOut = false
				})
			},
			//卡账单
			cardBill() {
				let data = {
					page: this.billpage,
					size: this.billpageSize,
					id: this.item.bank_card_id,
				}
				// this.dialogloading = true
				this.$api.cardBill(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.cardBillData = res.data.data
						this.billtotal = res.data.total
						this.bill_update_time = res.data.bill_update_time
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			//撤单
			ordercancel(item, index) {
				this.$confirm('确认撤单' + item.order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					this.$api.ordercancel(data).then(res => {
						this.loading = false
						if (res.status == 1) {
							this.orderAllList()
							this.$message({
								type: 'success',
								message: '撤单成功'
							});
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
					}).catch(error => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消撤单'
					});
				});
			},
			//卡账单补单
			billshowDia(item, index,type) {
				this.billbditem = item
				this.billType = type
				this.billbddialogVisible = true
				if(this.billType == "billadd"){
					//补单
					this.billTitle = "补单"
					if (JSON.stringify(this.replenishmentStateObj) == '{}') {
						this.replenishmentState()
					}
					this.getFailOrder()
				}
				else if(this.billType == "billloss"){
					this.billTitle = "银行卡报损"
				}
			},
			//补单类型
			replenishmentState() {
				this.$api.replenishmentState({}).then(res => {
					if (res.status == 1) {
						this.replenishmentStateObj = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//根据卡查找失败的订单
			getFailOrder(){
				let data = {
					card:this.billbditem.card
				}
				this.$api.getFailOrder(data).then(res=>{
					this.failOrderList=res.data
				})
			},
			//确认补单
			billonSubmit() {
				if(this.billType == "billadd"){
					if (isEmpty(this.failOrder, '请选择订单')) {
						return
					}
					if (isEmpty(this.replenState, '请选择补单类型')) {
						return
					}
					let amount = ''
					for(let i = 0;i<this.failOrderList.length;i++){
						if(this.failOrderList[i].id == this.failOrder){
							amount = this.failOrderList[i].add_gold
							break;
						}
					}
					let data = {
						id: this.failOrder,
						state: this.replenState,
						amount: amount,
						bill_id:this.billbditem.id
					}
					// console.log(data)
					this.billbddialogloading = true;
					this.$api.replenishment(data).then(res => {
						this.billbddialogloading = false;
						if (res.status == 1) {
							this.cardBill()
							this.billbdclose();
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(() => {
						this.billbddialogloading = false;
					})
				}
				else if(this.billType == "billloss"){
					this.lossmoney = parseFloat(this.billbditem.amount).toFixed(2)
					console.log(this.lossmoney)
					this.lossSubmit('bill',this.billbditem.id)
				}
			},
			//关闭补单弹框
			billbdclose() {
				this.billbditem = {}
				this.billbddialogVisible = false
				this.replenState = ''
				this.failOrder = ''
				this.lossmoney=''
				this.losseType=''
				this.losseNote=''
				this.failOrderList = []
			},
			bankList() {
				this.$api.bankList({}).then(res => {
					if (res.status == 1) {
						this.bankListArr = res.data;
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//报损
			lossSubmit(bill,id){
				if (!isMoney(this.lossmoney, '报损金额只能是数字，最多输入两位小数')) {
					return
				}
				if (isEmpty(this.losseType, '请选择报损类型')) {
					return
				}
				let data = {
					type:this.losseType,
					bank_card_id:this.item.bank_card_id,
					money:this.lossmoney,
					note:this.losseNote
				}
				if(bill == "bill"){
					data.bill_id = id
				}
				this.lossdialogloading = true
				this.$api.addBankCardLoss(data).then(res=>{
					this.lossdialogloading = false
					if(res.status == 1){
						this.orderAllList()
						if(bill == "bill"){
							this.billbdclose()
							this.cardBill()
						}else{							
							this.lossclose()
						}
						this.$message({type:"success",message:res.msg})
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.lossdialogloading = false
				})
				
			},
			getDataIn(type){
				if(type == 'in'){
					this.moneyList()					
				}
				else if(type == 'out'){
					this.outList()
				}
			},
			handleCopy(textCopy){
				
				const contentToCopy = textCopy
				// 创建一个临时的 textAREA 元素
				const textarea = document.createElement('textarea')
				textarea.value = contentToCopy
				document.body.appendChild(textarea)
				textarea.select()
				document.execCommand('copy')
				document.body.removeChild(textarea)
				this.$message({
					type: 'success',
					message: '已复制到剪切板'
				})
			},
			//获取订单日志
			// hidePopver(){
				
			// 	this.operatorLogData = []		
			// 	console.log('this.pageLog',this.pageLog)		
			// },
			getOrderPayOperatorLog(id) {
				this.orderId = id	
				this.pageLog= 1
				this.totalLog = 0
				this.operatorLogData = []
				this.getLogsById()
			},
			getLogsById(){				
				this.operatorLogData = []
				let data = {
					id:this.orderId,					
					page:this.pageLog,
					size:this.pageSizeLog,
				}
				this.$api.getLogsById(data).then(res => {
					if (res.status === 1) {
						this.operatorLogData = res.data.data
						this.totalLog= res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},

			// handleSizeChangeLog
			handleCurrentChangeLog(val){
				this.pageLog = val
				this.getLogsById()
			},


		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
				})
			}
		},
	};
</script>

<style scoped="scoped">
	.formBoxbd {
		width: 80%;
		margin: 0 auto;
	}
</style>
