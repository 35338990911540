<template>
	<div>
		<div class="queryBox">
			<div class="mb20">
				<el-input size="mini" placeholder="请输入收款卡号" v-model="card" clearable class="mr20" style="width: 200px"></el-input>
				<el-input size="mini" placeholder="请输入收款卡姓名" v-model="name" clearable class="mr20" style="width: 200px"></el-input>
				<el-input size="mini" placeholder="请输入手机号" v-model="phone" clearable class="mr20" style="width: 200px"></el-input>
				<el-select v-model="state" placeholder="请选择状态" size="mini" class="mr20" clearable>
					<el-option v-for="(item,index) in stateArr" :key="index" :label="item" :value="index"></el-option>
				</el-select>
				<el-select v-model="jiexi_state" placeholder="请选择解析状态" size="mini" class="mr20" clearable>
					<el-option v-for="(item,index) in stateArr" :key="index" :label="item" :value="index"></el-option>
				</el-select>
				<el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">前一天</el-button>
				<el-date-picker size="mini" v-model="date" type="datetimerange" range-separator="至" start-placeholder="开始日期"
				 end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
				<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">后一天</el-button>
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" :disabled="disLoading" @click="getData">查询</el-button>
			</div>
		</div>
		<template>
			<el-table :max-height="height" size="mini" :data="tableData" stripe :show-overflow-tooltip="true">
				<el-table-column type="index" label="序号" width="50px" ></el-table-column>
				<el-table-column prop="bill_no" label="交易号"  width="150px"></el-table-column>
				<el-table-column label="状态" >
					<template slot-scope="scope">
						<span :class="scope.row.state==2?'cdanger':'csuccess'">{{stateArr[scope.row.state]}}</span>
					</template>
				</el-table-column>
				<el-table-column label="解析" >
					<template slot-scope="scope">
						<span :class="scope.row.jiexi_state==2?'cdanger':'csuccess'">{{stateArr[scope.row.jiexi_state]}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="failed_reason_msg" label="回调失败原因"  show-overflow-tooltip></el-table-column>
				<el-table-column prop="notice_msg" label="短信内容"  show-overflow-tooltip></el-table-column>
				<el-table-column label="金额" >
					<template slot-scope="scope">
						{{scope.row.gold |  tofixed}}
					</template>
				</el-table-column>
				<el-table-column prop="phone" label="手机号" ></el-table-column>
				<el-table-column prop="true_name" label="收款信息" width = "170px">
					<template slot-scope="scope">
						<div class="flexX flexJustifyStart">				
							<span class="whiteEllipsisHover">姓名:{{scope.row.true_name}}</span>
							<!-- <span v-if="scope.row.true_name"> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.true_name)"></i></span> -->
						</div>
						<div class="flexX flexJustifyStart">				
							<span class="whiteEllipsisHover">卡号:{{scope.row.card}}</span>
							<!-- <span v-if="scope.row.card"> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.card)"></i></span> -->
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="card" label="收款卡号"  width="160px"></el-table-column> -->
				<el-table-column prop="bank" label="银行" ></el-table-column>
				<el-table-column prop="type" label="通知类型" ></el-table-column>
				<el-table-column prop="pay_name" label="付款信息" width = "170px">
					<template slot-scope="scope">
						<div class="flexX flexJustifyStart">				
							<span class="whiteEllipsisHover">姓名:{{scope.row.pay_name}}</span>
							<!-- <span v-if="scope.row.pay_name"> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.pay_name)"></i></span> -->
						</div>
						<div class="flexX flexJustifyStart">				
							<span class="whiteEllipsisHover">卡号:{{scope.row.pay_card}}</span>
							<!-- <span v-if="scope.row.pay_card"> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.pay_card)"></i></span> -->
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="pay_card" label="付款卡号"  width="160px"></el-table-column> -->
				<el-table-column prop="suc_time" label="时间" width = "160px">
					<template slot-scope="scope">
						<div v-if="scope.row.id">
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">接受:{{scope.row.rev_time}}</span>
							</div>
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">成功:{{scope.row.suc_time}}</span>
							</div>
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover" v-if='scope.row.rev_time && scope.row.suc_time'>耗时:{{getTimeDifference(scope.row.rev_time,scope.row.suc_time)}}</span>
							</div>
						</div>
					</template>

					<!-- <template slot-scope="scope">
						<div v-if="scope.row.suc_time">
							{{scope.row.suc_time.split(" ")[0]}}
							<br>
							{{scope.row.suc_time.split(" ")[1]}}
						</div>
					</template> -->
				</el-table-column>
				<!-- <el-table-column prop="rev_time" label="接收时间" >
					<template slot-scope="scope">
						<div v-if="scope.row.rev_time">
							{{scope.row.rev_time.split(" ")[0]}}
							<br>
							{{scope.row.rev_time.split(" ")[1]}}
						</div>
					</template>
				</el-table-column> -->
				<el-table-column prop="card_true_quota" label="余额" >
					<template slot-scope="scope">
						{{scope.row.card_true_quota |  tofixed}}
					</template>
				</el-table-column>
				<el-table-column prop="note" label="备注" ></el-table-column>
				<el-table-column label="操作" >
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click="setNote(scope.row,scope.$index)">备注</el-button>
					</template>
				</el-table-column>
				
			</el-table>
		</template>

		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>
		<el-dialog width="800px" center top="150px" title="补单" :visible.sync="visible" @close="close" :close-on-click-modal="false">
			<div class="formBoxbd" v-loading="loading">
				<div>
					<el-form label-position="right" label-width="100px">
						<el-form-item label="状态">
							{{stateArr[item.state]}}
						</el-form-item>
						<el-form-item label="金额">
							{{item.gold | tofixed}}
						</el-form-item>
						<el-form-item label="收款卡姓名">
							{{item.true_name}}
						</el-form-item>
						<el-form-item label="收款卡号">
							{{item.card}}
						</el-form-item>
						<el-form-item label="付款卡姓名">
							{{item.pay_name}}
						</el-form-item>
						<el-form-item label="付款卡号">
							{{item.pay_card}}
						</el-form-item>
						<el-form-item label="备注">
							<el-input placeholder="请输入备注" v-model="item.note"></el-input>
							<!-- <el-input v-model="amount" placeholder="请输入补单金额"></el-input> -->
						</el-form-item>
					</el-form>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmit">确定</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import local from '@/util/local'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			let bankMap = local.get("bankMap")
			return {
				loading: false,
				disLoading: false,
				tableData: [],
				card:"",
				phone:"",
				state:"",
				stateArr:{
					1:"成功",
					2:"未成功"
				},
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				visible:false,
				item:{},
				index:'',
				bankMap,
				name:'',
				height:0,
				jiexi_state:'',
				date: null,
			};
		},
		created() {
			this.date = [this.$util.timestampToTime(new Date().getTime(), true) + ' 00:00:00', this.$util.timestampToTime(new Date().getTime(), true) + ' 23:59:59']
			this.getData()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// console.log('sssssssssss', (value * 1).toFixed(2) + '')
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {		
			setDate(day) {
				let data = this.date?this.date[0]:this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate + ' 00:00:00', newDate + ' 23:59:59']
				// this.date = [newDate, newDate]
				this.getData()
			},	
			//搜索按钮
			getData() {
				this.page = 1;
				this.inNotice()
			},
			inNotice(){
				let data = {
					card:this.card,
					phone:this.phone,
					state:this.state,
					name:this.name,
					page:this.page,
					size:this.pageSize,
					jiexi_state:this.jiexi_state,
					startdate:this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0]))) : '',
					enddate: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1]))) : '',
				}
				this.disLoading = true
				this.$api.inNotice(data).then(res=>{
					this.disLoading = false
					if(res.status == 1){
						this.tableData = res.data.data;
						this.total = res.data.total
					}else{
						this.$message({type:'error',message:res.msg})
					}
				}).catch(error=>{
					this.disLoading = false
				})
			},
			setNote(item,index){
				this.item = JSON.parse(JSON.stringify(item))
				this.index = index
				this.visible = true
			},
			close(){
				this.item = {}
				this.index = ''
				this.visible = false
			},
			onSubmit(){
				// if(isEmpty(this.item.note,"请输入备注")){
				// 	return
				// }
				let data = {
					id:this.item.id,
					note:this.item.note
				}
				this.loading = true;
				this.$api.setInNotice(data).then(res=>{
					this.loading = false;
					if(res.status == 1){
						console.log(this.index,this.item.note)
						this.$set(this.tableData[this.index],'note',this.item.note)
						this.$message({type:"success",message:res.msg})
						this.close()
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.loading = false;
				})
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.page = 1
				this.pageSize = val
				this.inNotice()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.inNotice()
			},
			getTimeDifference(startTime, endTime) {
				return this.$util.intervalTime(startTime, endTime)
			},
			handleCopy(textCopy){
				
				const contentToCopy = textCopy
				// 创建一个临时的 textAREA 元素
				const textarea = document.createElement('textarea')
				textarea.value = contentToCopy
				document.body.appendChild(textarea)
				textarea.select()
				document.execCommand('copy')
				document.body.removeChild(textarea)
				this.$message({
					type: 'success',
					message: '已复制到剪切板'
				})
			},
		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
				})
			}
		},
	};
</script>

<style scoped="scoped">
	.formBoxbd{
		width: 80%;
		margin: 0 auto;
	}
</style>
