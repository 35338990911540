<template>
	<div>
		<!-- 角色管理 -->
		<div class="queryBox mb20">
			<el-button size="mini" type="primary" icon="el-icon-plu" @click="addAccount('new')">新 增</el-button>
		</div>
		<template>
			<el-table size="mini" :max-height="height" :data="tableData" stripe v-loading="loading":show-overflow-tooltip="true" style="width:600px">
				<el-table-column type="index" label="序号"></el-table-column>
				<el-table-column prop="name" label="角色"></el-table-column>
			
				<el-table-column prop="" label="功能" >
					<template slot-scope="scope">
						<el-link type="primary" @click="delRole(scope.row)" class="mr20">删除角色</el-link>
						<el-link type="primary" @click="addAccount('power',scope.row)" class="mr20">设置权限</el-link>
						
					</template>
				</el-table-column>
			</el-table>

		
		</template>
		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr"
				:page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		<el-dialog center class="dialog" top="150px" :title="title" :visible.sync="dialogVisible" @close="close":close-on-click-modal="false">
			<div class="formBox" v-loading="dialogloading">
				<div>
					<div v-if="titleName" class="mb20">{{titleName}}</div>
					<el-form :label-position="labelPosition" label-width="100px" v-if="type === 'new'">
						<el-form-item label="角色名称">
							<el-input v-model="username" placeholder="请输入角色名称"></el-input>
						</el-form-item>
						
					</el-form>
					<el-form :label-position="labelPosition" label-width="100px" v-if="type === 'power'">
						<el-form-item label="角色名称">
							{{item.name}}
						</el-form-item>
						<el-form-item label="权限列表"></el-form-item>
						<div>
							<template>
							  <el-checkbox-group v-model="permName">
								<span class="powerItem" v-if="powerList.length>0" v-for="item in powerList"><el-checkbox :label="item.name"  :key="item.id"></el-checkbox></span>
								
							  
							  </el-checkbox-group>
							</template>
						</div>
					</el-form>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmit">{{submitText}}</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				dialogloading: false,
				dialogVisible: false,
				date: [],
				input: '',
				tableData: [],
				dataTotal: {},
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				item:{},
				//新增
				labelPosition: 'right',
				username:'',
				password:'',
				passwordSure: '', //确认密码
				type: '', //new  新增   roles配置角色
				title: '',
				titleName: '',
				submitText: '新增',
				roles:'',//角色
				powerList:[],//角色列表
				permName:[],
				rolePageSize:100,
				height:0
			}
		},
		created() {
			this.getRoles()
			this.getPermissions()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 40 - 60
				console.log(this.height)
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			
			//角色列表
			getRoles(){
				this.$api.getRoles({
					page: this.page,
					size: this.pageSize
				}).then(res => {
					if (res.status === 1) {
						this.tableData = res.data.data						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
				})
			},
			//权限列表
			getPermissions(){
				this.$api.getPermissions({
						size: 5000
				}).then(res => {
					if (res.status === 1) {
						this.powerList = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
				})
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.page = 1
				this.pageSize = val
				this.getRoles()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page = val
				this.getRoles()
			},
			addAccount(type,item) {
				this.dialogVisible = true
				this.permName = []
				this.type = type
				if (item) {
					this.item = item;
				}
				if (this.type === 'new') {
					this.title = '新增角色'
					this.submitText = '新增'
				}
				else if(this.type == 'power'){
					for(let i = 0;i<this.item.permissions.length;i++){
						for(let j = 0; j<this.powerList.length;j++){
							if(this.item.permissions[i] == this.powerList[j].id){
								this.permName.push(this.powerList[j].name)
							}
						}
					}
					this.roles = this.item.role_id
					this.title = '设置用户权限'
					this.submitText = '确定'
				}
			},
			close() {
				this.dialogVisible = false
				this.type="";
				this.username="";
				this.item = {};
				this.permName=[]
			},
			//新增
			onSubmit() {
				if (this.type === 'new') {
					//新增商家
					if (isEmpty(this.username, '请输入角色名称')) {
						return
					}
					
					let data = {
						name: this.username
					}
					this.dialogloading = true
					this.$api.addRole(data).then(res => {
						// console.log(res)
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.close();
							this.page = 1;
							this.getRoles()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
					}).catch(error => {
						this.dialogloading = false
					})
				}
				else if(this.type == 'power'){
					let permId=[];
					for(let i = 0;i<this.permName.length;i++){
						for(let j = 0; j<this.powerList.length;j++){
							if(this.permName[i] == this.powerList[j].name){
								permId.push(this.powerList[j].id)
							}
						}
					}
					let data ={
						role_id:this.item.id,
						permission_id:JSON.stringify(permId)
					}
					console.log(data)
					this.configRolePermission(data)
				}
			},
			//重置用户密码
			resPassword(item) {
				this.$confirm('确认重置用户' + item.username + '的密码?', '重置密码', {
					confirmButtonText: '重置',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						// id: item.id,
						user: item.username
					}
					this.loading = true
					this.$api.resetUserPassword(data).then(res => {
						console.log(res)
						this.loading = false
						if (res.status === 1) {
							this.$alert('新密码为:' + res.data.password, '重置密码成功', {
								confirmButtonText: '确定',
							});
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}

					}).catch(error => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消重置用户·密码'
					});
				});

			},
			//重置谷歌验证码
			setUserGoogleKey(item) {
				this.$confirm(`确认要重置${item.username}的谷歌验证码吗?`, '重置谷歌验证码', {
					confirmButtonText: '重 置',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true;
					let data = {
						user: item.username
					}
					this.$api.setUserGoogleKey(data).then(res => {
						this.loading = false;
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false;
					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消重置谷歌验证码`
					});
				});
			},
			//删除角色
			delRole(item){
				this.$confirm(`确认要删除角色${item.name}吗?`, {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true;
					let data = {
						id: item.id,
						name:item.name
					}
					this.$api.delRole(data).then(res => {
						this.loading = false;
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.getRoles()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false;
					})
				
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消删除角色`
					});
				});
			},
			//设置权限
			configRolePermission(data){
				this.dialogloading = true
				this.$api.configRolePermission(data).then(res=>{
					if(res.status == 1){
						this.dialogloading = false
						this.close()
						this.getRoles()
						this.$message({type:"success",message:res.msg})
					} else{						
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.dialogloading = false
				})
			},
			
		},
		watch: {
			total1(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 40 - 60
					console.log(this.height)
				})
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.formBox {
		width: 80%;
		margin: 0 auto;
	}
	.powerItem{
		display: inline-block;
		width: 33%;
		text-align: left;
		margin-bottom: 20px;
	}
	/* 滚动条宽度 */
	  ::-webkit-scrollbar {
	       width: 0px;
	   }
	   table tbody {
	       display: block;
	       overflow-y: auto;
	       -webkit-overflow-scrolling: touch;
			border-bottom: solid 1px #ddd;
	   }	 
	   table tbody tr:last-child td{
			border-bottom: none;
	   }	 
	   table thead tr, table tbody tr, table tfoot tr {
	       box-sizing: border-box;
	       table-layout: fixed;
	       display: table;
	       width: 100%;
	   }
	   table td{
		   word-break:break-all;
	   }
</style>
