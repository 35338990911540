<template>
	<div>
		<!-- 代付 -->
		<div class="queryBox">
			<div class="mb20">
				<el-input size="mini" placeholder="请输入四方商户订单号" v-model="trader_order_id" clearable class="mr20"
					style="width: 200px"></el-input>
				<el-input size="mini" placeholder="请输入订单号" v-model="order_id" clearable class="mr20"
					style="width: 200px"></el-input>
				<el-input size="mini" placeholder="请输入交易单号" v-model="water_no" clearable class="mr20"
					style="width: 200px"></el-input>
				<el-input size="mini" placeholder="请输入商家号" v-model="business_no" clearable class="mr20"
					style="width: 200px"></el-input>
				<el-input size="mini" placeholder="请输子订单银行卡号" v-model="childCard" clearable class="mr20"
					style="width: 200px"></el-input>
				<el-input size="mini" placeholder="请输子订单银行姓名" v-model="childName" clearable class="mr20"
					style="width: 200px"></el-input>

			</div>
			<div class="mb20">
				<el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">前一天</el-button>
				<el-date-picker size="mini" v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期" class="mr20"></el-date-picker>
				<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">后一天</el-button>


				<!-- <el-select v-model="dfOrderType" placeholder="请选择订单类型" size="mini" class="mr20" clearable>
					<el-option v-for="(item,index) in orderTypeArr" :key="index" :label="item" :value="index"></el-option>
				</el-select> -->

				<el-select v-model="state" placeholder="请选择状态" size="mini" class="mr20" clearable>
					<el-option v-for="(item,index) in stateArr" :key="index" :label="item" :value="index"></el-option>
					<el-option :key="99" label="锁定中" :value="99"></el-option>
				</el-select>
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'"
					@click="getData" :disabled="disLoading">查询</el-button>
				<el-button size="mini" type="primary" icon="el-icon-document" @click="getExcel"
					:disabled="tableData.length == 0">导出Excel订单</el-button>
				<el-button size="mini" type="primary" icon="el-icon-document"  @click="submitAllOrder"
					:disabled="!chooseList.length">导出Excel代付</el-button>
			</div>
		</div>
		<template>
			<el-table :max-height="height" :data="tableData" style="width: 100%" size="mini" :row-class-name="getRowClassName" v-loading="loading" @selection-change="handleSelectionChange">
				<el-table-column fixed type="selection" :selectable='noSelecTable' v-if="state == 2" width="55"></el-table-column>
				<el-table-column prop="trader_order_id" label="订单号" width="170px" >
					<template slot-scope="scope">

						<div v-if="scope.$index < tableData.length-2">
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">商户:{{scope.row.trader_order_id}}</span>
								<span v-if="scope.row.trader_order_id"> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.trader_order_id)"></i></span>
							</div>
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">四方:{{scope.row.order_id}}</span>
								<span v-if="scope.row.order_id"> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.order_id)"></i></span>
							</div>
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">系统:{{scope.row.water_no}}</span>
								<span v-if="scope.row.water_no"> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.water_no)"></i></span>
							</div>
						</div>


						<!-- <div class="textCenter" v-if="scope.$index < tableData.length-2">
							{{scope.row.trader_order_id}}
						</div> -->
						<span v-if="scope.$index == tableData.length-2">当页总计</span>
						<span v-if="scope.$index == tableData.length-1">总计</span>

					</template>
				</el-table-column>
				<!-- <el-table-column label="订单编号" prop="order_id" >
					<template slot-scope="scope">
						<span v-if="scope.$index < tableData.length-2">{{scope.row.order_id}}</span>

					</template>
				</el-table-column>
				<el-table-column label="平台交易号" prop="water_no"  width="150px">
					<template slot-scope="scope">
						<span v-if="scope.$index < tableData.length-2">{{scope.row.water_no}}</span>
					</template>
				</el-table-column> -->
				<el-table-column label="金额" >
					<template slot-scope="props">
						<!-- <span v-if="props.row.id">{{props.row.add_gold | tofixed}}</span> -->
						<span>{{props.row.gold | tofixed}}</span>
					</template>
				</el-table-column>
				<el-table-column label="手续费" >
					<template slot-scope="props">
						<span v-if="props.row.id">费率(‰):{{props.row.fee | tofixed}}</span> <br/>
						<span v-if="props.row.id">费用:{{props.row.fee_gold_tra | tofixed}}</span>
					</template>
				</el-table-column>
				<el-table-column label="单笔费用" >
					<template slot-scope="props">
						<span v-if="props.row.id">{{props.row.fee_gold_num | tofixed}}</span>
					</template>
				</el-table-column>
				<el-table-column label="商户实扣" >
					<template slot-scope="props">
						<span v-if="props.row.id">{{props.row.trader_gold | tofixed}}</span>
					</template>
				</el-table-column>
				<el-table-column label="交易信息" >
					<template slot-scope="scope">
						<div v-if="scope.row.id">
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">类型:{{scope.row.type_name}}</span>
							</div>
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">名称:{{scope.row.business_name}}</span>
							</div>
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">编号:{{scope.row.business_no}}</span>
							</div>
						</div>
					</template>

					<!-- <el-table-column label="提交用户类型" prop="type_name"  width="100px"></el-table-column>
					<el-table-column label="用户信息"  width="200px">
						<template slot-scope="props">

							{{props.row.business_name}}
							<br>
							{{props.row.business_no}}

						</template>
					</el-table-column> -->
				</el-table-column>
				<!-- <el-table-column label="代付信息" > -->
				<!-- <el-table-column label="通道" prop="pay_type_name"  width="100px"></el-table-column> -->
				<el-table-column label="代付信息"  width="200px">
					<template slot-scope="scope">
						<div class="dblclick" v-if="scope.row.id"   @dblclick="dbCopy(scope.row.bank,scope.row.name,scope.row.card)">
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">{{scope.row.bank}}</span>
							</div>
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">{{scope.row.name}}</span>
							</div>
							
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">{{scope.row.card}}</span>

							</div>
						</div>

						<!-- <div>
							{{props.row.bank}}
							<br>
							{{props.row.name}}
							<br>
							{{props.row.card}}
						</div> -->
					</template>
				</el-table-column>
				<!-- </el-table-column> -->
				<el-table-column label="时间" prop="add_time"  width="120px">
					<template slot-scope="scope">
						<div v-if="scope.row.id">
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">提交:{{scope.row.add_time}}</span>
							</div>
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">处理:{{scope.row.action_time}}</span>
							</div>
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover" v-if='scope.row.add_time && scope.row.action_time'>耗时:{{getTimeDifference(scope.row.add_time,scope.row.action_time)}}</span>
							</div>
						</div>
						<!-- <div v-if="scope.row.add_time">
							{{scope.row.add_time.split(" ")[0]}}
							<br>
							{{scope.row.add_time.split(" ")[1]}}
						</div> -->
					</template>
				</el-table-column>
				<!-- <el-table-column label="处理时间" prop="action_time"  width="120px">
					<template slot-scope="scope">
						<div v-if='scope.row.add_time && scope.row.action_time' class="textCenter">
							{{getTimeDifference(scope.row.add_time,scope.row.action_time)}}
						</div>
						<div v-if="scope.row.action_time">
							{{scope.row.action_time.split(" ")[0]}}
							<br>
							{{scope.row.action_time.split(" ")[1]}}
						</div>
					</template>
				</el-table-column> -->
				<el-table-column label="订单状态" >
					<template slot-scope="props">
						<span :class="props.row.state== 1?'csuccess':'cdanger'">{{props.row.state_name}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作人"  :show-overflow-tooltip="true"> 
					<template slot-scope="scope">
						<div v-if="scope.row.id">
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">操作:{{scope.row.auditor}}</span>
							</div>
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">锁定:{{scope.row.owner}}</span>
							</div>
						</div>
					</template> 

					<!--<template slot-scope="props">
						<span>{{props.row.auditor}}</span><br>
						<span>{{props.row.note}}</span>
					</template> -->
				</el-table-column>
				<!-- <el-table-column label="锁定人" prop="owner" > </el-table-column> -->

				<el-table-column label="操作" align="" width="180px">
					<template slot-scope="props">
						<div class="flexX flexWrap">
							<div class="mb10 mr10" v-if="props.row.id && props.row.state == 1 && props.row.is_rush == 0">
								<el-button size="mini" @click="cancle(props.row,'parent',props.$index)" type="warning">冲正</el-button>
							</div>
							<div class="mb10 mr10" v-if="props.row.id && props.row.state == 1">
								<el-button size="mini" @click="noticeOut(props.row,props.$index)">发送回调</el-button>
							</div>
							<!-- <el-button v-if="props.row.id && props.row.state == 2 && props.row.allowOrderClose == 1" size="mini" @click="closeMainOrder(props.row,props.$index)">关闭订单</el-button> -->
							
							<div class="mt10 mr10" v-if="props.row.id && props.row.state == 2 && props.row.allowOrderClose == 1">
								<el-button size="mini" @click="showCloseOrder(props.row,props.$index)">关闭订单</el-button>							
							</div>
							
							<!-- <div v-if="props.row.id && props.row.state == 2 && props.row. allowOrderDiscard==1">
								<el-button class="mt10" size="mini" @click="showChooseCard(props.row,props.$index)"
									type="info">手动选卡</el-button>
							</div>	 -->
							<div class="mt10 mr10" v-if="props.row.id && props.row.allowOrderEnsure==1 && (props.row.state == 2 || props.row.state == 7)">
								<el-button size="mini" @click="ensureMain(props.row,props.$index)" type="primary">确认订单</el-button>
							</div>
							
							<div class="mt10 mr10" v-if="props.row.id && props.row.owner == username">
								<el-button size="mini" @click="owner(props.row,props.$index)" type="">解锁</el-button>
							</div>
							<div class="mt10 mr10" v-if="props.row.id && props.row.owner == ''">
								<el-button size="mini" @click="owner(props.row,props.$index)" type="">锁定</el-button>
							</div>
							<div class="mt10 mr10" v-if="props.row.id && props.row.state == 3">
								<el-button size="mini" @click="review(props.row,props.$index)">审核</el-button>
							</div>
							<!-- <div v-if="props.row.id && props.row.state == 2 && props.row. again_dis_card==0">
								<el-button class="mt10" size="mini" @click="showChooseCard(props.row,props.$index)"
									type="info">手动选卡</el-button>
							</div> -->
						</div>
					</template>
				</el-table-column>
			</el-table>
		</template>

		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr"
				:page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		<el-dialog center class="dialog" top="100px" title="" :visible.sync="dialogVisible" @close="close" width="70%"
			:close-on-click-modal="false">
			<div class="formBox" v-loading="dialogloading">
				<div>
					<el-tabs type="border-card" @tab-click="getInfoData" v-model="activeName">
						<el-tab-pane label="搬砖工" name='info'>
							<div class="flexX flexAround flexTop">

								<div>
									<el-form label-position="right" label-width="80px">
										<el-form-item label="用户名:">{{infoTotalData.name}}</el-form-item>
										<el-form-item label="账号:">{{infoTotalData.username}}</el-form-item>
										<el-form-item label="Skype:">
											<!-- {{infoTotalData.skype}} -->
											<el-link type="primary" :href="`skype:${infoTotalData.skype}?chat`"
												οnclick="return skypeCheck();">{{infoTotalData.skype}}</el-link>
										</el-form-item>
										<el-form-item label="手机号:">{{infoTotalData.telephone}}</el-form-item>
										<el-form-item label="上级:">{{infoTotalData.all_parent_name}}</el-form-item>
										<el-form-item label="银行:">{{infoTotalData.bank}}</el-form-item>
										<el-form-item label="卡号:">{{infoTotalData.card}}</el-form-item>
										<el-form-item label="姓名:">{{infoTotalData.true_name}}</el-form-item>
									</el-form>
								</div>
								<div>
									<el-form label-position="right" label-width="80px">
										<el-form-item label="提现金额:">{{infoTotalData.tx_money | tofixed}}</el-form-item>
										<!-- <el-form-item label="提成:">{{infoTotalData.total_tc | tofixed}}</el-form-item> -->
										<el-form-item label="今日奖金:">{{infoTotalData.today_yj | tofixed}}</el-form-item>
										<el-form-item label="今日提成:">{{infoTotalData.today_tc | tofixed}}</el-form-item>
										<el-form-item label="卡数量:">{{infoTotalData.card_count}}</el-form-item>
										<el-form-item label="充值状态:"> <span
												:class="infoTotalData.pay_state==0?'csuccess':'cdanger'">{{infoTotalData.pay_state==0?'启用':'禁用'}}</span>
										</el-form-item>
										<el-form-item label="代付状态:"> <span
												:class="infoTotalData.repay_state==0?'csuccess':'cdanger'">{{infoTotalData.repay_state==0?'启用':'禁用'}}</span>
										</el-form-item>
										<el-form-item label="通道:">{{infoTotalData.business_name}}</el-form-item>
									</el-form>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="卡列表" name='bankList'>
							<div class="mb20 ">
								<span class="mr20 mb20">用户名：{{infoTotalData.name}}</span>
								<span class="mr20 mb20">账号：{{infoTotalData.username}}</span>
								<span
									class="mr20 mb20">累计收款上限：{{cardListData.length>0?cardListData[cardListData.length-1].eve_used_money:'0.00'}}</span>
								<span
									class="mr20 mb20">留存上限：{{cardListData.length>0?cardListData[cardListData.length-1].keep_gold:'0.00'}}</span>
								<span
									class="mr20 mb20">真实余额：{{cardListData.length>0?cardListData[cardListData.length-1].true_quota:'0.00'}}</span>

							</div>
							<div class="mb20">
								<el-button size="mini" plain class="mr20" @click="cardListsetDate(-1)"
									:disabled="cardListdisLoading">前一天</el-button>
								<el-date-picker size="mini" value-format="yyyy-MM-dd" v-model="cardListdate"
									type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
									class="mr20"></el-date-picker>
								<el-button size="mini" plain class="mr20" @click="cardListsetDate(1)"
									:disabled="cardListdisLoading">后一天</el-button>
								<el-button size="mini" type="primary"
									:icon="cardListdisLoading?'el-icon-loading':'el-icon-search'"
									:disabled="cardListdisLoading" @click="cardListgetData">搜索</el-button>
							</div>
							<el-table size="mini" :data="cardListData" border="" stripe max-height="500">
								<el-table-column prop="card" label="卡号" width="145px" >
									<template slot-scope='scope'>
										<div v-if="scope.$index < cardListData.length-2">{{scope.row.card}}</div>
										<div v-else="">{{scope.row.all_parent_name}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="bank" label="银行" ></el-table-column>
								<el-table-column prop="true_name" label="姓名" ></el-table-column>
								<el-table-column prop="telephone" label="电话" ></el-table-column>
								<el-table-column label="昨日结余" >
									<template slot-scope='scope'>
										<div>{{scope.row.yes_sys_quota | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="今日收款" >
									<template slot-scope='scope'>
										<div>{{scope.row.money_in | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="今日出款" >
									<template slot-scope='scope'>
										<div>{{scope.row.money_out | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="当前余额" >
									<template slot-scope='scope'>
										<div>{{scope.row.sys_quota | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="累计收款上限" >
									<template slot-scope='scope'>
										<div>{{scope.row.eve_used_money | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="留存上限" >
									<template slot-scope='scope'>
										<div>{{scope.row.keep_gold | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="真实余额" >
									<template slot-scope='scope'>
										<div>{{scope.row.true_quota | tofixed}}</div>
									</template>
								</el-table-column>
							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="diahandleSizeChange"
									@current-change="diahandleCurrentChange" :current-page="diapage"
									:page-sizes="diapageSizesArr" :page-size="diapageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="diatotal"></el-pagination>
							</div>
						</el-tab-pane>
						<el-tab-pane label="卡进账订单" name='cardNote'>
							<div class="mb20">
								<span class="mr20">用户名：{{infoTotalData.name}}</span><span class="mr20">账号：{{infoTotalData.username}}</span>
								<span>时间：</span>
								<el-date-picker size="mini" v-model="dateIn" type="datetimerange" range-separator="至" start-placeholder="开始日期"
								 end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
								 <el-button size="mini" type="primary" :icon="disLoadingIn?'el-icon-loading':'el-icon-search'" @click="getDataIn('in')" :disabled="disLoadingIn">搜索</el-button>
							</div>
							<el-table size="mini" :data="cardNoteData" border="" stripe max-height="500">
								<el-table-column prop="true_name" label="姓名" >
									<template slot-scope='scope'>
										<div v-if="scope.row.id">{{scope.row.true_name}}</div>
										<div v-if="!scope.row.id">{{scope.row.name}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="card" label="卡号" ></el-table-column>
								<el-table-column prop="order_id" label="订单号" ></el-table-column>

								<el-table-column label="金额" >
									<template slot-scope='scope'>
										<div>{{scope.row.gold | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="action_time" label="时间" ></el-table-column>

							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="nodehandleSizeChange"
									@current-change="nodehandleCurrentChange" :current-page="nodepage"
									:page-sizes="nodepageSizesArr" :page-size="nodepageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="nodetotal"></el-pagination>
							</div>
						</el-tab-pane>

						<el-tab-pane label="卡出账订单" name='cardNoteOut'>
							<span>时间：</span>
							<el-date-picker size="mini" v-model="dateOut" type="datetimerange" range-separator="至" start-placeholder="开始日期"
							 end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
							 <el-button size="mini" type="primary" :icon="disLoadingOut?'el-icon-loading':'el-icon-search'" @click="getDataIn('out')" :disabled="disLoadingOut">搜索</el-button>
							<el-table size="mini" :data="cardNoteOutData" border="" stripe max-height="500">
								<el-table-column prop="true_name" label="姓名" >
									<template slot-scope='scope'>
										<div v-if="scope.row.id">{{scope.row.true_name}}</div>
										<div v-if="!scope.row.id">{{scope.row.name}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="card" label="卡号" ></el-table-column>
								<el-table-column prop="order_id" label="订单号" ></el-table-column>
								<el-table-column label="金额" >
									<template slot-scope='scope'>
										<div>{{scope.row.gold | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="action_time" label="时间" ></el-table-column>
							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="outhandleSizeChange"
									@current-change="outhandleCurrentChange" :current-page="outpage"
									:page-sizes="outpageSizesArr" :page-size="outpageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="outtotal"></el-pagination>
							</div>
						</el-tab-pane>

						<!-- 卡账单 -->
						<el-tab-pane label="卡账单" name='cardBill'>
							<div class="mb20">
								<span class="mr20">用户名：{{infoTotalData.name}}</span>
								<span class="mr20">账号：{{infoTotalData.username}}</span>
								<span class="mr20" v-if="cardBillData.length>0">姓名：{{cardBillData[0].name}}</span>
								<span class="mr20" v-if="cardBillData.length>0">卡号：{{cardBillData[0].card}}</span>
								<span>更新时间：{{bill_update_time}}</span>
							</div>
							<el-table size="mini" :data="cardBillData" border="" stripe max-height="500px">
								<el-table-column type="index" label="序号"  width="50px"></el-table-column>
								<el-table-column prop="water_no" label="系统订单号"  min-width="140px"></el-table-column>
								<el-table-column label="交易状态"  min-width="140px">
									<template slot-scope='scope'>
										<div v-if="scope.row.state_name">{{scope.row.state_name}}</div>
										<div v-if="scope.row.type == 1 && (scope.row.state == 7 || scope.row.water_no == '')">
											<el-link type="primary"@click="billshowDia(scope.row,scope.$index,'billadd')">补单</el-link>
										</div>
										<div v-if="scope.row.type == 0 && scope.row.water_no == '' && scope.row.repay_is_loss == 0">
											<el-link type="danger"@click="billshowDia(scope.row,scope.$index,'billloss')">报损</el-link>
										</div>
										<div v-if="scope.row.type == 0 && scope.row.water_no == '' && scope.row.repay_is_loss == 1">
											已报损
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="trans_date" label="交易时间"  min-width="140px">
								</el-table-column>
								<el-table-column label="入款记录" >
									<template slot-scope='scope'>
										<div v-if="scope.row.type == 1">{{scope.row.amount | tofixed}}</div>
										<!-- <div v-if="scope.row.type == 0">0</div> -->
									</template>
								</el-table-column>
								<el-table-column label="出款记录" >
									<template slot-scope='scope'>
										<div v-if="scope.row.type == 0">{{scope.row.amount | tofixed}}</div>
										<!-- <div v-if="scope.row.type == 1">0</div> -->
									</template>
								</el-table-column>
								<el-table-column prop="transplace" label="收支摘要"  min-width="140px">
									<template slot-scope='scope'>
										<div :class="scope.row.type=='0'?'cdanger':'csuccess'">{{scope.row.transplace}}</div>
									</template>
								</el-table-column>
								<el-table-column label="账户余额" >
									<template slot-scope='scope'>
										<div>{{scope.row.keep_amount | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="pay_card" label="对方账户"  min-width="140px">
								</el-table-column>
								<el-table-column prop="pay_name" label="对方户名"  width="140px">
								</el-table-column>
								<el-table-column prop="remark" label="备注"  width="140px">
								</el-table-column>

							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="billhandleSizeChange"
									@current-change="billhandleCurrentChange" :current-page="billpage"
									:page-sizes="billpageSizesArr" :page-size="billpageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="billtotal"></el-pagination>
							</div>
						</el-tab-pane>

					</el-tabs>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="close">关闭</el-button>
				</div>
			</div>
		</el-dialog>

		<el-dialog center class="dialog" top="150px" :title="rushType == 'budan'?'补单':'冲正订单'"
			:visible.sync="dialogVisible2" @close="close2" :close-on-click-modal="false">
			<div v-loading="dialogloading">
				<div class="textCenter">{{rushType == 'budan'?'补单原因':'冲正原因'}}</div>
				<div class="mt40" style="margin: 40px auto 0;">
					<el-input type="textarea" :rows="5" :placeholder="rushType == 'budan'?'请输入补单原因':'请输入冲正原因'"
						v-model="note"></el-input>
				</div>
				<div class="textCenter mt40">
					<el-button type="primary" @click="close2">取消</el-button>
					<el-button type="primary" @click="suerOrderRush">确认</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog center class="dialog" top="150px" title="审核" :visible.sync="reviewVisible"
			:close-on-click-modal="false" @close="reviewClose">
			<div v-loading="reviewloading">
				<div class="reviewBox flexX flexAround flexcenter">
					<div>
						<div class="flexX flexcenter">
							<div class="mr20">账号：</div>
							<div>{{item.business_name}}</div>
						</div>
						<div class="mt20 flexX flexcenter">
							<div class="mr20">卡号：</div>
							<div>{{item.card}}</div>
						</div>
						<div class="mt20 flexX flexcenter">
							<div class="mr20">姓名：</div>
							<div>{{item.type_name}}</div>
						</div>
						<div class="mt20 flexX flexcenter">
							<div class="mr20">银行：</div>
							<div>{{item.bank}}</div>
						</div>
						<div class="mt20 flexX flexcenter">
							<div class="mr20">金额：</div>
							<div>{{item.gold | tofixed}}</div>
						</div>
						<!-- <div class="flexX  flexcenter">
							<div class="mr20">订单编号:</div>
							<div>{{item.order_id}}</div>
						</div>
						<div class="mt20 flexX  flexcenter">
							<div class="mr20">平台交易号:</div>
							<div>{{item.water_no}}</div>
						</div>
						<div class="mt20 flexX  flexcenter">
							<div class="mr20">金额:</div>
							<div>{{item.gold | tofixed}}</div>
						</div> -->
					</div>
				</div>
		
				<div class="textCenter mt40">
					<el-button type="primary" @click="reviewCloseOrder">审核关闭</el-button>
					<el-button type="primary" @click="reviewPass">审核通过</el-button>
				</div>
			</div>
		
		
		</el-dialog>
		<el-dialog center class="dialog" top="50px" title="手动选卡" :visible.sync="manualVisible" @close="manualclose"
			:close-on-click-modal="false" width="90%">
			<!-- <div v-loading="manualloading"> -->
			<div>
				<div>
					<div class="flexX flexBetween mb10 fs16">
						<div class="transferTiete">可用卡列表</div>
						<div class="transferTiete">选中卡列表</div>
					</div>
					<div class="flexX transfer flexBetween">
						<div class="source">
							<!-- 添加按码商筛选，  按卡号或姓名搜索，  按金额 范围查找的功能，   卡信息展示， 调整成下面这种 -->
							<div class="searchBox">
								<el-input v-model="manualCarder_username" size="mini" type="text" style="width:100px;"
									class="mr10" placeholder="码商账号" clearable></el-input>
								<el-input v-model="manualCard" size="mini" type="text" style="width:150px;" class="mr10"
									placeholder="卡号" clearable></el-input>
								<el-input v-model="manualCard_name" size="mini" type="text" style="width:100px;"
									class="mr20" placeholder="姓名" clearable></el-input>
								<el-select v-model="canused" placeholder="请选择状态" size="mini" class="mr20 canused">
									<el-option key="1" label="可用卡" value="1"></el-option>
									<el-option key="2" label="不可用卡" value="2"></el-option>
								</el-select>
								<span class="fs12 whiteSpaceNowrap">真实余额：</span>
								<el-input v-model="manualMin_gold" size="mini" type="text" style="width:100px;"
									class="mr10" placeholder="最小" clearable></el-input>
								<span class="mr10 fs12">到</span>
								<el-input v-model="manualMax_gold" size="mini" type="text" style="width:100px;"
									class="mr10" placeholder="最大" clearable></el-input>
								<el-button class="mr10" size="mini" type="primary"
									:icon="manualQueryloading?'el-icon-loading':'el-icon-search'" @click="manualgetData"
									:disabled="manualQueryloading">查询</el-button>
								<el-link class="el-icon-refresh-right" type="primary" :disabled="manualQueryloading"
									@click="manualreferData"></el-link>
								<!-- <el-button size="mini" type="primary" :icon="manualQueryloading?'el-icon-loading':'el-icon-refresh-right'" @click="manualreferData"
								 :disabled="manualQueryloading">刷新</el-button> -->
							</div>
							<div class="sourceData">
								<el-table border :data="manualTableData" size="mini" height="100%">
									<el-table-column label="卡信息"  width="300px">
										<template slot-scope="scope">
											{{scope.row.carder_username}} | {{scope.row.true_name}} | {{scope.row.bank}}
											<br>
											{{scope.row.card}}
										</template>
									</el-table-column>
									<el-table-column label="业务余额" >
										<template slot-scope="scope">
											{{scope.row.sys_quota | tofixed(2)}}
										</template>
									</el-table-column>
									<el-table-column label="真实余额" >
										<template slot-scope="scope">
											{{scope.row.true_quota | tofixed(2)}}
										</template>
									</el-table-column>
									<el-table-column label="操作"  width="50px">
										<template slot-scope="scope">
											<el-checkbox @change="addChange(scope.row)" v-model="scope.row.selected"
												:disabled="manualloading"></el-checkbox>
										</template>
									</el-table-column>
								</el-table>

							</div>
							<div class="mt10 manualPage">
								<el-pagination @size-change="manualhandleSizeChange"
									@current-change="manualhandleCurrentChange" :current-page="manualpage"
									:page-sizes="manualpageSizesArr" :page-size="manualpageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="manualtotal">
								</el-pagination>
							</div>
						</div>
						<!-- <div class="chooseBox flexY flexcenter flexJustifyCen">
							<div>
								<el-button type="primary" icon="el-icon-d-arrow-right" :disabled="!canAdd" @click="addManualData"></el-button>
							</div>
							<div class="mt20">
								<el-button type="primary" icon="el-icon-d-arrow-left" :disabled="!canCancle" @click="cancleManualData"></el-button>
							</div>
						</div> -->
						<div class="target">
							<div class="textCenter goldBox">
								<!-- <span class="mr20">总金额：{{item.gold}}</span> -->
								<span class="mr20">总金额：{{item.allowOrderDiscardMoney}}</span>
								<span>剩余待匹配金额：{{remainderGold}}</span>

							</div>
							<div class="targetData">
								<el-table border :data="manualTableDataChoose" size="mini" height="100%">
									<el-table-column label="卡信息"  width="300px">
										<template slot-scope="scope">
											{{scope.row.carder_username}} | {{scope.row.true_name}} | {{scope.row.bank}}
											<br>
											{{scope.row.card}}
										</template>
									</el-table-column>
									<el-table-column label="业务余额" >
										<template slot-scope="scope">
											{{scope.row.sys_quota | tofixed(2)}}
										</template>
									</el-table-column>
									<el-table-column label="真实余额" >
										<template slot-scope="scope">
											{{scope.row.true_quota | tofixed(2)}}
										</template>
									</el-table-column>
									<el-table-column label="代付金额"  width="100px">
										<template slot-scope="scope">
											<el-input size="mini" type="text" v-model="scope.row.payAmount"
												@blur="getRemainderGold"></el-input>
										</template>
									</el-table-column>
									<el-table-column label="操作"  width="50px">
										<template slot-scope="scope">
											<el-checkbox @change="cancleChange(scope.row)" v-model="scope.row.selected"
												:disabled="manualloading"></el-checkbox>
										</template>
									</el-table-column>
								</el-table>
							</div>
							<div class="mt10 manualPage">
								<el-pagination @size-change="manualRighthandleSizeChange"
									@current-change="manualRighthandleCurrentChange" :current-page="manualRightpage"
									:page-sizes="manualRightpageSizesArr" :page-size="manualRightpageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="manualRighttotal">
								</el-pagination>
							</div>
						</div>
					</div>

				</div>
				<div class="textCenter mt40">
					<el-button type="primary" @click="manualclose">取消</el-button>
					<el-button type="primary" @click="manualsuer"
						:disabled="manualTableDataChoose.length==0 || manualloading">确认</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog width="800px" center top="150px"  :title="billTitle" :visible.sync="billbddialogVisible" @close="billbdclose"
			 :close-on-click-modal="false">
				<div class="formBoxbd" v-loading="billbddialogloading">
					<div v-if="billType == 'billadd'">
						<el-form label-position="right" label-width="100px">
							
							<!-- <el-form-item label="交易号">
								{{bditem.water_no}}
							</el-form-item> -->
							<!-- <el-form-item label="商家名称">
								{{billbditem.business_name}}
							</el-form-item> -->
							<el-form-item label="金额">
								{{billbditem.amount | tofixed}}元
							</el-form-item>
							<el-form-item label="订单编号">
								<!-- {{bditem.order_id}} -->
								<el-select v-model="failOrder" filterable placeholder="请选择订单编号" size="mini" class="mr20" clearable>
									<el-option v-for="(item) in failOrderList" :key="item.id" :label="item.trader_order_id+item.order_id" :value="item.id">
										 <span>{{item.trader_order_id}} | {{item.order_id}} | {{item.business_name}} | {{item.add_gold | tofixed}} | {{item.add_time}}</span>
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="补单类型">
								<el-select v-model="replenState" placeholder="请选择补单类型" size="mini" class="mr20" clearable>
									<el-option v-for="(item,index) in replenishmentStateObj" :key="index" :label="item" :value="index"></el-option>
								</el-select>
								
							</el-form-item>
							<!-- <el-form-item label="补单金额">
								<el-input v-model="amount" placeholder="请输入补单金额"></el-input>
							</el-form-item> -->
						</el-form>
					</div>
					<div v-if="billType == 'billloss'">
						<el-form label-position="right" label-width="100px">
							
							<el-form-item label="姓名">
								{{item.true_name}}
							</el-form-item>
							<el-form-item label="银行">
								{{item.bank}}
							</el-form-item>
							<el-form-item label="卡号">
								{{item.card}}
							</el-form-item>
							<el-form-item label="搬砖工">
								{{infoTotalData.name}}
							</el-form-item>
							<!-- <el-form-item label="差值">
								<span>{{item.true_quota - item.sys_quota  | tofixed}}</span>
							</el-form-item> -->
							<el-form-item label="金额">
								{{billbditem.amount | tofixed}}元
							</el-form-item>
							
							<el-form-item label="报损类型">
								<el-select v-model="losseType" filterable placeholder="请选择报损类型" size="mini" class="mr20" clearable>
									<el-option v-for="(item) in losseTypeList" :key="item.id" :label="item.label" :value="item.id"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="备注">
								<el-input size="mini" v-model="losseNote" placeholder="请输入备注" style="width: 200px;"></el-input>
							</el-form-item>
						</el-form>
					</div>
					<div class="textCenter">
						<el-button type="primary" @click="billonSubmit">确定</el-button>
					</div>
				</div>
		</el-dialog>
		
		<el-dialog width="500px" center top="150px" title="关闭订单" :visible.sync="closeOrderdialogVisible"
			@close="billbdclose" :close-on-click-modal="false">
			<div class="formBoxbd" v-loading="billbddialogloading">
				<div >
					<el-form label-position="right" label-width="100px">
						
						<el-form-item label="关闭原因">
							<el-input
							  type="textarea"
							  :rows="3"
							  placeholder="请输入订单关闭原因"
							  v-model="closeReason">
							</el-input>
		
						</el-form-item>
					</el-form>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="closeMainOrderNote">确定</el-button>
				</div>
			</div>
		</el-dialog>
		<!-- Excel 导出代付 -->
		<el-dialog center class="dialog" top="150px" title="Excel 导出代付" :visible.sync="stateVisible" :close-on-click-modal="false">
			<div>
					<span style="color: red; font-size: 1rem;margin-bottom: 0.3125rem;">以下为已导出过的订单，为防重复出款，请核实后点击确认导出</span>
					<el-table border :data="alreadyData" size="mini" height="100%">
						<el-table-column label="四方商户订单号"  >
							<template slot-scope="scope">
								{{scope.row.trader_order_id}}
							</template>
						</el-table-column>
						<el-table-column label="交易号"  >
							<template slot-scope="scope">
								{{scope.row.water_no}}
							</template>
						</el-table-column>
						<el-table-column label="提交时间"  >
							<template slot-scope="scope">
								{{scope.row.add_time}}
							</template>
						</el-table-column>
						<el-table-column label="金额"  >
							<template slot-scope="scope">
								{{scope.row.gold}}
							</template>
						</el-table-column>
						<el-table-column label="代付信息"  >
							<template slot-scope="scope">
								{{scope.row.name}} | {{scope.row.bank}}
								<br>
								{{scope.row.card}}
							</template>
						</el-table-column>
						<!-- <el-table-column label="操作"  width="50px">
							<template slot-scope="scope">
								<el-checkbox @change="addChange(scope.row)" v-model="scope.row.selected"
									:disabled="manualloading"></el-checkbox>
							</template>
						</el-table-column> -->
					</el-table>
				
			</div>
			
			<div class="textCenter mt40">
				<el-button type="primary" @click="submitExcel">确定</el-button>
				<el-button type="primary" @click="manualclose">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	import local from '@/util/local'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				expends: [],
				tableData: [],
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 100,
				total: 0,
				page: 1,
				order_id: '',
				water_no: '',
				business_no: '',
				card_name: '',
				business_name: '',
				add_gold: '',
				req_ip: '',
				pay_type: '',
				state: '',
				paywayArr: [],
				stateArr: {},
				// stateMap:{},
				date: null,
				countAll: {},
				diapageSizesArr: [20, 50, 100, 200],
				diapageSize: 20,
				diatotal: 0,
				diapage: 1,
				nodepageSizesArr: [20, 50, 100, 200],
				nodepageSize: 20,
				nodetotal: 0,
				nodepage: 1,
				outpageSizesArr: [20, 50, 100, 200],
				outpageSize: 20,
				outtotal: 0,
				outpage: 1,
				billpageSizesArr: [20, 50, 100, 200],
				billpageSize: 20,
				billtotal: 0,
				billpage: 1,
				dialogVisible: false,
				dialogloading: false,
				item: {},
				infoTotalData: {}, //搬砖工
				cardListData: [], //银行卡列表
				cardNoteData: [], //银行卡记录  流水
				cardNoteOutData: [], //卡出账记录 
				cardBillData: [], //卡账单
				bill_update_time: '', //卡账单 最后更新时间
				activeName: 'info',
				//冲正
				dialogVisible2: false,
				note: '',
				pIndex: '',
				sIndex: '',
				rushType: '',
				trader_order_id: "",
				username: local.get("username"),
				cardListdate: null,
				cardListdisLoading: false,
				//手动派卡
				manualVisible: false,
				manualQueryloading: false,
				manualloading: false,
				manualTableData: [],
				manualTableDataChoose: [],
				manualpageSizesArr: [20, 50, 100, 200,5000],
				manualpageSize: 20,
				manualtotal: 0,
				manualpage: 1,
				canAdd: false,
				canCancle: false,
				manualCarder_username: '',
				manualtype: '', //main 主订单   child子订单
				manualCard: '',
				manualCard_name: '',
				manualMin_gold: '',
				manualMax_gold: '',
				//剩余待匹配金额
				remainderGold: "",
				//右侧
				manualRightpageSizesArr: [20, 50, 100, 200,5000],
				manualRightpageSize: 20,
				manualRighttotal: 0,
				manualRightpage: 1,
				//按子订单银行卡号/姓名搜索
				childCard: '',
				childName: '',
				canused:'1',//可用卡
				//审核
				reviewloading:false,
				reviewVisible:false,
				//卡账单补单
				replenState: '',
				replenishmentStateObj: {}, //补单类型
				billbddialogVisible: false, //补单弹框
				billbditem: {}, //当前补单的数据
				index: {}, //当前item 索引
				// orderType:"",//订单操作类型  cancle 撤单
				billbddialogloading: false,
				failOrderList:[],
				failOrder:'',
				//报损
				lossmoney:'',
				losseType:'',
				losseTypeList:[{id:'1',label:'从搬砖工扣除'},{id:'2',label:'从平台扣除'}],
				losseNote:'',
				//卡账单报损
				billType:'',//billadd 卡账单补单   billloss 卡账单报损
				billTitle:'',//账单弹出框title
				height:0,
				dateIn:null,
				dateOut:null,
				disLoadingIn:false,
				disLoadingOut:false,
				dfOrderType: '-1',
				orderTypeArr: {},
				// 关闭代付订单
				closeOrderdialogVisible: false,
				closeReason: '',
				closeItem: '',
				closepIndex: '',
				// 导出Excel代付
				chooseList:[],
				alreadyData: [],
				stateVisible: false,
			};
		},
		created() {
			this.date = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date()
				.getTime(),
				true)];
			this.cardListdate = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(
				new Date().getTime(),
				true)]

			console.log('e', this.$route.query.state)
			if (this.$route.query.state) {
				this.state = this.$route.query.state;
				this.date = null
			}
			this.stateList()
			this.orderTypeList()
			this.orderOutList()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// console.log('sssssssssss', (value * 1).toFixed(2) + '')
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			submitExcel(){
				this.getExcelOut()
				this.manualclose()
			},
			  // 是否禁止选择
			noSelecTable(row, index) {
				// 回显的数据是否可选
				if (row.state !== 2) {
					return false;
				} else {
					return true;
				}
			},
			submitAllOrder(){
				let ids = this.chooseList.map(item => item.water_no)
				let data ={
					water_nos: JSON.stringify(ids) 
				}
				this.$api.checkExcelRepayOrder(data).then(res => {
					if (res.status === 1) {
						this.alreadyData = res.data
						
						if(this.alreadyData.length){
							this.stateVisible=true
						}else{
							this.getExcelOut()
						}
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
				
				
			},
			handleSelectionChange(selection, row) {
				this.chooseList = selection
				// console.log(selection)
				// for(let item of selection){
				// 	this.chooseList
				// }
				// water_no
			},
			showCloseOrder(item, pIndex){
				this.closeOrderdialogVisible = true
				this.closeItem = item
				this.closepIndex = pIndex
			},
			//关闭主订单
			closeMainOrderNote() {
				if(isEmpty(this.closeReason, '关闭原因不能为空')){
					return;
				}
				this.$confirm('确认关闭订单' + this.closeItem.order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: this.closeItem.id,
						note: this.closeReason
					}
					this.loading = true
					this.$api.closeMainOrder(data).then(res => {
						this.loading = false
						this.$api.outInfo({
							id: this.tableData[this.closepIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.closepIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						if (res.status == 1) {
							this.closeOrderdialogVisible = false
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.$api.outInfo({
							id: this.tableData[this.closepIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.closepIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消关闭订单'
					});
				});
			},
			setDate(day) {
				let data = this.date ? this.date[0] : this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate, newDate]
				this.getData()
			},
			cardListsetDate(day) {
				let data = this.cardListdate ? this.cardListdate[0] : this.$util.timestampToTime(new Date().getTime(),
					true)
				let newDate = this.$util.getLastDate(data, day);
				this.cardListdate = [newDate, newDate]
				this.cardListgetData()
				// console.log(this.date)
			},
			cardListgetData() {
				this.diapage = 1;
				this.cardList()
			},
			getTimeDifference(startTime, endTime) {
				return this.$util.intervalTime(startTime, endTime)
			},
			//设置table中的扩展项，展开的id，此处我需要全部展开
			// getExpends() {
			// 	let tableData = this.tableData.filter(item => {
			// 		if (item.id) {
			// 			return item
			// 		}
			// 	})
			// 	var Id = tableData.map(item => {
			// 		return item.id
			// 	})
			// 	this.expends = Id
			// 	// console.log(this.expends)
			// },
			// getRowKeys(row) {
			// 	return row.id
			// },
			//搜索按钮
			getData() {
				this.page = 1;
				this.orderOutList();
			},
			//总计
			orderCountAll() {
				this.$api.orderCountAll({}).then(res => {
					// console.log(res)
					if (res.status == 1) {
						this.countAll = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},

			getRowClassName({
				row,
				rowIndex
			}) {
				if (!row.id) {
					return 'row-expand-cover';
				}
			},

			//导出Excel
			getExcel() {
				//manager/order/exportList
				// console.log(this.$baseURL)
				// let data = {
				let username = local.get('username');
				let tid = local.get('tid')
				let start_time = this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), true) : '';
				let end_time = this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), true) : '';
				// }
				let href =
					`${this.$baseURL}/manager/order/exportOutList?username=${username}&tid=${tid}&order_id=${this.order_id}&water_no=${this.water_no}&business_no=${this.business_no}&card_name=${this.card_name}&business_name=${this.business_name}&add_gold=${this.add_gold}&req_ip=${this.req_ip}&pay_type=${this.pay_type}&state=${this.state}&start_time=${start_time}&end_time=${end_time}&type=2`
				// console.log(href)
				window.location.href = href
			},
			//导出Excel
			getExcelOut() {
				//manager/order/exportList
				// console.log(this.$baseURL)
				// let data = {
				let username = local.get('username');
				let tid = local.get('tid')
				// }
				let ids = this.chooseList.map(item => item.water_no)
				let href =
					`${this.$baseURL}/manager/order/exportExcelRepayOrder?username=${username}&tid=${tid}&water_nos=[${ids}]`
				// console.log(href)
				// window.location.href = href
				window.open(href)
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.orderOutList()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.orderOutList()
			},
			diahandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.diapageSize = val
				this.diapage = 1
				this.cardList()
			},
			diahandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.diapage = val
				this.cardList()
			},

			nodehandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.nodepageSize = val
				this.nodepage = 1
				this.moneyList()
			},
			nodehandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.nodepage = val
				this.moneyList()
			},
			outhandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.outpage = 1
				this.outpageSize = val
				this.outList()
			},
			outhandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.outpage = val
				this.outList()
			},
			billhandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.billpage = 1
				this.billpageSize = val
				this.cardBill()
			},
			billhandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.billpage = val
				this.cardBill()
			},
			paywayAll() {
				this.$api.paywayAll({}).then(res => {
					if (res.status === 1) {
						this.paywayArr = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			stateList() {
				this.$api.outStateList({}).then(res => {
					if (res.status === 1) {
						this.stateArr = res.data
						// this.stateArr.forEach(item=>{
						// 	this.stateMap[item.id] = item.note
						// })
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			orderTypeList() {
				this.$api.outTypeList({}).then(res => {
					if (res.status === 1) {
						this.orderTypeArr = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			orderOutList() {
				let data = {
					type: this.dfOrderType,
					order_id: this.order_id,
					water_no: this.water_no,
					business_no: this.business_no,
					business_name: this.business_name,
					state: this.state,
					start_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), true) : '',
					end_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), true) : '',
					page: this.page,
					size: this.pageSize,
					trader_order_id: this.trader_order_id,
					card: this.childCard,
					card_name: this.childName,
					type: 11,
				}
				// this.loading = true
				this.disLoading = true
				this.$api.orderOutList(data).then(res => {
					this.loading = false;
					this.disLoading = false;
					if (res.status === 1) {
						this.tableData = res.data.data;
						this.total = res.data.total;
						// this.getExpends();
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			gotopage() {
				this.$router.push({
					path: '/orderInfo'
				})
			},
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return 'success-rowT';
				} else {
					return 'success-row';
				}
				// return '';
			},
			//查看代理
			queryAgent(id) {
				// this.dialogloading = true
				this.$api.showAgent({
					carder_id: id
				}).then(res => {
					this.dialogloading = false
					if (res.status === 1) {
						let agent = res.data.join(' -> ')
						this.$alert('上级代理为:' + agent, '上级代理', {
							confirmButtonText: '确定',
						});
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}

				}).catch(error => {
					this.dialogloading = false
				})

			},
			showInfo(item) {
				// this.$router.push({path:'/orderInfo'})
				this.dialogVisible = true;
				this.item = item
				this.infoTotal(item)
			},
			getInfoData(val) {
				if (val.name == 'info') {
					if (JSON.stringify(this.infoTotalData) == '{}') {
						this.infoTotal()
					}
				}
				if (val.name == 'bankList') {
					if (this.cardListData.length == 0) {
						this.cardList()
					}
				}
				if (val.name == 'cardNote') {
					// this.dateIn = [this.$util.timestampToTime(new Date().getTime(), true) + ' 00:00:00', this.$util.timestampToTime(new Date().getTime(), true) + ' 23:59:59']
					if (this.cardNoteData.length == 0) {
						this.moneyList()
					}
				}
				if (val.name == 'cardNoteOut') {
					// this.dateIn = [this.$util.timestampToTime(new Date().getTime(), true) + ' 00:00:00', this.$util.timestampToTime(new Date().getTime(), true) + ' 23:59:59']					
					if (this.cardNoteOutData.length == 0) {
						this.outList()
					}
				}
				if (val.name == 'cardBill') {
					if (this.cardBillData.length == 0) {
						this.cardBill()
					}
				}
				// console.log('val', val)
			},
			infoTotal(item) {
				let data = {
					id: item.bank_card_id,
					type: 2
				}
				// this.dialogloading = true
				this.$api.infoTotal(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.infoTotalData = res.data
					} else {
						this.$message({
							"type": "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			cardList(item) {
				let data = {
					carder_id: this.infoTotalData.id,
					size: this.diapageSize,
					page: this.diapage,
					startdate: this.cardListdate ? this.cardListdate[0] : '',
					enddate: this.cardListdate ? this.cardListdate[1] : '',

					// type:2
				}

				// this.dialogloading = true
				this.cardListdisLoading = true
				this.$api.cardList(data).then(res => {
					this.dialogloading = false
					this.cardListdisLoading = false
					if (res.status == 1) {
						this.cardListData = res.data.data
						this.diatotal = res.data.total

					} else {
						this.$message({
							"type": "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
					this.cardListdisLoading = false
				})
			},
			close() {
				this.dialogVisible = false
				this.item = {}
				this.infoTotalData = {} //搬砖工
				this.cardListData = [] //银行卡列表
				this.cardNoteData = [] //银行卡记录  流水
				this.cardNoteOutData = []
				this.cardBillData = []
				this.activeName = 'info'
				this.diapage = 1
				this.nodepage = 1
				this.outpage = 1
				this.billpage = 1
				this.dateIn=null
				this.dateOut=null
				this.disLoadingIn=false
				this.disLoadingOut=false
				this.cardListdate = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(
					new Date().getTime(),
					true)]

			},
			close2() {
				this.dialogVisible2 = false
				this.note = ""

			},
			moneyList() {
				let data = {
					page: this.nodepage,
					size: this.nodepageSize,
					id: this.item.bank_card_id,
					startdate:this.dateIn?this.$util.timestampToTime(Date.parse(new Date(this.dateIn[0]))):"",
					endate:this.dateIn?this.$util.timestampToTime(Date.parse(new Date(this.dateIn[1]))):""
				}
				this.disLoadingIn = true
				// this.dialogloading = true
				this.$api.moneyList(data).then(res => {
					this.dialogloading = false
					this.disLoadingIn = false
					if (res.status == 1) {
						this.cardNoteData = res.data.data
						this.nodetotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {					
					this.disLoadingIn = false
					this.dialogloading = false
				})
			},
			outList() {
				let data = {
					page: this.outpage,
					size: this.outpageSize,
					id: this.item.bank_card_id,
					startdate:this.dateOut?this.$util.timestampToTime(Date.parse(new Date(this.dateOut[0]))):"",
					endate:this.dateOut?this.$util.timestampToTime(Date.parse(new Date(this.dateOut[1]))):"",
					
				}
				
				this.disLoadingOut = true
				// this.dialogloading = true
				this.$api.outList(data).then(res => {
					this.dialogloading = false
					this.disLoadingOut = false
					if (res.status == 1) {
						this.cardNoteOutData = res.data.data
						this.outtotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
					this.disLoadingOut = false
				})
			},
			//卡账单
			cardBill() {
				let data = {
					page: this.billpage,
					size: this.billpageSize,
					id: this.item.bank_card_id,
				}
				// this.dialogloading = true
				this.$api.cardBill(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.cardBillData = res.data.data
						this.billtotal = res.data.total
						this.bill_update_time = res.data.bill_update_time
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			cancle(item, type, index, sindex) {
				this.rushType = type
				this.dialogVisible2 = true;
				this.item = item;
				this.pIndex = index;
				this.sIndex = sindex;

			},
			suerOrderRush() {
				if (this.rushType == "child") {
					this.rushCard();
				}
				if (this.rushType == "parent") {
					this.orderRush()
				}
				if (this.rushType == "budan") {
					//补单
					this.repayReplenishment()
				}
			},
			rushCard() {
				this.$confirm('确认冲正订单' + this.item.order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: this.item.id,
						note: this.note
					}
					this.dialogloading = true
					this.$api.rushCard(data).then(res => {
						this.dialogloading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						if (res.status == 1) {
							this.close2()
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消冲正订单'
					});
				});
			},
			orderRush() {
				this.$confirm('确认冲正订单' + this.item.order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: this.item.id,
						note: this.note
					}
					this.dialogloading = true
					this.$api.orderRush(data).then(res => {
						this.dialogloading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						if (res.status == 1) {

							this.close2();
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						this.dialogloading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消冲正订单'
					});
				});
			},
			suerOrder(item, pIndex, sIndex) {
				this.pIndex = pIndex
				this.sIndex = sIndex
				this.$confirm('确认订单' + item.order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					// return
					this.$api.ensureOrder(data).then(res => {
						this.loading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						if (res.status == 1) {
							let nowTime = this.$util.timestampToTime(new Date().getTime());
							let username = local.get("username")
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消确认订单'
					});
				});
			},
			auditSuerOrder(item, pIndex, sIndex) {
				this.pIndex = pIndex
				this.sIndex = sIndex
				this.$confirm('审核通过订单' + item.order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					// return
					this.$api.subOrderCheck(data).then(res => {
						this.loading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						if (res.status == 1) {
							let nowTime = this.$util.timestampToTime(new Date().getTime());
							let username = local.get("username")
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消确认订单'
					});
				});
			},
			auditBackOrder(item, pIndex, sIndex) {
				this.pIndex = pIndex
				this.sIndex = sIndex
				this.$confirm('审核驳回订单' + item.order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					// return
					this.$api.subOrderReject(data).then(res => {
						this.loading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						if (res.status == 1) {
							let nowTime = this.$util.timestampToTime(new Date().getTime());
							let username = local.get("username")
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消确认订单'
					});
				});
			},
			ensureMain(item, pIndex) {
				this.pIndex = pIndex
				this.$confirm('确认订单' + item.order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					// return
					this.$api.ensureMain(data).then(res => {
						this.loading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						if (res.status == 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消确认订单'
					});
				});
			},
			//关闭主订单
			closeMainOrder(item, pIndex) {
				this.pIndex = pIndex
				this.$confirm('确认关闭订单' + item.order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					this.$api.closeMainOrder(data).then(res => {
						this.loading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						if (res.status == 1) {

							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消关闭订单'
					});
				});
			},
			//关闭子订单
			closeOrder(item, pIndex, sIndex) {
				this.pIndex = pIndex
				this.sIndex = sIndex
				this.$confirm('确认关闭订单' + item.order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					this.$api.closeOrder(data).then(res => {
						this.loading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						if (res.status == 1) {

							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消关闭订单'
					});
				});
			},
			//重新派卡
			disCard(item, pIndex, sIndex) {
				this.pIndex = pIndex
				this.sIndex = sIndex
				this.$confirm('确认订单' + item.order_id + '重新派卡吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					this.$api.disCard(data).then(res => {
						this.loading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						if (res.status == 1) {

							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消订单重新派卡'
					});
				});

			},
			//审核按钮
			review(item, index) {
				this.dataIndex = index
				console.log(this.dataIndex)
				this.item = item
				this.reviewVisible = true
			},
			reviewClose() {
				this.reviewVisible = false
				this.item = {}
			},
			reviewPass() {
				this.$confirm('确认通过审核吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					//审核通过
					let data = {
						id: this.item.id,
						type: 1
					}
					this.reviewloading = true;
					// this.tableData[this.dataIndex].state = 2
			
					this.checkMentionOrder(data, 1)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消审核'
					});
				});
			},
			reviewCloseOrder() {
				this.$confirm('确认关闭审核吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					//审核关闭
					let data = {
						id: this.item.id,
						type: 2
					}
					this.reviewloading = true;
					this.checkMentionOrder(data, 2)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消关闭审核'
					});
				});
			},
			checkMentionOrder(data, type) {
				this.$api.checkMentionOrder(data).then(res => {
					this.reviewloading = false;
					this.$api.outInfo({
						id: this.item.id
					}).then(subRes => {
						if (subRes.status == 1) {
							this.tableData.splice(this.pIndex, 1, subRes.data)
						} else {
							this.$message({
								type: 'error',
								message: subRes.msg
							})
						}
					})
					if (res.status == 1) {
						this.reviewClose()
						let nowTime = this.$util.timestampToTime(new Date().getTime());
						let username = local.get("username")
			
						this.$message({
							type: "success",
							message: res.msg
						})
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.$api.outInfo({
						id: this.item.id
					}).then(subRes => {
						if (subRes.status == 1) {
							this.tableData.splice(this.pIndex, 1, subRes.data)
						} else {
							this.$message({
								type: 'error',
								message: subRes.msg
							})
						}
					})
					this.reviewloading = false;
				})
			},
			noticeOut(item) {
				this.$confirm('确认发送回调吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					this.$api.noticeOut(data).then(res => {
						this.loading = false;
						if (res.status == 1) {
							this.$message({
								type: 'success',
								message: "发送回调成功"
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消发送回调'
					});
				});
			},
			repayReplenishment() {
				this.$confirm('确认补单' + this.tableData[this.pIndex].cardItem[this.sIndex].card_order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: this.tableData[this.pIndex].cardItem[this.sIndex].id,
						note: this.note
					}
					this.dialogloading = true
					this.$api.repayReplenishment(data).then(res => {
						this.dialogloading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						if (res.status == 1) {
							this.close2()
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消补单'
					});
				});
			},
			owner(item, pIndex) {
				this.pIndex = pIndex;
				this.item = item;
				let tipmsg = this.item.owner == '' ? '锁定' : '解锁'
				this.$confirm('确认' + tipmsg + '订单' + item.order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: this.item.id,
						type: this.item.owner == '' ? 1 : 2
					}
					this.loading = true
					this.$api.owner(data).then(res => {
						this.loading = false
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.$api.outInfo({
							id: this.item.id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
					}).catch(error => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消' + tipmsg + '订单'
					});
				});
			},
			// showChooseCard(scope.row,props.$index,scope.$index)
			//手动选卡
			showChooseCard(item, pIndex, sIndex) {
				if (sIndex || sIndex == 0 || sIndex == '0') {
					//子订单
					this.sIndex = sIndex;
					this.manualtype = 'child'
				} else {
					this.manualtype = 'main'
				}
				this.manualVisible = true
				this.item = item;
				this.remainderGold = this.item.allowOrderDiscardMoney
				this.pIndex = pIndex;
				this.manualDisCardList()
				this.choosedDisCardList()
			},
			//手动选卡 查询按钮

			manualgetData() {
				if (this.manualMin_gold != '' && this.manualMin_gold != '0' && this.manualMin_gold != 0) {
					if (!isMoney(this.manualMin_gold, "输入的最小金额不合法")) {
						return
					}

				}
				if (this.manualMax_gold != '' && this.manualMax_gold != '0' && this.manualMax_gold != 0) {
					if (!isMoney(this.manualMax_gold, "输入的最大金额不合法")) {
						return
					}
				}
				if (this.manualMax_gold != '' && this.manualMin_gold != '' && Number(this.manualMin_gold) > Number(this
						.manualMax_gold)) {
					// console.log(this.manualMin_gold,this.manualMax_gold)
					this.$message({
						type: 'error',
						message: '最小金额不能大于最大金额'
					})
					return
				}
				if(this.canused == "1"){
					//查可用卡
					this.manualDisCardList();
				}else if(this.canused == "2"){
					//查不可用卡
					this.manualDisCardUnavailableList()
				}
			},
			manualreferData() {
				this.manualMin_gold = ""
				this.manualMax_gold = ""
				this.manualCard_name = ""
				this.manualCard = ""
				this.manualCarder_username = ""
				this.manualpage = 1
				
				if (this.canused == "1") {
					//查可用卡
					this.manualDisCardList();
				} else if (this.canused == "2") {
					//查不可用卡
					this.manualDisCardUnavailableList()
				}
			},
			//查询右边卡列表
			choosedDisCardList() {
				let data = {
					page: this.manualRightpage,
					size: this.manualRightpageSize,
					order_id: this.item.order_id,
					water_no: this.item.water_no,
					card_order_id: this.item.card_order_id
				}
				this.$api.choosedDisCardList(data).then(res => {
					this.manualQueryloading = false;
					if (res.status == 1) {
						this.manualTableDataChoose = res.data.data
						this.manualRighttotal = res.data.total
					}
				}).catch(error => {
					this.manualQueryloading = false;
				})
			},
			//查询左边卡列表
			manualDisCardList() {
				let data = {
					business_no: this.item.business_no,
					carder_username: this.manualCarder_username,
					page: this.manualpage,
					size: this.manualpageSize,
					card: this.manualCard,
					card_name: this.manualCard_name,
					min_gold: this.manualMin_gold,
					max_gold: this.manualMax_gold
				}
				this.manualQueryloading = true;
				this.$api.manualDisCardList(data).then(res => {
					this.manualQueryloading = false;
					if (res.status == 1) {
						res.data.data.forEach(item => {
							item.payAmount = ''
							item.selected = false;
							for (let i = 0; i < this.manualTableDataChoose.length; i++) {
								if (item.id == this.manualTableDataChoose[i].id) {
									item.selected = true;
									item.payAmount = this.manualTableDataChoose[i].payAmount
								}
							}

						})
						this.manualTableData = res.data.data.filter(item => {
							if (!item.selected) {
								return item
							}
						});
						this.manualtotal = res.data.total
					}
				}).catch(error => {
					this.manualQueryloading = false;
				})
			},
			addChange(item) {
				// console.log(val)
				//console.log(item)
				let data = {
					id: item.id,
					type: 1,
					order_id: this.item.order_id,
					water_no: this.item.water_no,
					card_order_id: this.item.card_order_id,
				}
				this.manualloading = true
				this.$api.discardLock(data).then(res => {
					this.manualloading = false
					if (res.status == 1) {
						this.addManualData()
					} else {
						item.selected = false
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.manualloading = false
					item.selected = false
				})
				// let addData = this.manualTableData.filter(item => {
				// 	if (item.selected) {
				// 		return item
				// 	}
				// })
				// if (addData.length > 0) {
				// 	this.canAdd = true
				// } else {
				// 	this.canAdd = false
				// }
			},
			cancleChange(item) {
				let data = {
					id: item.id,
					type: 2,
					order_id: this.item.order_id,
					water_no: this.item.water_no,
					card_order_id: this.item.card_order_id,
				}
				this.manualloading = true
				this.$api.discardLock(data).then(res => {
					this.manualloading = false
					if (res.status == 1) {
						this.cancleManualData()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
						item.selected = false
					}

				}).catch(error => {
					this.manualloading = false
					item.selected = false
				})
				// let cancleData = this.manualTableDataChoose.filter(item => {
				// 	if (item.selected) {
				// 		return item
				// 	}
				// })
				// if (cancleData.length > 0) {
				// 	this.canCancle = true
				// } else {
				// 	this.canCancle = false
				// }
			},
			//添加到右边
			addManualData() {
				//右边
				let manualTableDataChoose = this.manualTableData.filter(item => {
					if (item.selected) {
						return item
					}
				})

				//左边				
				let manualTableData = this.manualTableData.filter(item => {
					if (!item.selected) {
						return item
					}
				});
				//清除右边选中
				manualTableDataChoose.forEach(item => {
					item.selected = false
				})
				this.manualTableData = manualTableData
				this.manualTableDataChoose.push(...manualTableDataChoose)
				this.canAdd = false
			},
			// 删除到左边
			cancleManualData() {
				//右边
				let manualTableDataChoose = this.manualTableDataChoose.filter(item => {
					if (!item.selected) {
						return item
					}
				})

				//左边				
				let manualTableData = this.manualTableDataChoose.filter(item => {
					if (item.selected) {
						return item
					}
				});
				//清除左边选中
				manualTableData.forEach(item => {
					item.selected = false
					item.payAmount = ''
				})
				this.manualTableData.push(...manualTableData)

				this.manualTableDataChoose = manualTableDataChoose
				this.canCancle = false
				this.getRemainderGold()
			},
			manualhandleSizeChange(val) {
				this.manualpageSize = val
				this.manualpage = 1
				if (this.canused == "1") {
					//查可用卡
					this.manualDisCardList();
				} else if (this.canused == "2") {
					//查不可用卡
					this.manualDisCardUnavailableList()
				}
			},
			manualhandleCurrentChange(val) {
				this.manualpage = val
				if (this.canused == "1") {
					//查可用卡
					this.manualDisCardList();
				} else if (this.canused == "2") {
					//查不可用卡
					this.manualDisCardUnavailableList()
				}
			},
			manualRighthandleSizeChange(val) {
				this.manualRightpageSize = val
				this.manualRightpage = 1
				this.choosedDisCardList()
			},
			manualRighthandleCurrentChange(val) {
				this.manualRightpage = val
				this.choosedDisCardList()
			},
			manualclose() {
				this.manualVisible = false;
				this.manualloading = false;
				this.manualQueryloading = false;
				this.canAdd = false;
				this.canCancle = false;
				this.manualTableData = [];
				this.manualTableDataChoose = [];
				this.manualtotal = 0;
				this.manualpage = 1;
				this.manualRighttotal = 0;
				this.manualRightpage = 1;
				this.item = {}
				this.stateVisible = false
			},
			//手动派卡
			manualsuer() {
				let totalAmount = 0;
				let errorString = "";
				let pattern = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
				for (let i = 0; i < this.manualTableDataChoose.length; i++) {
					if (this.manualTableDataChoose[i].payAmount == '') {
						errorString = "请输入代付金额";
						break
					}
					if (!pattern.test(this.manualTableDataChoose[i].payAmount)) {
						errorString = "输入的代付金额不合法";
						break
					}
					// if(Number(this.manualTableDataChoose[i].payAmount)>Number(this.manualTableDataChoose[i].true_quota)){
					// 	errorString=`【${this.manualTableDataChoose[i].true_name}|${this.manualTableDataChoose[i].card}】余额不够，请重新选择银行卡`
					// 	break
					// }
					if(Number(this.manualTableDataChoose[i].payAmount)>Number(this.manualTableDataChoose[i].sys_quota)){
						errorString=`【${this.manualTableDataChoose[i].true_name}|${this.manualTableDataChoose[i].card}】业务余额不够，请重新选择银行卡`
						break
					}
				}
				if (errorString != "") {
					this.$message({
						type: 'error',
						message: errorString
					})
					return
				}
				let dataArr = this.manualTableDataChoose.map(item => {
					totalAmount = Number(item.payAmount) + totalAmount;
					//money  bank_card_id
					return {
						money: item.payAmount,
						bank_card_id: item.id
					}
				})
				// if (totalAmount != this.item.gold) {
				// 	this.$message({
				// 		type: 'error',
				// 		message: '代付金额必须等于订单金额'
				// 	})
				// 	return
				// }
				let data = {
					id: this.item.id,
					data: JSON.stringify(dataArr)
				}
				this.manualloading = true
				if (this.manualtype == 'child') {
					this.manualDisCard(data)
				} else {
					this.manualDisCardMain(data)
				}

				/* this.$api.manualDisCard(data).then(res=>{
					this.manualloading = false
					if(res.status == 1){
						this.manualclose();
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.$api.outInfo({id: this.tableData[this.pIndex].id}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
					}
					else{
						
					}
					
				}).catch(error=>{
					this.manualloading = false
				})
				console.log(data) */
			},
			//子订单手动选卡
			manualDisCard(data) {
				this.$api.manualDisCard(data).then(res => {
					this.manualloading = false
					if (res.status == 1) {
						this.manualclose();
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}

				}).catch(error => {
					this.manualloading = false
				})
			},
			//主订单手动选卡
			manualDisCardMain(data) {
				this.$api.manualDisCardMain(data).then(res => {
					this.manualloading = false
					if (res.status == 1) {
						this.manualclose();
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}

				}).catch(error => {
					this.manualloading = false
				})
			},
			//计算剩余待匹配金额
			getRemainderGold() {
				let inputAllAmount = 0
				this.manualTableDataChoose.forEach(item => {
					inputAllAmount = inputAllAmount + Number(item.payAmount?item.payAmount:"0");
				})
				this.remainderGold = parseFloat((Number(this.item.allowOrderDiscardMoney) - inputAllAmount)).toFixed(2)
			},
			manualDisCardUnavailableList(){
				let data = {
					business_no: this.item.business_no,
					carder_username: this.manualCarder_username,
					page: this.manualpage,
					size: this.manualpageSize,
					card: this.manualCard,
					card_name: this.manualCard_name,
					min_gold: this.manualMin_gold,
					max_gold: this.manualMax_gold
				}
				this.manualQueryloading = true;
				this.$api.manualDisCardUnavailableList(data).then(res => {
					this.manualQueryloading = false;
					if (res.status == 1) {
						res.data.data.forEach(item => {
							item.payAmount = ''
							item.selected = false;
							for (let i = 0; i < this.manualTableDataChoose.length; i++) {
								if (item.id == this.manualTableDataChoose[i].id) {
									item.selected = true;
									item.payAmount = this.manualTableDataChoose[i].payAmount
								}
							}
				
						})
						this.manualTableData = res.data.data.filter(item => {
							if (!item.selected) {
								return item
							}
						});
						this.manualtotal = res.data.total
					}
				}).catch(error => {
					this.manualQueryloading = false;
				})
			},
			//卡账单补单
			billshowDia(item, index,type) {
				this.billbditem = item				
				this.billType = type
				this.billbddialogVisible = true
				if(this.billType == "billadd"){
					//补单
					this.billTitle = "补单"
					if (JSON.stringify(this.replenishmentStateObj) == '{}') {
						this.replenishmentState()
					}
					this.getFailOrder()
				}
				else if(this.billType == "billloss"){
					this.billTitle = "银行卡报损"
				}
			},
			//补单类型
			replenishmentState() {
				this.$api.replenishmentState({}).then(res => {
					if (res.status == 1) {
						this.replenishmentStateObj = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//根据卡查找失败的订单
			getFailOrder(){
				let data = {
					card:this.billbditem.card
				}
				this.$api.getFailOrder(data).then(res=>{
					this.failOrderList=res.data
				})
			},
			//确认补单
			billonSubmit() {
				if(this.billType == "billadd"){
					if (isEmpty(this.failOrder, '请选择订单')) {
						return
					}
					if (isEmpty(this.replenState, '请选择补单类型')) {
						return
					}
					let amount = ''
					for(let i = 0;i<this.failOrderList.length;i++){
						if(this.failOrderList[i].id == this.failOrder){
							amount = this.failOrderList[i].add_gold
							break;
						}
					}
					let data = {
						id: this.failOrder,
						state: this.replenState,
						amount: amount,
						bill_id:this.billbditem.id
					}
					// console.log(data)
					this.billbddialogloading = true;
					this.$api.replenishment(data).then(res => {
						this.billbddialogloading = false;
						if (res.status == 1) {
							this.cardBill()
							this.billbdclose();
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(() => {
						this.billbddialogloading = false;
					})
				}else if(this.billType == "billloss"){
					this.lossmoney = parseFloat(this.billbditem.amount).toFixed(2)
					console.log(this.lossmoney)
					this.lossSubmit('bill',this.billbditem.id)
				}
			},
			//关闭补单弹框
			billbdclose() {
				this.billbditem = {}
				this.billbddialogVisible = false
				this.replenState = ''
				this.failOrder = ''
				this.lossmoney=''
				this.losseType=''
				this.losseNote=''
				this.failOrderList = []
				this.closeOrderdialogVisible = false
				this.closeReason = ''
				this.closeItem = ''
				this.closepIndex = ''
			},
			//报损
			lossSubmit(bill,id){
				if (!isMoney(this.lossmoney, '报损金额只能是数字，最多输入两位小数')) {
					return
				}
				if (isEmpty(this.losseType, '请选择报损类型')) {
					return
				}
				let data = {
					type:this.losseType,
					bank_card_id:this.item.bank_card_id,
					money:this.lossmoney,
					note:this.losseNote
				}
				if(bill == "bill"){
					data.bill_id = id
				}
				this.lossdialogloading = true
				this.$api.addBankCardLoss(data).then(res=>{
					this.lossdialogloading = false
					if(res.status == 1){
						this.orderOutList()
						if(bill == "bill"){
							this.billbdclose()
							this.cardBill()
						}else{							
							this.lossclose()
						}
						this.$message({type:"success",message:res.msg})
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.lossdialogloading = false
				})
				
			},
			getDataIn(type){
				if(type == 'in'){
					this.moneyList()					
				}
				else if(type == 'out'){
					this.outList()
				}
			},
			handleCopy(textCopy){
				
				const contentToCopy = textCopy
				// 创建一个临时的 textAREA 元素
				const textarea = document.createElement('textarea')
				textarea.value = contentToCopy
				document.body.appendChild(textarea)
				textarea.select()
				document.execCommand('copy')
				document.body.removeChild(textarea)
				this.$message({
					type: 'success',
					message: '已复制到剪切板'
				})
			},
			dbCopy(t1,t2,t3){
				const contentToCopy = t1+'\n'+t2+'\n'+t3
				// 创建一个临时的 textAREA 元素
				const textarea = document.createElement('textarea')
				textarea.value = contentToCopy
				document.body.appendChild(textarea)
				textarea.select()
				document.execCommand('copy')
				document.body.removeChild(textarea)
				this.$message({
					type: 'success',
					message: '已复制到剪切板'
				})
			}
		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
				})
			}
		},
	};
</script>

<style scoped="scoped" lang="scss">
	@import '@/style/variables.scss';

	.transferTiete {
		width: calc(50% - 10px);
		text-align: center;
	}

	.transfer {
		height: 70vh;

		// width: 80%;
		.target,
		.source {
			border: solid 1px $tableBorderColor;
			width: calc(50% - 10px);
			// padding: 0 10px;
			height: 100%;

			.searchBox {
				height: 35px;
				width: 100%;
				display: flex;
				align-items: center;
				padding-left: 10px;
			}

			.sourceData {
				height: calc(100% - 35px - 42px);
			}

			.goldBox {
				height: 35px;
				line-height: 35px;
			}

			.targetData {
				height: calc(100% - 35px - 42px);
			}

			.manualPage {
				height: 32px;
			}
		}
	}
</style>
