<template>
	<div>
		<!-- 商家费率报表 -->
		<div class="queryBox mb20">
			<el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">前一天</el-button>
			<el-date-picker size="mini" v-model="date" type="datetimerange" range-separator="至" start-placeholder="开始日期"
			 end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
			<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">后一天</el-button>

			<span class="inlineBlock mr20">
				<span>代理：</span>
				<el-select v-model="agent" placeholder="请选择" size="mini" clearable>
					<el-option v-for="item in agenttableData" :key="item.username" :label="item.name" :value="item.username"></el-option>
				</el-select>
			</span>
			<span class="inlineBlock mr20">
				<span>商家：</span>
				<el-select v-model="trader" placeholder="请选择" size="mini" clearable>
					<el-option v-for="item in tradertableData" :key="item.business_no" :label="item.business_name" :value="item.business_no"></el-option>
				</el-select>
			</span>
			<span class="inlineBlock mr20">
				<span>查询搬砖工：</span>
				<el-input size="mini" placeholder="请输入搬砖工账号" v-model="carder_username" clearable class="mr20" style="width: 200px"></el-input>
			</span>

			<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getData" :disabled="disLoading">搜索</el-button>
		</div>
		<template>
			<el-table :max-height="height" size="mini"  border="" :data="tableData" stripe v-loading="loading" :span-method="objectSpanMethod">
				<!-- <el-table-column prop="plaName" label="收款平台"></el-table-column> -->
				<!-- <el-table-column label="序号" >
					<template slot-scope="scope">
						<span v-if="scope.row.agent_username && scope.row.agent_username.includes('总计')">{{scope.row.agent_username}}</span>
						<span v-else="">{{scope.$index + 1}}</span>
					</template>
				</el-table-column> -->
				<el-table-column label="支付方式" >
					<template slot-scope="scope">
						<span v-if="scope.row.agent_username && scope.row.agent_username.includes('总计')">{{scope.row.agent_username}}</span>
						<span v-else="">{{scope.row.pay_type_name}}</span>
					</template>
				</el-table-column>				
				<el-table-column label="提交" >

					<template slot-scope="scope">
						<div>单数:{{scope.row.add_count}}</div>
						<div>金额:{{scope.row.add_money | tofixed}}</div>
					</template>

					<!-- <el-table-column prop="add_count" label="单数" ></el-table-column>
					<el-table-column label="金额" >
						<template slot-scope="scope">
							<span>{{scope.row.add_money | tofixed}}</span>
						</template>
					</el-table-column> -->


				</el-table-column>
				<el-table-column label="扫码" >
					<template slot-scope="scope">
						<div>单数:{{scope.row.scan_count}}</div>
						<div>金额:{{scope.row.scan_money | tofixed}}</div>
					</template>

					<!-- <el-table-column prop="scan_count" label="单数" ></el-table-column>
					<el-table-column label="金额" >
						<template slot-scope="scope">
							<span>{{scope.row.scan_money | tofixed}}</span>
						</template>
					</el-table-column> -->
				</el-table-column>
				<el-table-column label="成功" >

					<template slot-scope="scope">
						<div>单数:{{scope.row.success_count}}</div>
						<div v-if="scope.row.agent_username && scope.row.agent_username.includes('总计')">金额:{{scope.row.success_money | tofixed}}</div>
						<div v-else="" class="flexX">金额：<el-link type="primary" @click="gotopage(scope.row)">{{scope.row.success_money | tofixed}}</el-link></div>
					</template>




					<!-- <el-table-column prop="success_count" label="单数" ></el-table-column>
					<el-table-column label="金额" >
						<template slot-scope="scope">
							<span v-if="scope.row.agent_username && scope.row.agent_username.includes('总计')">{{scope.row.success_money | tofixed}}</span>
							<el-link v-else="" type="primary" @click="gotopage(scope.row)">{{scope.row.success_money | tofixed}}</el-link>
						</template>
					</el-table-column> -->


				</el-table-column>
				<el-table-column label="支付率" >

					<template slot-scope="scope">
						<div>单数:{{scope.row.pay_count_ratio | tofixed}}%</div>
						<div>金额:{{scope.row.pay_money_ratio | tofixed}}%</div>
					</template>

					<!-- <el-table-column label="单数" >
						<template slot-scope="scope">
							<span>{{scope.row.pay_count_ratio | tofixed}}%</span>
						</template>
					</el-table-column>
					<el-table-column label="金额" >
						<template slot-scope="scope">
							<span>{{scope.row.pay_money_ratio | tofixed}}%</span>
						</template>
					</el-table-column> -->
				</el-table-column>
				<el-table-column label="成功率" >
					<template slot-scope="scope">
						<div>单数:{{scope.row.success_count_ratio | tofixed}}%</div>
						<div>金额:{{scope.row.success_money_ratio | tofixed}}%</div>
					</template>

<!-- 					
					<el-table-column label="单数" >
						<template slot-scope="scope">
							<span>{{scope.row.success_count_ratio | tofixed}} %</span>
						</template>
					</el-table-column>
					<el-table-column label="金额" >
						<template slot-scope="scope">
							<span>{{scope.row.success_money_ratio | tofixed}} %</span>
						</template>
					</el-table-column> -->
				</el-table-column>
				<el-table-column label="支付" >
					<template slot-scope="scope">
						<span>{{scope.row.fee_gold_carder | tofixed}}</span>
					</template>
				</el-table-column>
				<el-table-column label="代理" >
					<template slot-scope="scope">
						<span>{{scope.row.fee_gold_ag | tofixed}}</span>
					</template>
				</el-table-column>
				<el-table-column label="平台" >
					<template slot-scope="scope">
						<span>{{scope.row.platform_gold | tofixed}}</span>
					</template>
				</el-table-column>
				<el-table-column label="商家" >
					<template slot-scope="scope">
						<span>{{scope.row.trader_gold | tofixed}}</span>
					</template>
				</el-table-column>
			</el-table>
		</template>
		<div class="flexX mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				date: '',
				input: '',
				tableData: [],
				pageSizesArr: [100, 200,500,1000],
				pageSize: 100,
				total: 0,
				page: 1,
				agent: '',
				trader: '',
				spanArr: [],
				pos: '',
				carder_username:'',
				height:0,
				traderagentpageSize:500,
				agenttableData:[],
				tradertableData:[],
			}
		},
		created() {
			let date = [this.$util.timestampToTime(new Date().getTime(), true) + ' 00:00:00', this.$util.timestampToTime(new Date().getTime(), true) + ' 23:59:59']
			this.date =this.$route.query.date? this.$route.query.date:date// 获取时间参数
			this.agent = this.$route.query.agent // 获取通道报表传过来的充值类型
			this.trader = this.$route.query.business_no // 获取通道报表传过来的充值类型
			this.carder_username = this.$route.query.carder_username // 获取通道报表传过来的充值类型
			this.agentList()
			this.traderListtList()
			this.reportPayType()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			setDate(day) {
				let data = this.date?this.date[0]:this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate + ' 00:00:00', newDate + ' 23:59:59']
				this.getData()
			},
			//查看列表
			reportPayType() {
				// console.log(this.$util.timestampToTime(Date.parse(new Date(this.date[0]))))
				let data = {
					// startdate: this.date,
					startdate: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0]))) : '',
					enddate: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1]))) : '',
					size: this.pageSize,
					page: this.page,
					// pay_type: this.pay_type,
					agent_username: this.agent,
					business_no: this.trader,
					carder_username:this.carder_username
				}
				// this.loading = true
				this.disLoading = true
				this.$api.reportPayType(data).then(res => {
					this.loading = false
					this.disLoading = false
					if (res.status == 1) {
						this.tableData = res.data.data
						this.total = res.data.total
						this.spanArr= []
						this.pos= ''
						this.getSpanArr(this.tableData)
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
			
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			getData() {
				this.page = 1;
				this.reportPayType()
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.page = 1
				this.pageSize = val
				this.reportPayType()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page = val
				this.reportPayType()
			},
			//跳转页面
			gotopage(item){
				this.$router.push({path:'/merchantIncomeReport',query:{recharge:item.pay_type,date:[this.$util.timestampToTime(Date.parse(new Date(this.date[0]))),this.$util.timestampToTime(Date.parse(new Date(this.date[1])))]}})
			},
			getSpanArr(data) {
				for (var i = 0; i < data.length; i++) {
					if (i === 0) {
						this.spanArr.push(1);
						this.pos = 0
					} else {
						// 判断当前元素与上一个元素是否相同
						if (data[i].pay_type === data[i - 1].pay_type) {
							this.spanArr[this.pos] += 1;
							this.spanArr.push(0);
						} else {
							this.spanArr.push(1);
							this.pos = i;
						}
					}
					console.log(this.spanArr)
				}
			},
			objectSpanMethod({row,column,rowIndex,columnIndex}) {
				if (columnIndex === 0) {
					const _row = this.spanArr[rowIndex];
					const _col = _row > 0 ? 1 : 0;
					console.log(`rowspan:${_row} colspan:${_col}`)
					return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
						rowspan: _row,
						colspan: _col
					}
				}
			},
			//代理列表
			agentList() {
				this.$api.agentList({
					page: 1,
					size: this.traderagentpageSize
				}).then(res => {
					if (res.status === 1) {
						this.agenttableData = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
				})
			},
			//商家列表
			traderListtList() {
				this.$api.traderList({
					page: 1,
					size: this.traderagentpageSize
				}).then(res => {
					if (res.status === 1) {
						this.tradertableData = res.data.data						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
				})
			},
		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
				})
			}
		},
	}
</script>

<style>
</style>
