<template>
	<div>
		<div class="queryBox mb20 flexX flexBetween flexcenter">
			<div>
				<el-cascader v-model="carder_id" class="mr20" :show-all-levels="false" placeholder="搬砖工" size="mini" :options="options"
				 :props="{ checkStrictly: true,expandTrigger:'hover',emitPath: false,value:'id',label:'name' }" clearable @change="agentChang"></el-cascader>
							
				<!-- <template>
					<el-select size="mini" class="mr20" v-model="check_type" clearable placeholder="请选择卡验证状态">
						<el-option key="1" label="未验证" value="1"></el-option>
						<el-option key="2" label="已验证" value="2"></el-option>
					</el-select>
				</template> -->
				<template>
					<el-select size="mini" class="mr20" v-model="is_online" clearable placeholder="请选择在线状态">
						<el-option key="0" label="离线" value="0"></el-option>
						<el-option key="1" label="在线" value="1"></el-option>
						<el-option key="2" label="监控掉线" value="2"></el-option>
					</el-select>
				</template>
				<template>
					<el-select size="mini" class="mr20" v-model="state" clearable placeholder="请选择启停状态">
						<el-option key="0" label="启用" value="0"></el-option>
						<el-option key="1" label="停用" value="1"></el-option>
					</el-select>
				</template>
				<!-- <template>
					<el-select v-model="bank" size="mini" class="mr20" clearable placeholder="请选择银行">
						<el-option v-for="(item,index) in bankListArr" :key="index" :label="item" :value="index"></el-option>
					</el-select>
					
				</template>
				 -->
				<el-input size="mini" placeholder="请输入数字人民币姓名" v-model="card_name" clearable class="mr20" style="width: 200px"></el-input>
				<el-input style="width: 200px;" size="mini" v-model="card" placeholder="请输入数字人民币钱包编号" class="mr20" clearable></el-input>
				<el-input size="mini" placeholder="请输入手机号" v-model="telephone" clearable class="mr20" style="width: 200px"></el-input>
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getData" :disabled="disLoading">搜索</el-button>
				
				<el-button size="mini" type="primary" :disabled="!chooseList.length"  @click="submitAllOrder" >批量设置</el-button>
				<el-button size="mini" type="primary" :disabled="!chooseList.length"  @click="batchDetele" >批量删除</el-button>
			</div>
			<div>
				<span class="inlineBlock mr20 cdanger" v-if="countAllData.total_ratio">总成功率：{{countAllData.total_ratio | tofixed}}%</span>
				<span class="inlineBlock mr20 cdanger" v-if="countAllData.total_amount">总成金额：{{countAllData.total_amount | tofixed}}</span>
				<span class="inlineBlock mr20 cdanger" v-if="countAllData.carder_ratio">搬砖工成功率：{{countAllData.carder_ratio | tofixed}}%</span>
				<span class="inlineBlock mr20 cdanger" v-if="countAllData.carder_amount">搬砖工总金额：{{countAllData.carder_amount | tofixed}}</span>
				<span class="inlineBlock mr20 cdanger" v-if="countAllData.carder_advance_money">搬砖工余额：{{countAllData.carder_advance_money | tofixed}}</span>
			</div>
		</div>
		<template>
			<el-table :max-height="height" :data="tableData" stripe style="width: 100%" v-loading="loading" size="mini" :show-overflow-tooltip="true"  @selection-change="handleSelectionChange">
			 <el-table-column
			 	fixed
			 	type="selection"
			 	:selectable='noSelecTable'
			 	width="55">
			 </el-table-column>
				<el-table-column type="index" label="序号"  width="120px">
					<template slot-scope="scope">
						<div class="textCenter" v-if="scope.$index<tableDataAll.length-2">
							{{scope.$index+1}}
						</div>
						<div class="textCenter" v-else="">
							{{scope.row.all_parent_name}}
						</div>

					</template>
				</el-table-column>
				<el-table-column prop="all_parent_name" label="搬砖工" min-width="200px" >
					<template slot-scope="scope">
						<div class="textCenter" v-if="scope.$index<tableDataAll.length-2">
							{{scope.row.all_parent_name}}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="名称(账号)" width="200px" >
					<template slot-scope="scope">
						<div class="textCenter" v-if="scope.$index<tableDataAll.length-2">
							<el-link type="primary" @click="showInfo(scope.row,scope.$index)">
								<span class="cprimary">{{scope.row.true_name}}</span>
								<br>
								<span class="">{{scope.row.telephone}}</span>
								<br>
								<span class="cdanger">{{scope.row.account}}</span>
							</el-link>

						</div>

					</template>
				</el-table-column>
				<!-- <el-table-column label="手机号" width="100px" >
					<template slot-scope="scope">
						<div class="textCenter" v-if="scope.$index<tableDataAll.length-2">
							<span>{{scope.row.telephone}}</span>
						</div>
					</template>
				</el-table-column> -->

				<!-- <el-table-column label="短信在线状态" >
					<template slot-scope="scope">
						<div v-if="scope.$index<tableDataAll.length-2">
							<span v-if="scope.row.phone_type == 2" :class="{cdanger:scope.row.telephone_is_online=== 2,csuccess:scope.row.telephone_is_online=== 1,cwarning:scope.row.telephone_is_online=== 0}">{{scope.row.telephone_is_online=== 0?'离线':scope.row.telephone_is_online=== 1?'在线':scope.row.telephone_is_online=== 2?'掉线':''}}</span>
						</div>
					</template>
				</el-table-column> -->
				<el-table-column label="代收回调"  width="130px">
					<template slot-scope="scope">
						<div v-if="scope.$index<tableDataAll.length-2">
							<span :class="{csuccess:scope.row.ds_callback_type === 0}">{{scope.row.ds_callback_type=== 0?'监控回调':'手动回调'}}</span>
							<br>
							<!-- 0 短信回调  1 手动回调  2 微信回调 -->
							<el-button class="mt10" @click="setCallbackType(scope.row,scope.$index,'ds')" size="mini">{{scope.row.ds_callback_type=== 0?'设为手动回调':'设为监控回调'}}</el-button>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="在线状态"  width="150px">
					<template slot-scope="scope">
						<div v-if="scope.$index<tableDataAll.length-2">
							<span :class="{cdanger:scope.row.is_online=== 2,csuccess:scope.row.is_online=== 1,cwarning:scope.row.is_online=== 0}">{{scope.row.is_online=== 0?'离线':scope.row.is_online=== 1?'在线':scope.row.is_online=== 2?'监控掉线':''}}</span>
							<br>
							<span class="mt10">{{scope.row.online_time}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="离线" >
					<template slot-scope="scope">
						<div v-if="scope.$index<tableDataAll.length-2">
							<el-button @click="setOnline(scope.row)" size="mini">离线</el-button>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column label="手机类型"  width="130px">
					<template slot-scope="scope">
						<div v-if="scope.$index<tableDataAll.length-2">
							<span :class="{csuccess:scope.row.phone_type=== 2}">{{scope.row.phone_type=== 1?'实体手机':'云手机'}}</span>
							<br>
							<el-button class="mt10" @click="setPhoneType(scope.row,scope.$index)" size="mini">{{scope.row.phone_type=== 1?'设置为云手机':'设置为实体机'}}</el-button>
						</div>
					</template>
				</el-table-column>
 -->
				<el-table-column label="状态" >
					<template slot-scope="scope">
						<div v-if="scope.$index<tableDataAll.length-2">
							<div class="flexX flexJustifyStart">	
								<span class="whiteEllipsisHover">
									<span>启用：</span>
									<span :class="{cdanger:scope.row.state=== 1,csuccess:scope.row.state=== 0}">{{scope.row.state=== 0?'启用':scope.row.state=== 1?'停用':''}}</span>
								</span>
							</div>
							<div class="flexX flexJustifyStart">	
								<span class="whiteEllipsisHover">
									<span>异常：</span>
									<span :class="{cdanger:scope.row.abnormal_state=== 2,csuccess:scope.row.abnormal_state=== 1}">{{scope.row.abnormal_state== 1?'正常':'异常'}}</span>
								</span>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="启用/停用" >
					<template slot-scope="scope">
						<div v-if="scope.$index<tableDataAll.length-2">
							<el-button @click="cardToggle(scope.row,scope.$index)" size="mini">{{scope.row.state=== 1?'启用':scope.row.state=== 0?'停用':''}}</el-button>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column label="异常状态" >
					<template slot-scope="scope">
						<div v-if="scope.$index<tableDataAll.length-2">
							<span :class="{cdanger:scope.row.abnormal_state=== 2,csuccess:scope.row.abnormal_state=== 1}">{{scope.row.abnormal_state== 1?'正常':'异常'}}</span>
						</div>
					</template>
				</el-table-column> -->

				<el-table-column label="每日收款上限" >
					<template slot-scope="scope">
						<div v-if="scope.$index<tableDataAll.length-2">
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">金额:{{scope.row.eve_used_money | tofixed}}</span>
							</div>
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">笔数:{{scope.row.eve_used_num | tofixed}}</span>
							</div>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column label="每日收款上限笔数" >
					<template slot-scope="scope">
						<div v-if="scope.$index<tableDataAll.length-2">
							<span>{{scope.row.eve_used_num | tofixed}}</span>
						</div>
					</template>
				</el-table-column> -->
<!-- 				<el-table-column label="余额留存上限" >
					<template slot-scope="scope">
						<div v-if="scope.$index<tableDataAll.length-2">
							<span>{{scope.row.keep_gold | tofixed}}</span>
						</div>
					</template>
				</el-table-column>
 -->
				<el-table-column label="扫码总笔数" >
					<template slot-scope="scope">
						<div v-if="scope.$index<tableDataAll.length-2">
							<span class="csuccess">{{scope.row.total_num}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="成功" >
					<template slot-scope="scope">
						<div v-if="scope.$index<tableDataAll.length-2">
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">笔数:{{scope.row.success_num}}</span>
							</div>
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">金额:{{scope.row.success_amount | tofixed}}</span>
							</div>
							<div class="flexX flexJustifyStart">				
								<span class="whiteEllipsisHover">成功率:{{scope.row.num_ratio | tofixed}}</span>
							</div>
							<!-- <span class="csuccess">{{scope.row.success_num}}</span> -->
						</div>
					</template>
				</el-table-column>
			<!-- 	<el-table-column label="业务金额" >
					<template slot-scope="scope">
						<span class="csuccess">{{scope.row.sys_quota | tofixed}}</span>
					</template>
				</el-table-column> -->
				<!-- <el-table-column label="真实金额"  width="120px">
					<template slot-scope="scope">
						<span class="csuccess">{{scope.row.true_quota | tofixed}}</span>

						<div v-if="scope.$index<tableDataAll.length-2">
							<br>
							<el-button size="mini" @click="getTruequota(scope.row)">获取余额</el-button>
						</div>
					</template>
				</el-table-column> -->

				<el-table-column label="年累计限额" >
					<template slot-scope="scope">
						<div v-if="scope.$index<tableDataAll.length-2">
							<span class="csuccess">{{scope.row.year_total_money | tofixed}}</span>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column label="成功金额" >
					<template slot-scope="scope">
						<div v-if="scope.$index<tableDataAll.length-2">
							<span class="csuccess">{{scope.row.success_amount | tofixed}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="成功率" >
					<template slot-scope="scope">
						<div v-if="scope.$index<tableDataAll.length-2">
							<span class="csuccess">{{scope.row.num_ratio | tofixed}}%</span>
						</div>
					</template>
				</el-table-column> -->

				<el-table-column label="操作" width="260px" >
					<template slot-scope="scope">
						<div v-if="scope.$index<tableDataAll.length-2">
							<div>
								<span class="mr20">
									<el-button size="mini" @click="setInfo(scope.row,scope.$index)" style="width: 120px;">设置数字人民币</el-button>
								</span>
								<span>
									<el-button size="mini" @click="delet(scope.row,scope.$index)">删除</el-button>
								</span>
							</div>

							<div class="mt10">
								<el-select size="mini" v-model="scope.row.abnormal_state" placeholder="请选择" style="width: 120px;" class="mr20">
									<el-option v-for="item in tagArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
								</el-select>
								<el-button size="mini" @click="setTag(scope.row,scope.$index)">确定</el-button>
							</div>
						</div>
					</template>
				</el-table-column>


			</el-table>
		</template>

		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>

		<el-dialog center class="dialog" top="100px" title="" :visible.sync="dialogVisible" @close="close" width="70%"
		 :close-on-click-modal="false">
			<div class="formBox" v-loading="dialogloading">
				<div>
					<el-tabs type="border-card" @tab-click="getInfoData" v-model="activeName">
						<el-tab-pane label="搬砖工" name='info'>
							<div class="flexX flexAround flexTop">

								<div>
									<el-form label-position="right" label-width="80px">
										<el-form-item label="用户名:">{{infoTotalData.name}}</el-form-item>
										<el-form-item label="账号:">{{infoTotalData.username}}</el-form-item>
										<el-form-item label="Skype:">
											<!-- {{infoTotalData.skype}} -->
											<el-link type="primary" :href="`skype:${infoTotalData.skype}?chat`" οnclick="return skypeCheck();">{{infoTotalData.skype}}</el-link>
										</el-form-item>
										<el-form-item label="手机号:">{{infoTotalData.telephone}}</el-form-item>
										<el-form-item label="上级:">{{infoTotalData.all_parent_name}}</el-form-item>
										<el-form-item label="银行:">{{infoTotalData.bank_name}}</el-form-item>
										<el-form-item label="钱包:">{{infoTotalData.alipay_account}}</el-form-item>
										<el-form-item label="姓名:">{{infoTotalData.alipay_true_name}}</el-form-item>
									</el-form>
								</div>
								<div>
									<el-form label-position="right" label-width="80px">
										<el-form-item label="提现金额:">{{infoTotalData.tx_money | tofixed}}</el-form-item>
										<!-- <el-form-item label="提成:">{{infoTotalData.total_tc | tofixed}}</el-form-item> -->
										<el-form-item label="今日奖金:">{{infoTotalData.today_yj | tofixed}}</el-form-item>
										<el-form-item label="今日提成:">{{infoTotalData.today_tc | tofixed}}</el-form-item>
										<el-form-item label="钱包数量:">{{infoTotalData.number_rmb_count}}</el-form-item>
										<el-form-item label="充值状态:"> <span :class="infoTotalData.pay_state==0?'csuccess':'cdanger'">{{infoTotalData.pay_state==0?'启用':'禁用'}}</span></el-form-item>
										<el-form-item label="代付状态:"> <span :class="infoTotalData.repay_state==0?'csuccess':'cdanger'">{{infoTotalData.repay_state==0?'启用':'禁用'}}</span></el-form-item>
										<el-form-item label="通道:">{{infoTotalData.business_name}}</el-form-item>
									</el-form>
								</div>
							</div>
						</el-tab-pane>
						<!-- <el-tab-pane label="卡列表" name='bankList'>
							<div class="mb20"><span class="mr20">用户名：{{infoTotalData.name}}</span><span>账号：{{infoTotalData.username}}</span></div>
							<el-table size="mini" :data="cardListData" border="" stripe>
								<el-table-column prop="card" label="卡号" width="145px" align="center"></el-table-column>
								<el-table-column prop="bank" label="银行" align="center"></el-table-column>
								<el-table-column prop="true_name" label="姓名" align="center"></el-table-column>
								<el-table-column prop="telephone" label="电话" align="center"></el-table-column>

								<el-table-column label="昨日结余" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.yes_true_quota | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="今日收款" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.money_in | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="今日出款" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.money_out | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="当前余额" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.true_quota | tofixed}}</div>
									</template>
								</el-table-column>
							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="diahandleSizeChange" @current-change="diahandleCurrentChange"
								 :current-page="diapage" :page-sizes="diapageSizesArr" :page-size="diapageSize" layout="total, sizes, prev, pager, next, jumper"
								 :total="diatotal"></el-pagination>
							</div>
						</el-tab-pane> -->
						<el-tab-pane label="卡详情" name='cardinfo'>
							<div class="flexX flexAround flexTop">
								<div>
									<el-form label-position="right" label-width="140px">
										<el-form-item label="充值验证:">
											<span :class="{csuccess:item.pay_check=== 1,cdanger:item.pay_check=== 0}">{{item.pay_check=== 1?'已验证通过':'未验证通过'}}</span>
										</el-form-item>

										<el-form-item label="代付验证:">
											<span :class="{csuccess:item.repay_check=== 1,cdanger:item.repay_check=== 0}">{{item.repay_check=== 1?'已验证通过':'未验证通过'}}</span>
										</el-form-item>

										<el-form-item label="验证状态设置:">
											<el-button @click="setCheckType(item)" size="mini">{{(item.pay_check=== 1 && item.repay_check === 1)?'设置为未验证通过':'设置为已验证通过'}}</el-button>
										</el-form-item>


									</el-form>
								</div>
								<div>
									<el-form label-position="right" label-width="140px">
										<el-form-item label="充值状态:">
											<span :class="{cdanger:item.pay_state== 1,csuccess:item.pay_state== 0}">{{item.pay_state== 0?'启用':'禁用'}}</span>
										</el-form-item>
								
										<el-form-item label="代付状态:">
											<span :class="{cdanger:item.repay_state== 1,csuccess:item.repay_state== 0}">{{item.repay_state== 0?'启用':'禁用'}}</span>
										</el-form-item>
								
										<el-form-item label="充值状态设置:">
											<el-button @click="setPay_state(item)" size="mini">{{item.pay_state== 0?'充值禁用':'充值启用'}}</el-button>
										</el-form-item>
										
										<el-form-item label="代付状态设置:">
											<el-button @click="setRepay_state(item)" size="mini">{{item.repay_state== 0?'代付禁用':'代付启用'}}</el-button>
										</el-form-item>
								
								
									</el-form>
								</div>
								<div>
									<el-form label-position="right" label-width="140px">
										<el-form-item label="每日收款上限金额:">
											<span>{{item.eve_used_money | tofixed}}</span>
										</el-form-item>
										<el-form-item label="每日收款上限笔数:">
											<span>{{item.eve_used_num | tofixed}}</span>
										</el-form-item>
										<el-form-item label="绑卡手机号:">
											<span>{{item.telephone}}</span>
										</el-form-item>
										<el-form-item label="在线操作:">
											<el-button @click="setOnline(item)" size="mini">离线</el-button>

										</el-form-item>
									</el-form>
								</div>
							</div>
						</el-tab-pane>
						
						<el-tab-pane label="钱包进账订单" name='cardNote'>
							<div class="mb20">
								<span class="mr20">用户名：{{infoTotalData.name}}</span><span class="mr20">账号：{{infoTotalData.username}}</span>
								<span>时间：</span>
								<el-date-picker size="mini" v-model="dateIn" type="datetimerange" range-separator="至" start-placeholder="开始日期"
								 end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
								 <el-button size="mini" type="primary" :icon="disLoadingIn?'el-icon-loading':'el-icon-search'" @click="getDataIn('in')" :disabled="disLoadingIn">搜索</el-button>
							</div>
							<el-table size="mini" :data="cardNoteData" border="" stripe max-height="500">
								<el-table-column prop="true_name" label="姓名" align="center">
									<template slot-scope='scope'>
										<div v-if="scope.row.id">{{scope.row.true_name}}</div>
										<div v-if="!scope.row.id">{{scope.row.name}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="card" label="钱包编号" align="center"></el-table-column>
								<el-table-column prop="order_id" label="订单号" align="center"></el-table-column>
						
								<el-table-column label="金额" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.gold | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="action_time" label="时间" align="center"></el-table-column>
						
							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="nodehandleSizeChange"
									@current-change="nodehandleCurrentChange" :current-page="nodepage"
									:page-sizes="nodepageSizesArr" :page-size="nodepageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="nodetotal"></el-pagination>
							</div>
						</el-tab-pane>
						
						<el-tab-pane label="钱包出账订单" name='cardNoteOut'>
							<span>时间：</span>
							<el-date-picker size="mini" v-model="dateOut" type="datetimerange" range-separator="至" start-placeholder="开始日期"
							 end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
							 <el-button size="mini" type="primary" :icon="disLoadingOut?'el-icon-loading':'el-icon-search'" @click="getDataIn('out')" :disabled="disLoadingOut">搜索</el-button>
							<el-table size="mini" :data="cardNoteOutData" border="" stripe max-height="500">
								<el-table-column prop="true_name" label="姓名" align="center">
									<template slot-scope='scope'>
										<div v-if="scope.row.id">{{scope.row.true_name}}</div>
										<div v-if="!scope.row.id">{{scope.row.name}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="card" label="钱包编号" align="center"></el-table-column>
								<el-table-column prop="order_id" label="订单号" align="center"></el-table-column>
								<el-table-column label="金额" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.gold | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="action_time" label="时间" align="center"></el-table-column>
							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="outhandleSizeChange"
									@current-change="outhandleCurrentChange" :current-page="outpage"
									:page-sizes="outpageSizesArr" :page-size="outpageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="outtotal"></el-pagination>
							</div>
						</el-tab-pane>




						<!-- 卡账单 -->
						<el-tab-pane label="卡账单" name='cardBill'>
							<div class="mb20">
								<span class="mr20">用户名：{{infoTotalData.name}}</span>
								<span class="mr20">账号：{{infoTotalData.username}}</span>
								<span class="mr20" v-if="cardBillData.length>0">姓名：{{cardBillData[0].name}}</span>
								<span class="mr20" v-if="cardBillData.length>0">卡号：{{cardBillData[0].card}}</span>
								<span>更新时间：{{bill_update_time}}</span>
							</div>
							<el-table size="mini" :data="cardBillData" border="" stripe max-height="500px">
								<el-table-column type="index" label="序号" align="center" width="50px"></el-table-column>
								<el-table-column prop="water_no" label="系统订单号" align="center" min-width="140px"></el-table-column>
								<el-table-column label="交易状态" align="center" min-width="140px">
									<template slot-scope='scope'>
										<div v-if="scope.row.state_name">{{scope.row.state_name}}</div>
										<div v-if="scope.row.type == 1 && (scope.row.state == 7 || scope.row.water_no == '')">
											<el-link type="primary"@click="billshowDia(scope.row,scope.$index,'billadd')">补单</el-link>
										</div>
										<div v-if="scope.row.type == 0 && scope.row.water_no == '' && scope.row.repay_is_loss == 0">
											<el-link type="danger"@click="billshowDia(scope.row,scope.$index,'billloss')">报损</el-link>
										</div>
										<div v-if="scope.row.type == 0 && scope.row.water_no == '' && scope.row.repay_is_loss == 1">
											已报损
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="trans_date" label="交易时间" align="center" min-width="140px"></el-table-column>
								<el-table-column label="入款记录" align="center">
									<template slot-scope='scope'>
										<div v-if="scope.row.type == 1">{{scope.row.amount | tofixed}}</div>
										<!-- <div v-if="scope.row.type == 0">0</div> -->
									</template>
								</el-table-column>
								<el-table-column label="出款记录" align="center">
									<template slot-scope='scope'>
										<div v-if="scope.row.type == 0">{{scope.row.amount | tofixed}}</div>
										<!-- <div v-if="scope.row.type == 1">0</div> -->
									</template>
								</el-table-column>
								<el-table-column prop="transplace" label="收支摘要" align="center" min-width="140px">
									<template slot-scope='scope'>
										<div :class="scope.row.type=='0'?'cdanger':'csuccess'">{{scope.row.transplace}}</div>
									</template>
								</el-table-column>
								<el-table-column label="账户余额" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.keep_amount | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="pay_card" label="对方账户" align="center" min-width="140px"></el-table-column>
								<el-table-column prop="pay_name" label="对方户名" align="center" width="140px"></el-table-column>
								<el-table-column prop="remark" label="备注" align="center" width="140px"></el-table-column>

							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="billhandleSizeChange" @current-change="billhandleCurrentChange"
								 :current-page="billpage" :page-sizes="billpageSizesArr" :page-size="billpageSize" layout="total, sizes, prev, pager, next, jumper"
								 :total="billtotal"></el-pagination>
							</div>
						</el-tab-pane>


					</el-tabs>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="close">关闭</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog center class="dialog" top="150px" title="" :visible.sync="cardVisible" @close="cardclose"
		 :close-on-click-modal="false">
			<div class="formBox" v-loading="cardloading">
				<el-form label-position="right" label-width="200px">
					<el-form-item label="用户名:">{{item.all_parent_name}}</el-form-item>
					<el-form-item label="姓名:">{{item.true_name}}</el-form-item>
					<el-form-item label="数字人民币账号:">
						<el-input type="text" placeholder="请输入数字人民币钱包编号" v-model="item.account"></el-input>
					</el-form-item>
					<el-form-item label="手机号:">
						<el-input type="text" placeholder="请输入手机号" v-model="item.telephone"></el-input>
					</el-form-item>
					<el-form-item label="权重:">
						<el-input type="text" placeholder="请输入账号收款权重" v-model="item.pay_rate"></el-input>
					</el-form-item>
					<el-form-item label="每日收款上限金额:">
						<el-input type="text" placeholder="请输入每日收款上限金额" v-model="item.eve_used_money"></el-input>
					</el-form-item>
					<el-form-item label="每日收款上限笔数:">
						<el-input type="text" placeholder="请输入每日收款上限笔数" v-model="item.eve_used_num"></el-input>
					</el-form-item>
					<!-- <el-form-item label="验证:">
						<el-select size="mini" class="mr20" v-model="item.card_check" clearable placeholder="请选择验证状态">
							<el-option key="0" label="待验证" value="1"></el-option>
							<el-option key="1" label="验证成功" value="2"></el-option>
						</el-select>
					</el-form-item> -->
					<el-form-item label="启用充值:">
						<el-select size="mini" class="mr20" v-model="item.pay_state" clearable placeholder="请选择充值状态">
							<el-option key="0" label="启用" :value="0"></el-option>
							<el-option key="1" label="禁用" :value="1"></el-option>
						</el-select>
					</el-form-item>
					<!-- <el-form-item label="启用代付:">
						<el-select size="mini" class="mr20" v-model="item.repay_state" clearable placeholder="请选择代付状态">
							<el-option key="0" label="启用" value="0"></el-option>
							<el-option key="1" label="禁用" value="1"></el-option>
						</el-select>
					</el-form-item> -->
					<el-form-item label="单笔最小限额:">
						<el-input type="text" placeholder="请输入单笔最小限额" v-model="item.min_gold"></el-input>
					</el-form-item>
					<el-form-item label="单笔最大限额:">
						<el-input type="text" placeholder="请输入单笔最大限额" v-model="item.max_gold"></el-input>
					</el-form-item>
					<el-form-item label="年累计限额:">
						<el-input type="text" placeholder="请输入年累计限额" v-model="item.year_total_money"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div class="textCenter">
				<el-button type="primary" @click="setInfoCom">确定</el-button>
				<el-button @click="cardclose">取消</el-button>
			</div>
		</el-dialog>
		<el-dialog width="800px" center top="150px" :title="billTitle" :visible.sync="billbddialogVisible" @close="billbdclose"
			 :close-on-click-modal="false">
				<div class="formBoxbd" v-loading="billbddialogloading">
					<div v-if="billType == 'billadd'">
						<el-form label-position="right" label-width="100px">
							<el-form-item label="金额">
								{{billbditem.amount | tofixed}}元
							</el-form-item>
							<el-form-item label="订单编号">
								<!-- {{bditem.order_id}} -->
								<el-select v-model="failOrder" filterable placeholder="请选择订单编号" size="mini" class="mr20" clearable>
									<el-option v-for="(item) in failOrderList" :key="item.id" :label="item.trader_order_id+item.order_id" :value="item.id">
										 <span>{{item.trader_order_id}} | {{item.order_id}} | {{item.business_name}} | {{item.add_gold | tofixed}} | {{item.add_time}}</span>
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="补单类型">
								<el-select v-model="replenState" placeholder="请选择补单类型" size="mini" class="mr20" clearable>
									<el-option v-for="(item,index) in replenishmentStateObj" :key="index" :label="item" :value="index"></el-option>
								</el-select>
								
							</el-form-item>
						</el-form>
					</div>
					<div v-if="billType == 'billloss'">
						<el-form label-position="right" label-width="100px">
							
							<el-form-item label="姓名">
								{{item.true_name}}
							</el-form-item>
							<el-form-item label="银行">
								{{item.bank}}
							</el-form-item>
							<el-form-item label="卡号">
								{{item.card}}
							</el-form-item>
							<el-form-item label="搬砖工">
								{{infoTotalData.name}}
							</el-form-item>
							<el-form-item label="差值">
								<span>{{item.true_quota - item.sys_quota  | tofixed}}</span>
							</el-form-item>
							<el-form-item label="金额">
								{{billbditem.amount | tofixed}}元
							</el-form-item>
							
							<el-form-item label="报损类型">
								<el-select v-model="losseType" filterable placeholder="请选择报损类型" size="mini" class="mr20" clearable>
									<el-option v-for="(item) in losseTypeList" :key="item.id" :label="item.label" :value="item.id"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="备注">
								<el-input size="mini" v-model="losseNote" placeholder="请输入备注" style="width: 200px;"></el-input>
							</el-form-item>
						</el-form>
					</div>
					<div class="textCenter">
						<el-button type="primary" @click="billonSubmit">确定</el-button>
					</div>
				</div>
		</el-dialog>
		
		<el-dialog center class="dialog" top="150px" title="批量设置限额" :visible.sync="piliangVisible" @close="piliangclose" :close-on-click-modal="false">
			<div class="formBox" v-loading="cardloading">
				<el-form label-position="right" label-width="200px">
				<div style="color: red; font-size: 15px;">
					当输入框不填入内容时，即不设置对应额度
				</div>
					<el-form-item label="每日收款上限金额:">
						<el-input type="text" placeholder="请输入每日收款上限金额" v-model="piLiangArr.eve_used_money"></el-input>
					</el-form-item>
					<el-form-item label="每日收款上限笔数:">
						<el-input type="text" placeholder="请输入每日收款上限笔数" v-model="piLiangArr.eve_used_num"></el-input>
					</el-form-item>
					<el-form-item label="单笔最小限额:">
						<el-input type="text" placeholder="请输入单笔最小限额" v-model="piLiangArr.min_gold"></el-input>
					</el-form-item>
					<el-form-item label="单笔最大限额:">
						<el-input type="text" placeholder="请输入单笔最大限额" v-model="piLiangArr.max_gold"></el-input>
					</el-form-item>
					<el-form-item label="年累计限额:">
						<el-input type="text" placeholder="请输入年累计限额" v-model="piLiangArr.year_total_money"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div class="textCenter">
				<el-button type="primary" @click="submitPiliang">确定</el-button>
				<el-button @click="piliangclose">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				carder_id: '',
				card_name: '',
				options: [],
				tableData: [],
				height: 0,
				pageSizesArr: [5000,20, 50, 100, 200],
				pageSize: 5000,
				total: 0,
				page: 1,
				tagArr: [{
					id:1,
					name:'正常'
				},
				{
					id:2,
					name:'异常',
					
				}],
				countAllData: {},
				//前端分页
				tableDataAll:[],
				fontPageSize:10,
				fontLastPage:0,
				fontPage:1,

				diapageSizesArr: [20, 50, 100, 200],
				diapageSize: 20,
				diatotal: 0,
				diapage: 1,
				nodepageSizesArr: [20, 50, 100, 200],
				nodepageSize: 20,
				nodetotal: 0,
				nodepage: 1,
				outpageSizesArr: [20, 50, 100, 200],
				outpageSize: 20,
				outtotal: 0,
				outpage: 1,
				billpageSizesArr: [20, 50, 100, 200],
				billpageSize: 20,
				billtotal: 0,
				billpage: 1,
				dialogVisible: false,
				dialogloading: false,
				item: {},
				infoTotalData: {}, //搬砖工
				cardListData: [], //银行卡列表
				cardNoteData: [], //银行卡记录  流水
				cardNoteOutData: [], //卡出账记录
				cardBillData: [], //卡账单
				bill_update_time: '', //卡账单 最后更新时间
				activeName: 'info',
				cardVisible: false,
				cardloading: false,
				check_type: '',
				is_online: '',
				is_repay_fail: '',
				state:'',
				index:'',
				//卡账单补单
				replenState: '',
				replenishmentStateObj: {}, //补单类型
				billbddialogVisible: false, //补单弹框
				billbditem: {}, //当前补单的数据
				// index: {}, //当前item 索引
				// orderType:"",//订单操作类型  cancle 撤单
				billbddialogloading: false,
				failOrderList:[],
				failOrder:'',
				bank: '',
				bankListArr: [],
				card:'',//银行卡号查询
				//报损
				lossmoney:'',
				losseType:'',
				losseTypeList:[{id:'1',label:'从搬砖工扣除'},{id:'2',label:'从平台扣除'}],
				losseNote:'',
				//卡账单报损
				billType:'',//billadd 卡账单补单   billloss 卡账单报损
				billTitle:'',//账单弹出框title
				telephone:'',//手机号
				dateIn:null,
				dateOut:null,
				disLoadingIn:false,
				disLoadingOut:false,
				// 批量设置
				chooseList: [],
				piliangVisible: false,
				piLiangArr: {
					eve_used_money: '', // 每日收款上限金额
					eve_used_num: '', // 每日收款上限笔数
					min_gold: '', //单笔最小限额
					max_gold: '', // 单笔最大限额
					year_total_money: '', // 年累计限额
				},
			};
		},
		created() {
			if (this.$route.query.is_online) {
				this.is_online = this.$route.query.is_online;
				this.is_repay_fail = this.$route.query.is_repay_fail;

			}
			// if(this.$route.query.is_repay_fail){
			this.is_repay_fail = this.$route.query.is_repay_fail;

			// }
			this.bankList()
			this.countAll()
			this.treeList()
			// this.cardTags()
			this.cardList()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - threeDirectoryBox - 40
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// console.log('sssssssssss', (value * 1).toFixed(2) + '')
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			batchDetele(){
				let ids = this.chooseList.map(item => item.id)
				let data ={
					ids: JSON.stringify(ids),
				}
				this.$confirm(`确认批量删除账号吗?`, `提示`, {
					confirmButtonText: `确认`,
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true;
					this.$api.batchDelete(data).then(res => {
						this.loading = false;
						if (res.status === 1) {
							this.cardList();
							
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false;
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消批量删除`
					});
				});
			},
			piliangclose(){
				this.piliangVisible = false
				this.piLiangArr.eve_used_money = ''
				this.piLiangArr.eve_used_num = ''
				this.piLiangArr.min_gold = ''
				this.piLiangArr.max_gold = ''
				this.piLiangArr.year_total_money = ''
			},
			submitPiliang(){
				let ids = this.chooseList.map(item => item.id)
				let data ={
					ids: JSON.stringify(ids),
					eve_used_money: this.piLiangArr.eve_used_money, // 每日收款上限金额
					eve_used_num: this.piLiangArr.eve_used_num, // 每日收款上限笔数
					min_gold: this.piLiangArr.min_gold, //单笔最小限额
					max_gold: this.piLiangArr.max_gold, // 单笔最大限额
					year_total_money: this.piLiangArr.year_total_money, // 年累计限额
				}
				this.cardloading = true
				this.$api.batchSetInfo(data).then(res=>{
					
					this.cardloading = false
					if (res.status == 1) {
						this.piliangVisible=false
						
						this.cardList()
						this.piliangclose()
						this.$message({
							type: 'success',
							message: res.msg
						})
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					
					// this.paywayTypeList = res.data
				})
			},
			submitAllOrder(){
				if(this.chooseList.length){
					this.piliangVisible=true
				}
			},
			  // 是否禁止选择
			noSelecTable(row, index) {
				// 回显的数据是否可选
				if (row.id) {
					return true;
				} else {
					return false;
				}
			},
			handleSelectionChange(selection, row) {
				this.chooseList = selection
			},
			//设置账户代收，代付类型
			setCallbackType(item,index,type) {
			
				this.index = index
				let data = {
					alipay_id: item.id,
				}
				if(type === 'ds'){
					let ds_type_val = 0
					if(item.ds_callback_type == 0){
						ds_type_val = 1
					}
					if(item.ds_callback_type == 1){
						ds_type_val = 0
					}
					data['ds_callback_type'] = ds_type_val;
				}
				if(type === 'df'){
					data['df_callback_type'] = item.df_callback_type == 1?0:1;
				}
				
				this.loading = true
				this.$api.alipaysetInfo(data).then(res => {
					this.loading = false
					if (res.status == 1) {
						// this.cardList()
						this.cardinfo(item.id)
						this.$message({
							type: "success",
							message: res.msg
						})
						this.cardclose()
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
			
					}
				}).catch(error => {
					this.loading = false
				})
			},
			agentChang(value) {
				// console.log(value)
				this.carder_id = value
				// console.log('this.val', this.carder_id)
			},
			countAll() {
				let data = {
					carder_id: this.carder_id
				}
				this.$api.countAll(data).then(res => {
					if (res.status === 1) {
						this.countAllData = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			treeList() {
				this.$api.treeList({}).then(res => {
					if (res.status === 1) {
						this.options = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//获取银行卡标签
			// cardTags() {
			// 	this.$api.cardTags({}).then(res => {
			// 		if (res.status === 1) {
			// 			this.tagArr = res.data
			// 		} else {
			// 			this.$message({
			// 				type: 'error',
			// 				message: res.msg
			// 			})
			// 		}
			// 	})
			// },
			//查询银行卡列表
			cardList() {
				let data = {
					carder_id: this.carder_id,
					true_name: this.card_name,
					// check_type: this.check_type,
					is_online: this.is_online,
					// is_repay_fail: this.is_repay_fail,
					page: this.page,
					size: this.pageSize,
					state:this.state,
					// account:this.bank,
					account:this.card,
					telephone:this.telephone,
					type:2,
				}
				// this.loading = true
				this.disLoading = true
				
				this.$api.alipayall(data).then(res => {
					this.loading = false;
					this.disLoading = false;
					if (res.status === 1) {
						// this.tableData = res.data.data;
						this.tableData = []
						this.fontPage=1
						this.tableDataAll = res.data.data;
						let len = this.tableDataAll.length
						this.fontLastPage =Math.ceil(len / this.fontPageSize)
						let start = 0;
						let end = 0;
						if(this.fontPage < this.fontLastPage){
							 end = this.fontPage*this.fontPageSize;
						}else{
							end = this.tableDataAll.length-1;
						}
						for(let i = start;i<=end;i++){
							this.tableData.push(this.tableDataAll[i])
						}
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
				
			},
			//搜索按钮
			getData() {
				this.page = 1;
				this.cardList();
				this.countAll()
			},
			//修改标签
			setTag(item,index) {
				let data = {
					alipay_id: item.id,
					state: item.abnormal_state
				}
				this.$confirm(`确认设置${item.account}的标签吗?`, `提示`, {
					confirmButtonText: `确认`,
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.index = index;
					this.loading = true;
					this.$api.setNormalState(data).then(res => {
						this.loading = false;
						if (res.status === 1) {
							// this.cardList();
							this.cardinfo(item.id)
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false;
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消设置标签`
					});
				});
			},
			//离线
			setOnline(item) {
				let data = {
					alipay_id: item.id,
					state: 0,
				}

				this.$confirm(`确认设置${item.account}离线吗?`, `提示`, {
					confirmButtonText: `确认`,
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true;
					this.$api.alipaysetOnline(data).then(res => {
						this.loading = false;
						if (res.status === 1) {
							// this.cardList();
							this.cardinfo(item.id)
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false;
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消设置离线`
					});
				});
			},
			//启用 停用
			cardToggle(item,index) {
				let data = {
					alipay_id: item.id,
					state: item.state == 1 ? 0 : 1,
				}

				this.$confirm(`确认${item.state==1?'启用':'停用'}${item.account}吗?`, `提示`, {
					confirmButtonText: `确认`,
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.index = index
					this.loading = true;
					this.$api.alipayToggle(data).then(res => {
						this.loading = false;
						if (res.status === 1) {
							// this.cardList();
							this.cardinfo(item.id)
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false;
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消${item.state==1?'启用':'停用'}账号`
					});
				});
			},
			//删除
			delet(item, index) {
				let data = {
					id: item.id
				}
				this.$confirm(`确认删除数字人名币账号${item.account}吗?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true;
					this.$api.alipayDelete(data).then(res => {
						this.loading = false;
						if (res.status == 1) {
							this.tableData.splice(index, 1)
							this.cardList()
							this.$message({
								"type": "success",
								message: "删除成功"
							})
						} else {
							this.$message({
								"type": "error",
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false;
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});

			},
			//清零统计
			cleared(item, index) {
				let data = {
					id: item.id
				}
				this.$confirm(`确认清零数字人名币账号${item.card}的连续失败次数吗?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true;
					this.$api.cardcleared(data).then(res => {
						this.loading = false;
						if (res.status == 1) {
							this.$set(this.tableData[index], "fail_count", 0)
							this.$message({
								"type": "success",
								message: "清理成功"
							})
						} else {
							this.$message({
								"type": "error",
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false;
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消清零统计'
					});
				});
			},
			//修改银行卡信息
			setInfo(item,index) {
				this.cardVisible = true
				this.item = item
				this.index = index
			},
			setInfoCom() {
				// if (isEmpty(this.item.card, "卡号不能为空")) {
				// 	return
				// }
				if (isEmpty(this.item.account, "数字人民币账号不能为空")) {
					return
				}
				if (isEmpty(this.item.telephone, "电话号码不能为空")) {
					return
				}
				if (!isMobile(this.item.telephone, "输入的电话号码不合法")) {
					// this.$message({type:"error",message:"输入的电话号码不合法"})					
					return
				}
				if (isEmpty(this.item.eve_used_money, "每日收款上限金额不能为空")) {
					return
				}
				if (!isMoney(this.item.eve_used_money, "每日收款上限金额只能是数字，最多输入两位小数")) {
					return
				}
				if (isEmpty(this.item.eve_used_num, "每日收款上限笔数不能为空")) {
					return
				}
				if (!isPositiveNumber(this.item.eve_used_num, "每日收款上限笔数不能为空")) {
					return
				}
				// if (isEmpty(this.item.card_check, "请选择验证状态")) {
				// 	return
				// }
				if (isEmpty(this.item.pay_state, "请选择充值状态")) {
					return
				}
				// if (isEmpty(this.item.repay_state, "请选择代付状态")) {
				// 	return
				// }
				
				if (isEmpty(this.item.min_gold, "单笔最小限额不能为空")) {
					return
				}
				if (!isMoney(this.item.min_gold, "单笔最小限额只能是数字，最多输入两位小数")) {
					return
				}
				if (isEmpty(this.item.max_gold, "单笔最大限额不能为空")) {
					return
				}
				if (isEmpty(this.item.year_total_money, "最大年累计限额不能为空")) {
					return
				}
				if (!isMoney(this.item.max_gold, "单笔最大限额只能是数字，最多输入两位小数")) {
					return
				}
				if (!isMoney(this.item.year_total_money, "最大年累计只能是数字，最多输入两位小数")) {
					return
				}
				if (isEmpty(this.item.pay_rate, "请输入账号收款权重不能为空")) {
					return
				}
				if (!isMoney(this.item.pay_rate, "请输入账号收款权重只能是数字")) {
					return
				}
				let data = {
					alipay_id: this.item.id,
					account:this.item.account,
					telephone:this.item.telephone,
					eve_used_money:this.item.eve_used_money,
					eve_used_num:this.item.eve_used_num,
					// card_check:this.item.card_check,
					pay_state:this.item.pay_state,
					// repay_state:this.item.repay_state,
					min_gold:this.item.min_gold,
					max_gold:this.item.max_gold,
					year_total_money:this.item.year_total_money,
					pay_rate: this.item.pay_rate,
					// is_get_quota:1,
					
					
					
					
					// card_no: this.item.card,
					// telephone: this.item.telephone,
					// eve_used_money: this.item.eve_used_money,
					// eve_used_num: this.item.eve_used_num,
					// keep_gold: this.item.keep_gold,
					// min_gold: this.item.min_gold,
					// max_gold: this.item.max_gold,
				}
				this.cardloading = true
				this.$api.alipaysetInfo(data).then(res => {
					this.cardloading = false
					if (res.status == 1) {
						// this.cardList()
						this.cardinfo(this.item.id)
						this.$message({
							type: "success",
							message: res.msg
						})
						this.cardclose()
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})

					}
				}).catch(error => {
					this.cardloading = false
				})
			},
			//获取真实余额
			getTruequota(item) {
				let data = {
					alipay_id: item.id,

					// card_no: item.card,
					is_get_quota: 1
				}
				this.loading = true
				this.$api.alipaysetInfo(data).then(res => {
					this.loading = false
					if (res.status == 1) {
						// this.cardList()
						this.$message({
							type: "success",
							message: res.msg
						})
						// this.cardclose()
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})

					}
				}).catch(error => {
					this.loading = false
				})
			},

			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.cardList()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page = val
				this.cardList()
			},

			diahandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.diapageSize = val
				this.diapage = 1
				this.cardListin()
			},
			diahandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.diapage = val
				this.cardListin()
			},

			nodehandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.nodepageSize = val
				this.nodepage = 1
				this.moneyList()
			},
			nodehandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.nodepage = val
				this.moneyList()
			},
			outhandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.outpageSize = val
				this.outpage = 1
				this.outList()
			},
			outhandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.outpage = val
				this.outList()
			},
			billhandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.billpageSize = val
				this.billpage = 1
				this.cardBill()
			},
			billhandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.billpage = val
				this.cardBill()
			},
			showInfo(item,index) {
				// this.$router.push({path:'/orderInfo'})
				this.dialogVisible = true;
				this.item = item
				this.index = index
				this.infoTotal(item)
			},
			getInfoData(val) {
				if (val.name == 'info') {
					if (JSON.stringify(this.infoTotalData) == '{}') {
						this.infoTotal()
					}
				}
				if (val.name == 'cardinfo') {
					// if (JSON.stringify(this.infoTotalData) == '{}') {
					// 	this.infoTotal()
					// }
				}
				if (val.name == 'bankList') {
					if (this.cardListData.length == 0) {
						this.cardListin()
					}
				}
				if (val.name == 'cardNote') {
					if (this.cardNoteData.length == 0) {
						this.moneyList()
					}
				}
				if (val.name == 'cardNoteOut') {
					if (this.cardNoteOutData.length == 0) {
						this.outList()
					}
				}
				if (val.name == 'cardBill') {
					if (this.cardBillData.length == 0) {
						this.cardBill()
					}
				}
			},
			infoTotal(item) {
				let data = {
					id: item.id,
					type: 3
				}
				// this.dialogloading = true
				this.$api.infoTotal(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.infoTotalData = res.data
					} else {
						this.$message({
							"type": "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			cardListin() {
				let data = {
					carder_id: this.infoTotalData.id,
					size: this.diapageSize,
					page: this.diapage,
					// type:2
				}

				// this.dialogloading = true
				this.$api.cardList(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.cardListData = res.data.data
						this.diatotal = res.data.total
					} else {
						this.$message({
							"type": "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			cardclose() {
				this.cardVisible = false
				this.item = {}
			},
			close() {
				this.dialogVisible = false
				this.item = {}
				this.infoTotalData = {} //搬砖工
				this.cardListData = [] //银行卡列表
				this.cardNoteData = [] //银行卡记录  流水
				this.cardNoteOutData = []
				this.cardBillData = []
				this.activeName = 'info'
				this.diapage = 1
				this.nodepage = 1
				this.outpage = 1
				this.billpage = 1
				this.diatotal = 0
				this.nodetotal = 0
				this.dateIn=null
				this.dateOut=null
				this.disLoadingIn=false
				this.disLoadingOut=false
				this.outtotal = 0
				this.billtotal = 0
			},
			moneyList() {
				let data = {
					page: this.nodepage,
					size: this.nodepageSize,
					id: this.item.id,
					startdate:this.dateIn?this.$util.timestampToTime(Date.parse(new Date(this.dateIn[0]))):"",
					endate:this.dateIn?this.$util.timestampToTime(Date.parse(new Date(this.dateIn[1]))):""
				}
				this.disLoadingIn = true
				// this.dialogloading = true
				this.$api.moneyList(data).then(res => {
					this.dialogloading = false
					this.disLoadingIn = false
					if (res.status == 1) {
						this.cardNoteData = res.data.data
						this.nodetotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.disLoadingIn = false
					this.dialogloading = false
				})
			},
			outList() {
				let data = {
					page: this.outpage,
					size: this.outpageSize,
					id: this.item.id,
					startdate:this.dateOut?this.$util.timestampToTime(Date.parse(new Date(this.dateOut[0]))):"",
					endate:this.dateOut?this.$util.timestampToTime(Date.parse(new Date(this.dateOut[1]))):""
				}
				this.disLoadingOut = true
				// this.dialogloading = true
				this.$api.outList(data).then(res => {
					this.dialogloading = false
					this.disLoadingOut = false
					if (res.status == 1) {
						this.cardNoteOutData = res.data.data
						this.outtotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
					this.disLoadingOut = false
				})
			},
			//卡账单
			cardBill() {
				let data = {
					page: this.billpage,
					size: this.billpageSize,
					id: this.item.id,
				}
				// this.dialogloading = true
				this.$api.cardBill(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.cardBillData = res.data.data
						this.billtotal = res.data.total
						this.bill_update_time = res.data.bill_update_time
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			//设置手机状态
			setPhoneType(item,index) {
				this.$confirm(`确定改变手机类型吗?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.index = index
					let data = {
						alipay_id: item.id,
						phone_type: item.phone_type == 1 ? 2 : 1
					}
					this.loading = true
					this.$api.alipaysetInfo(data).then(res => {
						this.loading = false
						if (res.status == 1) {
							// this.cardList()
							this.cardinfo(item.id)
							this.$message({
								type: "success",
								message: res.msg
							})
							this.cardclose()
						} else {
							this.$message({
								type: "error",
								message: res.msg
							})

						}
					}).catch(error => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消改变手机类型'
					});
				});
			},
			//设置验证状态
			setCheckType(item) {
				let card_check = (item.pay_check === 1 && item.repay_check === 1) ? 1 : 2
				this.$confirm(`确定改变验证状态?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						alipay_id: item.id,
						card_check: card_check
					}
					this.loading = true
					this.$api.alipaysetInfo(data).then(res => {
						this.loading = false
						if (res.status == 1) {
							// this.cardList()
							this.cardinfo(item.id)
							this.$message({
								type: "success",
								message: res.msg
							})
							if (card_check == 1) {
								this.item.pay_check = 0
								this.item.repay_check = 0
							}
							if (card_check == 2) {
								this.item.pay_check = 1
								this.item.repay_check = 1
							}
							console.log('card_check', card_check)
							// this.item.pay_check = card_check
							// this.item.repay_check = card_check
							// this.cardclose()
						} else {
							this.$message({
								type: "error",
								message: res.msg
							})

						}
					}).catch(error => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消改变手机类型'
					});
				});
			},
			//设置充值状态
			setPay_state(item) {
				let pay_state = item.pay_state == 1 ? 0 : 1
				this.$confirm(`确定改变充值状态?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						alipay_id: item.id,
						pay_state: pay_state
					}
					this.loading = true
					this.$api.alipaysetInfo(data).then(res => {
						this.loading = false
						if (res.status == 1) {
							// this.cardList()
							this.cardinfo(item.id)
							this.$message({
								type: "success",
								message: res.msg
							})
							if (pay_state == 1) {
								this.item.pay_state = 1
							}
							if (pay_state == 0) {
								this.item.pay_state = 0
							}
							// console.log('pay_state',pay_state)
						} else {
							this.$message({
								type: "error",
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消改变充值状态'
					});
				});
			},
			//设置代付状态
			setRepay_state(item) {
				let repay_state = item.repay_state == 1 ? 0 : 1
				this.$confirm(`确定改变充值状态?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						alipay_id: item.id,
						repay_state: repay_state
					}
					this.loading = true
					this.$api.alipaysetInfo(data).then(res => {
						this.loading = false
						if (res.status == 1) {
							// this.cardList()							
							this.cardinfo(item.id)
							this.$message({
								type: "success",
								message: res.msg
							})
							if (repay_state == 1) {
								this.item.repay_state = 1
							}
							if (repay_state == 0) {
								this.item.repay_state = 0
							}
							// console.log('pay_state',pay_state)
						} else {
							this.$message({
								type: "error",
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消改变充值状态'
					});
				});
			},
			//获取单条数据
			cardinfo(card_id){
				let data = {
					alipay_id:card_id
				}
				this.$api.alipayinfo(data).then(res=>{
					console.log(this.index)
					if(res.status == 1){
						// this.tableData[this.index] = res.data
						this.tableData.splice(this.index, 1, res.data)
						// this.$set(this.tableData,this.tableData[this.index],res.data)
					}else{
						this.$message({type:"error",message:res.msg})
					}
					
				})
			},
			//卡账单补单
			billshowDia(item, index,type) {
				this.billbditem = item
				this.billType = type
				this.billbddialogVisible = true
				if(this.billType == "billadd"){
					//补单
					this.billTitle = "补单"
					if (JSON.stringify(this.replenishmentStateObj) == '{}') {
						this.replenishmentState()
					}
					this.getFailOrder()
				}
				else if(this.billType == "billloss"){
					this.billTitle = "银行卡报损"
				}
			
			},
			//补单类型
			replenishmentState() {
				this.$api.replenishmentState({}).then(res => {
					if (res.status == 1) {
						this.replenishmentStateObj = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//根据卡查找失败的订单
			getFailOrder(){
				let data = {
					card:this.billbditem.card
				}
				this.$api.getFailOrder(data).then(res=>{
					this.failOrderList=res.data
				})
			},
			//确认补单
			billonSubmit() {
				if(this.billType == "billadd"){
					if (isEmpty(this.failOrder, '请选择订单')) {
						return
					}
					if (isEmpty(this.replenState, '请选择补单类型')) {
						return
					}
					let amount = ''
					for(let i = 0;i<this.failOrderList.length;i++){
						if(this.failOrderList[i].id == this.failOrder){
							amount = this.failOrderList[i].add_gold
							break;
						}
					}
					let data = {
						id: this.failOrder,
						state: this.replenState,
						amount: amount,
						bill_id:this.billbditem.id
					}
					// console.log(data)
					this.billbddialogloading = true;
					this.$api.replenishment(data).then(res => {
						this.billbddialogloading = false;
						if (res.status == 1) {
							this.cardBill()
							this.billbdclose();
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(() => {
						this.billbddialogloading = false;
					})
				}
				else if(this.billType == "billloss"){
					this.lossmoney = parseFloat(this.billbditem.amount).toFixed(2)
					console.log(this.lossmoney)
					this.lossSubmit('bill',this.billbditem.id)
				}
			},
			//关闭补单弹框
			billbdclose() {
				this.billbditem = {}
				this.billbddialogVisible = false
				this.replenState = ''
				this.failOrder = ''
				this.lossmoney=''
				this.losseType=''
				this.losseNote=''
				this.failOrderList = []
			},
			bankList() {
				this.$api.bankList({}).then(res => {
					if (res.status == 1) {
						this.bankListArr = res.data;
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//报损
			lossSubmit(bill,id){
				if (!isMoney(this.lossmoney, '报损金额只能是数字，最多输入两位小数')) {
					return
				}
				if (isEmpty(this.losseType, '请选择报损类型')) {
					return
				}
				let data = {
					type:this.losseType,
					bank_card_id:this.item.id,
					money:this.lossmoney,
					note:this.losseNote
				}
				if(bill == "bill"){
					data.bill_id = id
				}
				this.lossdialogloading = true
				this.$api.addBankCardLoss(data).then(res=>{
					this.lossdialogloading = false
					if(res.status == 1){
						this.cardList()
						if(bill == "bill"){
							this.billbdclose()
							this.cardBill()
						}else{							
							this.lossclose()
						}
						this.$message({type:"success",message:res.msg})
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.lossdialogloading = false
				})
				
			},
			getDataIn(type){
				if(type == 'in'){
					this.moneyList()					
				}
				else if(type == 'out'){
					this.outList()
				}
			},
		},
		watch: {
			tableData:function(){//materielId为需要监听的data
				let that = this;
			    this.$nextTick(function(){
					setTimeout(function(){					
					if(that.fontLastPage > that.fontPage){
						that.fontPage = that.fontPage +1;
						let start = (that.fontPage-1)*that.fontPageSize+1;
						let end = 0;
						if(that.fontPage < that.fontLastPage){
							 end = that.fontPage*that.fontPageSize;
						}
						else{
							end = that.tableDataAll.length-1;
						}
						for(let i = start;i<=end;i++){
							that.tableData.push(that.tableDataAll[i])
						}
					}
					},10)
			    });
			},
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - threeDirectoryBox - 40
				})
			}
		},

	};
</script>

<style>
</style>
