<template>
	<div>
		<!-- 后台报表 -->
		<div class="queryBox mb20">
			<el-button size="mini" type="primary" icon="el-icon-plu" @click="addAccount('new')">新 增</el-button>
		</div>
		<template>
			<el-table :max-height="height" :data="tableData" stripe style="width: 100%" v-loading="loading" size="mini">
				<el-table-column label="序号" width="100px" >
					<template slot-scope="scope">
						<span v-if="scope.row.agent.includes('总计')">{{scope.row.agent}}</span>
						<span v-else="">{{scope.$index + 1}}</span>
					</template>
				</el-table-column>
				<el-table-column label="上级账号|备注" >
					<template slot-scope="scope">
						<span v-if="scope.row.agent.includes('总计')"></span>
						<span v-else>{{scope.row.agent}} / {{scope.row.agent_name}}</span>
					</template>
				</el-table-column>
				<el-table-column label="商家名" >
					<template slot-scope="scope">
						<span v-if="scope.row.agent.includes('总计')"></span>
						<span v-else>{{scope.row.business_name}} </span>
					</template>
				</el-table-column>
				<el-table-column label="商家账号" >
					<template slot-scope="scope">
						<span v-if="scope.row.agent.includes('总计')"></span>
						<span v-else>{{scope.row.username}} </span>
					</template>
				</el-table-column>
				<el-table-column label="商家号" >
					<template slot-scope="scope">
						<span v-if="scope.row.agent.includes('总计')"></span>
						<span v-else>{{scope.row.business_no}} </span>
					</template>
				</el-table-column>
				<el-table-column prop="money" label="余额" ></el-table-column>
				<el-table-column prop="money_uncertain" label="冻结余额" ></el-table-column>
				<el-table-column label="状态" >
					<template slot-scope="scope">
						<span v-if="scope.row.agent.includes('总计')"></span>
						<span v-else>{{scope.row.status===0?'正常':'冻结'}} </span>
					</template>
				</el-table-column>
				<el-table-column label="备注" >
					<template slot-scope="scope">
						<span v-if="scope.row.agent.includes('总计')"></span>
						<span v-else>{{scope.row.name}} </span>
					</template>
				</el-table-column>
				<el-table-column label="功能" >
					<template slot-scope="scope">
						<span v-if="scope.row.agent.includes('总计')"></span>
						<div v-else>
							<el-link type="primary" @click="openEditAll(scope.row,scope.$index)" class="mr20">编辑</el-link>
							<el-link type="primary" @click="showLog(scope.row)" class="mr20">日志</el-link>
							<el-link type="primary" @click="traderToggle(scope.row)" class="mr20">{{scope.row.status===0?'冻结':'启用'}}</el-link>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</template>
		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>
		<!-- 新增 -->
		<el-dialog center width="55%" class="dialog" top="100px" :title="title" :visible.sync="dialogVisible" @close="close"
		 :close-on-click-modal="false">
			<div class="formBox" v-loading="dialogloading">
				<div>
					<div v-if="titleName" class="mb20">{{titleName}}</div>
					<el-form :label-position="labelPosition" label-width="100px" :model="form" v-if="type === 'new'">
						<el-form-item label="代理名称" v-if="type === 'new' || type === 'edt'">
							<el-select :disabled="type === 'edt'" v-model="form.agent" placeholder="请选择代理名称">
								<el-option v-for="item in agentNameArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="名称" v-if="type === 'new' || type === 'edt' || type === 'payPassword'">
							<el-input autocomplete="new-password" :disabled="type === 'payPassword'" v-model="form.business_name"
							 placeholder="请输入名称"></el-input>
						</el-form-item>
						<el-form-item label="登录账号" v-if="type === 'new' || type === 'edt' || type === 'payPassword'">
							<el-input :disabled="type === 'payPassword'||type === 'edt'" v-model="form.username" placeholder="请输入登录账号"></el-input>
						</el-form-item>
						
						<el-form-item label="登录密码" v-if="type === 'new'">
							<el-input autocomplete="new-password" type="password" v-model="form.password" placeholder="请输入登录密码"></el-input>
						</el-form-item>
						<el-form-item label="确认登录密码" v-if="type === 'new'">
							<el-input autocomplete="new-password" type="password" v-model="passwordSure" placeholder="请再次输入登录密码"></el-input>
						</el-form-item>
						<el-form-item label="商家备注" v-if="type === 'new' || type === 'edt'">
							<el-input v-model="form.name" placeholder="请输入备注"></el-input>
						</el-form-item>
						<el-form-item label="账号状态" v-if="type === 'edt'">
							<el-switch v-model="status" class="mr20"></el-switch>
							<span>{{status?'正常':'冻结'}}</span>
						</el-form-item>
						<el-form-item label="支付状态" v-if="type === 'edt'">
							<el-switch v-model="status_order" class="mr20"></el-switch>
							<span>{{status_order?'正常':'停用'}}</span>
						</el-form-item>
						<el-form-item label="代付状态" v-if="type === 'edt'">
							<el-switch v-model="status_pay" class="mr20"></el-switch>
							<span>{{status_pay?'正常':'冻结'}}</span>
						</el-form-item>
						<!-- <el-form-item label="代付接口状态" v-if="type === 'edt'">
							<el-switch v-model="status_pay_api" class="mr20"></el-switch>
							<span>{{status_pay_api?'正常':'停用'}}</span>
						</el-form-item> -->
						<el-form-item label="订单金额状态" v-if="type === 'edt'">
							<el-switch v-model="status_gold_limit" class="mr20"></el-switch>
							<span>{{status_gold_limit?'正常':'停用'}}</span>
						</el-form-item>
						<el-form-item label="浮动金额" v-if="type === 'edt'">
							<el-switch v-model="float_money" class="mr20"></el-switch>
							<span>{{float_money?'开启':'停用'}}</span>
						</el-form-item>
						
						<!-- 修改密码 -->
						<el-form-item label="代付密码" v-if="type === 'payPassword'">
							<el-input type="password" v-model="form.password" placeholder="请输入代付密码"></el-input>
						</el-form-item>
						<el-form-item label="确认密码" v-if="type === 'payPassword'">
							<el-input type="password" v-model="passwordSure" placeholder="请再次输入密码"></el-input>
						</el-form-item>
					</el-form>
					
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmit">{{submitText}}</el-button>
				</div>
			</div>
		</el-dialog>
	<!-- 日志 -->
		<el-dialog class="dialog" top="100px" center title="日志" :visible.sync="logdialog" @close="logclose" :close-on-click-modal="false" width="70%">
			<el-tabs type="border-card" @tab-click="getlogData" v-model="logName">
				<el-tab-pane label="冻结|解冻" name='recharge' :disabled="logLoding">
					<el-table size="mini" :data="rechargeLog" border="" stripe max-height="500px">
						<el-table-column prop="name" label="操作类型" align="center">
							<template slot-scope='scope'>
								<div :class="{csuccess:scope.row.name=='解冻'||scope.row.name=='充值冻结余额',cdanger:scope.row.name!='解冻' && scope.row.name!='充值冻结余额'}">{{scope.row.name}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作前金额" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.old_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作金额" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作后金额" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.new_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="auditor" label="交易人" align="center"></el-table-column>
						<el-table-column prop="time" label="交易时间" align="center"></el-table-column>
						
					</el-table>
					<div class="flexX  mt20">
						<el-pagination :hide-on-single-page="true" @size-change="reLoghandleSizeChange" @current-change="reLoghandleCurrentChange"
						 :current-page="reLogpage" :page-sizes="reLogpageSizesArr" :page-size="reLogpageSize" layout="total, sizes, prev, pager, next, jumper"
						 :total="reLogtotal"></el-pagination>
					</div>
				</el-tab-pane>
				<el-tab-pane label="限额" name='deduction' :disabled="logLoding">
					<el-table size="mini" :data="deductionLog" border="" stripe max-height="500px">
						<el-table-column prop="pay_type_name" label="支付方式" align="center"></el-table-column>						
						<el-table-column label="操作前最大限额" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.old_max_gold | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作前最小限额" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.old_min_gold | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作后最大限额" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.new_max_gold | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作后最小限额" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.new_min_gold | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="auditor" label="操作者" align="center"></el-table-column>
						<el-table-column prop="time" label="操作时间" align="center"></el-table-column>
					</el-table>
					<div class="flexX mt20">
						<el-pagination :hide-on-single-page="true" @size-change="dedLoghandleSizeChange" @current-change="dedLoghandleCurrentChange"
						 :current-page="dedLogpage" :page-sizes="dedLogpageSizesArr" :page-size="dedLogpageSize" layout="total, sizes, prev, pager, next, jumper"
						 :total="dedLogtotal"></el-pagination>
					</div>
				</el-tab-pane>
				<el-tab-pane label="手续费" name='fee' :disabled="logLoding">
					<el-table size="mini" :data="feeLog" border="" stripe max-height="500px">
						<!-- <el-table-column prop="card" label="卡号" width="145px" align="center"></el-table-column> -->
						<el-table-column label="支付类型" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.pay_type_name}}</div>
							</template>
						</el-table-column>
						<el-table-column label="费率" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.fee}}</div>
							</template>
						</el-table-column>
						<el-table-column label="旧费率" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.old_fee}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="auditor" label="操作者" align="center"></el-table-column>
						<el-table-column prop="time" label="操作时间" align="center"></el-table-column>
					</el-table>
					<div class="flexX  mt20">
						<el-pagination :hide-on-single-page="true" @size-change="feeLoghandleSizeChange" @current-change="feeLoghandleCurrentChange"
						 :current-page="feeLogpage" :page-sizes="feeLogpageSizesArr" :page-size="feeLogpageSize" layout="total, sizes, prev, pager, next, jumper"
						 :total="feeLogtotal"></el-pagination>
					</div>
				</el-tab-pane>
				<el-tab-pane label="匹配模式" name='match' :disabled="logLoding">
					<el-table size="mini" :data="matchLog" border="" stripe max-height="500px">
						<el-table-column label="原匹配类型" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.old_type}}</div>
							</template>
						</el-table-column>
						<el-table-column label="匹配类型" align="center">
							<template slot-scope='scope'>
								<div>{{scope.row.new_type}}</div>
							</template>
						</el-table-column>
						
						<el-table-column prop="auditor" label="操作者" align="center"></el-table-column>
						<el-table-column prop="time" label="操作时间" align="center"></el-table-column>
					</el-table>
					<div class="flexX  mt20">
						<el-pagination :hide-on-single-page="true" @size-change="matchLoghandleSizeChange" @current-change="matchLoghandleCurrentChange"
						 :current-page="matchLogpage" :page-sizes="matchLogpageSizesArr" :page-size="matchLogpageSize" layout="total, sizes, prev, pager, next, jumper"
						 :total="matchLogtotal"></el-pagination>
					</div>
				</el-tab-pane>
			</el-tabs>
			
		</el-dialog>
	
	<!-- 全部编辑弹窗 -->
	<el-dialog class="dialog" top="100px" center :visible.sync="editAllVisible" @close="closeEditAll" :close-on-click-modal="false" width="60%">
		
		<el-tabs type="border-card" @tab-click="changeEdit" v-model="editAllName" v-loading="dialogloading">
			<el-tab-pane label="手续费" name='feeTab' :disabled="dialogloading">
				<div v-if="titleName" class="mb20">{{titleName}}</div>
				<el-form v-if="type === 'fee'" :label-position="labelPosition" label-width="80px" class="mb20">
					<el-table :data="feeData" stripe size="mini" border max-height="500px">
						<el-table-column prop="name" label="通道名称" ></el-table-column>
						<el-table-column label="手续费率(‰)" >
							<template slot-scope="scope">
								<el-input style="width: 80px;" v-model="scope.row.fee" placeholder="请输入手续费" class="mr20"></el-input>
								<span>最小值:{{scope.row.agent_fee}}</span>
								<!-- <el-link type="primary" class="mr20"></el-link> -->
							</template>
						</el-table-column>
						<el-table-column label="单笔手续费用" >
							<template slot-scope="scope">
								<el-input style="width: 80px;" v-model="scope.row.fee_num" placeholder="请输入手续费" class="mr20"></el-input>
								<span>最小值:{{scope.row.agent_fee_num}}</span>
								<!-- <el-link type="primary" class="mr20"></el-link> -->
							</template>
						</el-table-column>
					</el-table>
					<el-pagination :hide-on-single-page="true" @size-change="feehandleSizeChange" @current-change="feehandleCurrentChange"
						:current-page="feepage" :page-sizes="feepageSizesArr" :page-size="feepageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="feetotal"></el-pagination>
				</el-form>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmitFee">确 定</el-button>
				</div>
			</el-tab-pane>
			<el-tab-pane label="冻结余额" name='freezeTab' :disabled="dialogloading">
				<div v-if="titleName" class="mb20">{{titleName}}</div>
				<el-form :label-position="labelPosition" label-width="80px" class="mb20">
					<span class="mr20">当前余额：{{money}}</span>
					<span class="mr20">当前冻结余额：{{money_uncertain}}</span>
					<el-form-item class="mt20" label="冻结余额">
						<el-input v-model="amount" placeholder="请输入余额"></el-input>
					</el-form-item>
				</el-form>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmitFreeze('OUT')">确 定</el-button>
				</div>
			</el-tab-pane>
			<el-tab-pane label="解冻余额" name='unFreezeTab' :disabled="dialogloading">
				<div v-if="titleName" class="mb20">{{titleName}}</div>
				<el-form :label-position="labelPosition" label-width="80px" class="mb20">
					<span class="mr20">当前余额：{{money}}</span>
					<span class="mr20">当前冻结余额：{{money_uncertain}}</span>
					<el-form-item class="mt20" label="解冻余额">
						<el-input v-model="amount" placeholder="请输入余额"></el-input>
					</el-form-item>
				</el-form>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmitFreeze('IN')">确 定</el-button>
				</div>
			</el-tab-pane>
			<el-tab-pane label="增减冻结余额" name='inOutUnMoneyTab' :disabled="dialogloading">					
				<div v-if="titleName" class="mb20">{{titleName}}</div>
				<el-form :label-position="labelPosition" label-width="80px" class="mb20">
					<span class="mr20">当前冻结余额：{{money_uncertain}}</span>
					<el-form-item class="mt20" label="增减方式">
						<el-radio v-model="unmoneyRadio" label="IN">增加</el-radio>
						<el-radio v-model="unmoneyRadio" label="OUT">扣除</el-radio>
					</el-form-item>
					<el-form-item class="mt20" label="金额">
						<el-input v-model="unmoneyAmount" placeholder="请输入金额"></el-input>
					</el-form-item>
				</el-form>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmitInOutUnMoney('IN')">确 定</el-button>
				</div>
			</el-tab-pane>
			
			<el-tab-pane label="编辑商家" name='edtTab' :disabled="dialogloading">					
				<!-- <div v-if="titleName" class="mb20">{{titleName}}</div> -->
				<el-form :label-position="labelPosition" label-width="100px" :model="form">
					<el-form-item label="代理名称">
						<el-select :disabled="true" v-model="form.agent" placeholder="请选择代理名称">
							<el-option v-for="item in agentNameArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="名称" >
						<el-input autocomplete="new-password" v-model="form.business_name" placeholder="请输入名称"></el-input>
					</el-form-item>
					<el-form-item label="登录账号">
						<el-input :disabled="true" v-model="form.username" placeholder="请输入登录账号"></el-input>
					</el-form-item>
					<el-form-item label="商家备注">
						<el-input v-model="form.name" placeholder="请输入备注"></el-input>
					</el-form-item>
					<el-form-item label="账号状态">
						<el-switch v-model="status" class="mr20"></el-switch>
						<span>{{status?'正常':'冻结'}}</span>
					</el-form-item>
					<el-form-item label="支付状态">
						<el-switch v-model="status_order" class="mr20"></el-switch>
						<span>{{status_order?'正常':'停用'}}</span>
					</el-form-item>
					<el-form-item label="代付状态">
						<el-switch v-model="status_pay" class="mr20"></el-switch>
						<span>{{status_pay?'正常':'冻结'}}</span>
					</el-form-item>
					
					<el-form-item label="订单金额状态">
						<el-switch v-model="status_gold_limit" class="mr20"></el-switch>
						<span>{{status_gold_limit?'正常':'停用'}}</span>
					</el-form-item>
					<el-form-item label="浮动金额">
						<el-switch v-model="float_money" class="mr20"></el-switch>
						<span>{{float_money?'开启':'停用'}}</span>
					</el-form-item>						
					
				</el-form>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmitEdit()">确 定</el-button>
				</div>
			</el-tab-pane>
			<el-tab-pane label="匹配模式" name='matchTab' :disabled="dialogloading">
				<div v-if="titleName" class="mb20">{{titleName}}</div>
				<el-form :label-position="labelPosition" label-width="100px" class="mb20">
					<div class="cdanger textCenter"></div>
					<el-form-item class="mt20" label="匹配模式">							
						<el-select v-model="form.pay_match_type" placeholder="请选择匹配模式">
							<el-option :key="2" label="金额匹配" :value="2"></el-option>
							<el-option :key="3" label="姓名匹配" :value="3"></el-option>
						</el-select>
					</el-form-item>
					
				</el-form>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmitMatch()">确 定</el-button>
				</div>
			</el-tab-pane>
			<el-tab-pane label="设置单笔限额" name='singleLimitTab' :disabled="dialogloading">
				<div v-if="titleName" class="mb20">{{titleName}}</div>
				<el-form :label-position="labelPosition" label-width="80px" class="mb20">
					<el-table :data="singleLimitData" stripe size="mini" border  max-height="500px">
						<el-table-column prop="name" label="通道名称" align="center"></el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<span class="mr10">最小限额:</span><el-input style="width: 80px;" v-model="scope.row.min_gold" placeholder="请输入最小限额" class="mr20"></el-input>
								<span class="mr10">最大限额:</span><el-input style="width: 80px;" v-model="scope.row.max_gold" placeholder="请输入最大限额" class="mr20"></el-input>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination :hide-on-single-page="true" @size-change="limithandleSizeChange" @current-change="limithandleCurrentChange"
						:current-page="limitpage" :page-sizes="limitpageSizesArr" :page-size="limitpageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="limittotal"></el-pagination>
				</el-form>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmitSingleLimit()">确 定</el-button>
				</div>
			</el-tab-pane>
			<el-tab-pane label="支付权限配置" name='payPowerTab' :disabled="dialogloading">
				<div v-if="titleName" class="mb20">{{titleName}}</div>
				<el-form :label-position="labelPosition" label-width="80px" class="mb20">
					<el-form-item label="通道列表"></el-form-item>
					<div>
						<template v-for="(item,index) in payWay">
							<el-checkbox v-model="item.state">{{item.name}}</el-checkbox>
						</template>

					</div>
				</el-form>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmitPayPower()">确 定</el-button>
				</div>
			</el-tab-pane>
			<el-tab-pane label="余额留存上限" name='keepGoldTab' :disabled="dialogloading">
				<div v-if="titleName" class="mb20">{{titleName}}</div>
				<el-form :label-position="labelPosition" label-width="120px" class="mb20">
					<el-form-item class="mt20" label="余额留存上线">
						<el-input v-model="item.trader_keep_gold" placeholder="请输入余额"></el-input>
					</el-form-item>
				</el-form>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmitKeepGold()">确 定</el-button>
				</div>
			</el-tab-pane>
			
			
		</el-tabs>
		
		</el-dialog>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				dialogloading: false,
				dialogVisible: false,
				date: [],
				input: '',
				tableData: [],
				dataTotal: {},
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				feepageSizesArr: [20, 50, 100, 200],
				feepageSize: 20,
				feetotal: 0,
				feepage: 1,
				
				limitpage:1,
				limitpageSizesArr:[20, 50, 100, 200],
				limitpageSize:20,
				limittotal:0,
				singleLimitData:[],
			
				//新增
				labelPosition: 'right',
				form: {
					agent: '',
					username: '',
					// business_no:'',
					business_name: '',
					password: '',
					note: '',
					name:'',
					pay_match_type:''
				},
				status: true,
				status_order: true,
				status_pay: true,
				// status_pay_api: true,
				status_gold_limit: true,
				float_money: true,
				// password: '',
				passwordSure: '', //确认密码
				type: '', //new  新增   edt修改
				id: '',
				title: '新增代理',
				titleName: '',
				submitText: '新 增',
				fee: '',
				agentNameArr: [{
					value: '',
					label: '请选择代理'
				}],
				//手续费
				feeData: [],
				business_no: '',
				amount: '',
				//当前余额
				// money: '',
				//当前冻结余额
				// money_uncertain: '',
				payWay: [],
				
				//Log 日志
				item:{},
				logName:'recharge',
				logdialog:false,
				logLoding:false,
				//冻结|解冻  日志
				rechargeLog:[],
				reLogpageSizesArr:[20, 50, 100, 200],
				reLogpageSize:20,
				reLogtotal:0,
				reLogpage:1,
				//限额	日志		
				deductionLog:[],
				dedLogpageSizesArr:[20, 50, 100, 200],
				dedLogpageSize:20,
				dedLogtotal:0,
				dedLogpage:1,
				//费率日志
				feeLog:[],
				feeLogpageSizesArr:[20, 50, 100, 200],
				feeLogpageSize:20,
				feeLogtotal:0,
				feeLogpage:1,
				//匹配模式
				matchLog:[],
				matchLogpageSizesArr:[20, 50, 100, 200],
				matchLogpageSize:20,
				matchLogtotal:0,
				matchLogpage:1,
				height:0,
				// 增减冻结余额.
				unmoneyRadio: 'IN',
				unmoneyAmount: '',
				//全部编辑

				editAllVisible:false,
				editAllName:'',
				index:0,
			}
		},
		created() {
			this.traderListtList()
			this.agentList()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - 100
			})
		},
		computed: {
			money_uncertain () {			
				return this.item.money_uncertain;
			},
			money(){
				return this.item.money
			}
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			agentList() {
				this.$api.agentList({
					// page: this.page,
					// size: this.pageSize
				}).then(res => {
					if (res.status === 1) {
						this.agentNameArr = res.data.data.map(item => {
							let obj = {
								value: item.id,
								label: item.name
							}
							return obj
						})
					} else {
						this.$message({
							type: 'error',
							message: '代理列表查询失败'
						})
					}
					console.log(res)
				}).catch(error => {})
			},
			traderListtList() {
				// this.loading = true
				this.$api.traderList({
					page: this.page,
					size: this.pageSize
				}).then(res => {
					this.loading = false
					if (res.status === 1) {
						let totalCurrent = {
							agent:'当页总计',
							money: res.data.current_total_money,
							money_uncertain:res.data.current_total_money_uncertain
						}
						let totalAll = {
						
							agent:'总计',
							money: res.data.all_total_money,
							money_uncertain:res.data.all_total_money_uncertain
						}
						res.data.data.push(totalCurrent,totalAll)
						this.tableData = res.data.data
						this.total = res.data.total
						// this.dataTotal = {
						// 	all_total_money: res.data.all_total_money,
						// 	all_total_money_uncertain: res.data.all_total_money_uncertain,
						// 	current_total_money: res.data.current_total_money,
						// 	current_total_money_uncertain: res.data.current_total_money_uncertain,
						// }
						

						if(this.editAllVisible){	
							this.item = this.tableData[this.index]//弹窗打开时 刷新弹窗数据
						}
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					console.log(res)
				}).catch(error => {
					this.loading = false
					// this.$message({
					// 	type: 'error',
					// 	message: error
					// })
				})
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.page =1
				this.pageSize = val
				this.traderListtList()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page = val
				this.traderListtList()
			},
			feehandleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.feepage = 1
				this.feepageSize = val
				this.traderFeeList()
			},
			feehandleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.feepage = val
				this.traderFeeList()
			},
			limithandleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.limitpage=1
				this.limitpageSize = val
				this.traderLimitList()
			},
			limithandleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.limitpage = val
				this.traderLimitList()
			},
			addAccount(type, item) {
				this.type = type
				if (item) {
					this.item = item;
					this.id = item.id;
					this.business_no = item.business_no;
					this.form.agent = item.agent;
					this.form.name = item.name;
					this.form.username = item.username;
					this.form.business_name = item.business_name;
					this.status = item.status == 0 ? true : false;
					this.status_order = item.status_order == 0 ? true : false;
					this.status_pay = item.status_pay == 0 ? true : false;
					// this.status_pay_api = item.status_pay_api == 0 ? true : false;
					this.status_gold_limit = item.status_gold_limit == 0 ? true : false;
					this.float_money = item.float_money == 1 ? true : false;
					// this.money = item.money;
					// this.money_uncertain = item.money_uncertain;
					this.form.pay_match_type = item.pay_match_type;
					this.submitText = '确 定'
				}
				if (this.type === 'new') {
					this.title = '新增商家'
					this.submitText = '新 增'
					this.dialogVisible = true

				} else if (this.type === 'edt') {
					this.title = '编辑商家'
					this.submitText = '修 改'
				} else if (this.type === 'password') {
					this.title = '重置商家密码'
					this.submitText = '重 置'
				} else if (this.type === 'fee') {
					this.title = '手续费'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
					this.traderFeeList()
				} else if (this.type === 'singleLimit') {
					this.title = '设置单笔限额'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
					this.traderLimitList()
				} else if (this.type === 'payPower') {
					this.title = '支付权限配置'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
					this.traderPayList(item);
				} else if (this.type === 'payPassword') {
					this.title = '代付密码'
					// this.titleName = item.business_name+`  `+item.business_no
					this.submitText = '确 定'
				} else if (this.type === 'freeze') {
					this.title = '冻结余额'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
				} else if (this.type === 'unFreeze') {
					this.title = '解冻余额'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
				} else if (this.type === 'inOutUnMoney') {
					this.title = '增减冻结余额'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
				}else if (this.type === 'whitelist') {
					this.title = '商家白名单'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
				}else if (this.type === 'match') {
					this.title = '匹配模式'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
				}else if (this.type === 'keepGold') {
					this.title = '余额留存上限'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
				}

			},
			close() {
				this.dialogVisible = false
				this.id = "";
				this.business_no = "";
				this.amount = "";
				this.form.name = "";
				this.form.username = "";
				this.form.password="";
				this.passwordSure="";
				this.form.agent = "";
				this.form.business_name = "";
				this.status = true;
				this.status_order = true;
				this.status_pay = true;
				// this.status_pay_api = true;
				this.status_gold_limit = true;
				this.float_money = true;
				this.feeData = [];
				this.singleLimitData = [];
				// this.money = '';
				// this.money_uncertain = '';
				this.titleName = '';
				this.payWay = [];
				this.item = {};
				this.unmoneyAmount = ''
				this.unmoneyRadio = 'IN'
			},
			//手续费列表查询
			traderFeeList() {
				let data = {
					trader_id: this.id,
					page: this.feepage,
					size: this.feepageSize
				}
				// this.dialogloading = true
				this.$api.traderFeeList(data).then(res => {
					this.dialogloading = false
					if (res.status === 1) {
						this.feeData = res.data.data
						this.feetotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			//手续费列表查询
			traderLimitList() {
				let data = {
					trader_id: this.id,
					page: this.limitpage,
					size: this.limitpageSize
				}
				
				// this.dialogloading = true
				this.$api.traderLimitList(data).then(res => {
					this.dialogloading = false
					if (res.status === 1) {
						this.singleLimitData = res.data.data
						this.limittotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			//新增
			onSubmit() {
				if (this.type === 'new') {
					//新增商家
					if (isEmpty(this.form.agent, '请选择代理')) {
						return
					}
					if (isEmpty(this.form.username, '请输入商家名')) {
						return
					}
					if (isIncludeHanzi(this.form.username, '商家名不能包含汉字')) {
						return
					}
					if (isEmpty(this.form.business_name, '请输入商家名称')) {
						return
					}
					if (isEmpty(this.form.password, '请输入商家密码')) {
						return
					}
					if (this.form.password != this.passwordSure) {
						this.$message({
							type: 'error',
							message: '两次输入密码不一致'
						})
						return
					}
					let data = {
						note:this.form.name,
						agent: this.form.agent,
						trader_username: this.form.username,
						business_name: this.form.business_name,
						password: this.$md5(this.form.password),
						status: this.status ? 0 : 1,
						status_order: this.status_order ? 0 : 1,
						status_pay: this.status_pay ? 0 : 1,
						// status_pay_api: this.status_pay_api ? 0 : 1,
						status_gold_limit: this.status_gold_limit ? 0 : 1,
						float_money: this.float_money ? 1 : 2,
					}
					this.dialogloading = true
					this.$api.addTrade(data).then(res => {
						console.log(res)
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.close();
							this.page = 1;
							// setTimeout(()=>{
							this.traderListtList()
							// },200)

						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
						// console.log(res)
					}).catch(error => {
						this.dialogloading = false
					})
				} else if (this.type === 'edt') {
					//编辑
					if (isEmpty(this.form.business_name, '请输入商家名称')) {
						return
					}
					let data = {
						trader_id: this.id,
						note:this.form.name,
						// agent: this.form.agent,						
						business_name: this.form.business_name,
						status: this.status ? 0 : 1,
						status_order: this.status_order ? 0 : 1,
						status_pay: this.status_pay ? 0 : 1,
						// status_pay_api: this.status_pay_api ? 0 : 1,
						status_gold_limit: this.status_gold_limit ? 0 : 1,
						float_money: this.float_money ? 1 : 2,
					}
					this.dialogloading = true
					this.$api.editTrader(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.close();
							this.traderListtList()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
						// console.log(res)
					}).catch(error => {
						this.dialogloading = false
					})
				} else if (this.type === 'payPassword') {
					this.$confirm(`确认设置代付密码吗?`, `提示`, {
						confirmButtonText: `确认`,
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						if (isEmpty(this.form.password, '代付密码不能为空')) {
							return
						}
						if (this.form.password != this.passwordSure) {
							this.$message({
								type: 'error',
								message: '两次输入密码不一致'
							})
							return
						}
						let data = {
							id: this.id,
							business_no: this.business_no,
							password: this.$md5(this.form.password),
						}
						this.dialogloading = true
						this.$api.setTraderPayPassword(data).then(res => {
							this.dialogloading = false
							if (res.status === 1) {
								this.$message({
									type: 'success',
									message: '代付密码设置成功'
								})
								this.close()
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						}).catch(error => {
							this.dialogloading = false
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: `已取消设置代付密码`
						});
					});
				} 
				else if (this.type === 'freeze' || this.type === 'unFreeze') {
					//新增商家
					this.$confirm(`确认${this.type === 'freeze'?'冻结金额':'解冻金额'}?`, `提示`, {
						confirmButtonText: `确认`,
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						if (!isMoney(this.amount, '余额只能是数字，最多输入两位小数')) {
							return
						}
						let data = {
							id: this.id,
							business_no: this.business_no,
							amount: this.amount,
							type: this.type === 'freeze' ? 'OUT' : 'IN'
						}
						this.dialogloading = true
						this.$api.setAmount(data).then(res => {
							this.dialogloading = false
							if (res.status === 1) {
								this.$message({
									type: 'success',
									message: res.msg
								})
								this.traderListtList()
								this.close()
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						}).catch(error => {
							this.dialogloading = false
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: `已取消${this.type === 'freeze'?'冻结金额':'解冻金额'}`
						});
					});
				}
				else if (this.type === 'inOutUnMoney') {
					this.$prompt('请输入谷歌验证码', '提示', {
					          confirmButtonText: '确定',
					          cancelButtonText: '取消',
					        }).then(({ value }) => {
								this.inOutUnMoneyFun(value)
					        }).catch(() => {
					          this.$message({
					            type: 'info',
					            message: '取消输入'
					          });       
					        });
			
				} else if (this.type === 'fee') {
					let errorString = ''
					var pattern = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/

					for (let i = 0; i < this.feeData.length; i++) {
						if (this.feeData[i].fee === '' || (!pattern.test(this.feeData[i].fee) && this.feeData[i].fee != 0 && this.feeData[
								i].fee != '0')) {
							errorString = '手续费只能是数字，最多两位小数'
							break;
						} else if (this.feeData[i].fee < this.feeData[i].agent_fee) {
							errorString = this.feeData[i].name + ': 商家手续费不能低于代理手续费!'
							break;
						}
					}
					if (errorString != '') {
						this.$message({
							type: 'error',
							message: errorString
						})
						return
					}
					let feeData = this.feeData.map(item => {
						return {
							"pay_type": item.pay_type,
							"fee": item.fee,
							"fee_num": item.fee_num
						}
					})
					this.dialogloading = true
					//设置手续费

					let data = {
						trader_id: this.id,
						data: JSON.stringify(feeData)
					}
					console.log('feedata', data)
					this.$api.setTraderFee(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: '手续费设置成功'
							})
							this.close()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				} 
				else if (this.type === 'singleLimit') {
					let errorString = ''
					var pattern = /^[0-9]*$/

					for (let i = 0; i < this.singleLimitData.length; i++) {
						if (this.singleLimitData[i].min_gold === '' || !pattern.test(this.singleLimitData[i].min_gold)) {
							errorString = '最小限额只能是数字'
							break;
						} 
						if (Number(this.singleLimitData[i].min_gold)<0) {
							errorString = '最小限额不能小于0'
							break;
						} 
						if (this.singleLimitData[i].max_gold === '' || !pattern.test(this.singleLimitData[i].max_gold)) {
							errorString = '最大限额只能是数字'
							break;
						} 
						if (Number(this.singleLimitData[i].max_gold)<0) {
							errorString = '最大限额不能小于0'
							break;
						} 
						if (Number(this.singleLimitData[i].max_gold)<Number(this.singleLimitData[i].min_gold)) {
							errorString = '最大限额不能小于最小限额'
							break;
						} 
					}
					if (errorString != '') {
						this.$message({
							type: 'error',
							message: errorString
						})
						return
					}
					let singleLimitData = this.singleLimitData.map(item => {
						return {
							"pay_type": item.pay_type,
							"min_gold": item.min_gold,
							"max_gold": item.max_gold
						}
					})
					this.dialogloading = true
					//设置手续费

					let data = {
						trader_id: this.id,
						data: JSON.stringify(singleLimitData)
					}
					// console.log('feedata', data)
					this.$api.setTraderGold(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: '单笔限额设置成功'
							})
							this.close()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				} 
				else if (this.type === 'payPower') {
					let pay_type = this.payWay.filter(item => {
						if (item.state) {
							return item
						}
					}).map(item2 => {
						return item2.id
					})
					let data = {
						pay_type: JSON.stringify(pay_type),
						trader_id: this.id,
						business_no: this.business_no,
					}
					this.dialogloading = true;
					this.$api.setTraderPayWay(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: '支付权限配置成功'
							})
							this.close()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
					console.log(data)

				}else if(this.type == 'whitelist'){
					let data = {
						id:this.item.id,
						login_ip:this.item.login_whitelist,
						repay_ip:this.item.repay_whitelist
					}
					this.dialogloading = true;
					this.$api.setWhitelist(data).then(res=>{
						this.dialogloading = false
						if(res.status==1){
							this.$message({type:"success",message:"白名单设置成功"})
							this.close()
						}else{
							this.$message({type:"error",message:res.msg})
						}
					}).catch(error=>{
						this.dialogloading = false
					})
				}else if(this.type == 'match'){
					let data = {
						id:this.item.id,
						type:this.form.pay_match_type,
						business_no:this.item.business_no
						// repay_ip:this.item.repay_whitelist
					}
					console.log(data)
					this.dialogloading = true;
					this.$api.setTraderMatch(data).then(res=>{
						this.dialogloading = false
						if(res.status==1){
							this.traderListtList()
							this.$message({type:"success",message:"设置成功"})
							this.close()
						}else{
							this.$message({type:"error",message:res.msg})
						}
					}).catch(error=>{
						this.dialogloading = false
					})
				}
				
				else if (this.type === 'keepGold') {
					//新增商家
					this.$confirm(`确认设置留存上限`, `提示`, {
						confirmButtonText: `确认`,
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						if (!isMoney(this.item.trader_keep_gold, '余额只能是数字，最多输入两位小数')) {
							return
						}
						let data = {
							id: this.id,
							amount: this.item.trader_keep_gold
						}
						this.dialogloading = true
						this.$api.setTraderKeepGold(data).then(res => {
							this.dialogloading = false
							if (res.status === 1) {
								this.$message({
									type: 'success',
									message: res.msg
								})
								this.traderListtList()
								this.close()
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						}).catch(error => {
							this.dialogloading = false
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: `已取消设置留存上限`
						});
					});
				}
		
			},
			//重置商家密码
			resPassword(item) {
				this.$confirm('确认重置商家' + item.business_name + ' ' + item.business_no + '的密码?', '重置密码', {
					confirmButtonText: '重置',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id,
						business_no: item.business_no
					}
					this.loading = true
					this.$api.setTraderPassword(data).then(res => {
						console.log(res)
						this.loading = false
						if (res.status === 1) {
							this.$alert('新密码为:' + res.data, '重置密码成功', {
								confirmButtonText: '确定',
							});
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}

					}).catch(error=>{
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消重置商家密码'
					});
				});

			},
			traderToggle(item) {
				let status = item.status === 0 ? '冻结' : '启用'
				this.$confirm(`确认${status}商家${item.business_name}  ${item.business_no}`, `${status}`, {
					confirmButtonText: `${status}`,
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id,
						business_no: item.business_no
					}
					this.loading = true
					this.$api.traderToggle(data).then(res => {
						this.loading = false
						console.log(res)
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.traderListtList()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}

					}).catch(error => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消${status}商家`
					});
				});

			},
			traderPayList(item) {
				let data = {
					trader_id: item.id,
					business_no: item.business_no
				}
				this.dialogloading = true;
				this.$api.traderPayList(data).then(res => {
					this.dialogloading = false;
					if (res.status === 1) {
						this.payWay = res.data.map(items => {
							if (items.status === 1) {
								items.state = true;
							} else {
								items.state = false;
							}
							return items
						})
						console.log(this.payWay)
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					// console.log(res)
				}).catch(error => {
					this.dialogloading = false
				})

			},
			//重置谷歌验证码
			setMemberGoogleKey(item){
				this.$confirm(`确认要重置${item.username}的谷歌验证码吗?`, '重置谷歌验证码', {
					confirmButtonText: '重 置',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// 限制充值
					this.loading = true;
					let data = {
						id: item.id
					}
					this.$api.setMemberGoogleKey(data).then(res => {
						this.loading = false;
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false;
					})
				
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消重置谷歌验证码`
					});
				});
			},
			
			//日志
			showLog(item){
				this.logdialog = true
				this.item = item
				this.rechargeLogList();
			},
			logclose(){
				this.logdialog = false
				this.logName = 'recharge'
				this.rechargeLog = []
				this.deductionLog = []
				this.feeLog = []
				this.item = {}
			},
			getlogData(val){
				if (val.name == 'recharge') {
					if(this.rechargeLog.length == 0){
						this.rechargeLogList();
					}
				}
				if(val.name == "deduction"){
					if(this.deductionLog.length == 0){
						this.deductionLogList();
					}
				}
				if(val.name == "fee"){
					if(this.feeLog.length == 0){
						this.feeLogList();
					}
				}
				if(val.name == "match"){
					if(this.matchLog.length == 0){
						this.matchLogList();
					}
				}
			},
			// 增减冻结余额
			inOutUnMoneyFun(googleKey){
				
				this.$confirm(`确认此操作吗?`, `提示`, {
					confirmButtonText: `确认`,
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if (!isMoney(this.unmoneyAmount, '余额只能是数字，最多输入两位小数')) {
						return
					}
					let data = {
						id: this.id,
						business_no: this.business_no,
						amount: this.unmoneyAmount,
						type: this.unmoneyRadio,
						googleCode: googleKey,
					}
					this.dialogloading = true
					this.$api.setUncertainAmount(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.traderListtList()
							this.unmoneyAmount = ''
							// this.close()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消增加冻结余额`
					});
				});
			},
			//冻结|解冻
			rechargeLogList(){
				let data = {
					page:this.reLogpage,
					size:this.reLogpageSize,
					id:this.item.id,
					type:1,
				}
				this.logLoding = true;
				this.$api.memberlogList(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.rechargeLog = res.data.data;
						this.reLogtotal = res.data.total
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			reLoghandleSizeChange(){
				this.reLogpage = 1
				this.reLogpageSize = val
				this.rechargeLogList()
			},
			reLoghandleCurrentChange(){
				console.log(`当前页: ${val}`);
				this.reLogpage = val
				this.rechargeLogList()
			},
			//限额
			deductionLogList(){
				let data = {
					page:this.dedLogpage,
					size:this.dedLogpageSize,
					id:this.item.id,
					type:2,
				}
				this.logLoding = true;
				this.$api.memberlogList(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.deductionLog = res.data.data;
						this.dedLogtotal = res.data.total
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			dedLoghandleSizeChange(){
				
				this.dedLogpage = 1
				this.dedLogpageSize = val
				this.deductionLogList()
			},
			dedLoghandleCurrentChange(){
				console.log(`当前页: ${val}`);
				this.dedLogpage = val
				this.deductionLogList()
			},
			//手续费
			feeLogList(){
				let data = {
					page:this.feeLogpage,
					size:this.feeLogpageSize,
					id:this.item.id,
					type:3,
				}
				this.logLoding = true;
				this.$api.memberlogList(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.feeLog = res.data.data;
						this.feeLogtotal = res.data.total
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			feeLoghandleSizeChange(){				
				this.feeLogpageSize = val
				this.feeLogList()
			},
			feeLoghandleCurrentChange(){
				console.log(`当前页: ${val}`);
				this.feeLogpage = val
				this.feeLogList()
			},
				//商户匹配设置
			matchLogList(){
				let data = {
					page:this.matchLogpage,
					size:this.matchLogpageSize,
					id:this.item.id,
					type:4,
				}
				this.logLoding = true;
				this.$api.memberlogList(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.matchLog = res.data.data;
						this.matchLogtotal = res.data.total
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			matchLoghandleSizeChange(){				
				this.matchLogpageSize = val
				this.matchLogList()
			},
			matchLoghandleCurrentChange(){
				console.log(`当前页: ${val}`);
				this.matchLogpage = val
				this.matchLogList()
			},

			//全部编辑
			//关闭弹窗
			closeEditAll(){
				this.editAllVisible = false
				this.index = 0
				this.id = "";
				this.business_no = "";
				this.amount = "";
				this.form.name = "";
				this.form.username = "";
				this.form.password="";
				this.passwordSure="";
				this.form.agent = "";
				this.form.business_name = "";
				this.status = true;
				this.status_order = true;
				this.status_pay = true;
				this.status_gold_limit = true;
				this.float_money = true;
				this.feeData = [];
				this.singleLimitData = [];
				// this.money = '';
				// this.money_uncertain = '';
				this.titleName = '';
				this.payWay = [];
				this.item = {};
				this.unmoneyAmount = ''
			},
			//切换tabs
			changeEdit(val){
				if(this.editAllName == 'singleLimitTab'){
					this.traderLimitList()
				}
				if(this.editAllName == 'feeTab'){
					this.traderFeeList()
				}
				if(this.editAllName == 'payPowerTab'){
					this.traderPayList(this.item);
				}
				
				
				// console.log(val,this.editAllName,'==================')
			},
			//打开弹窗
			openEditAll(item,index){
				this.editAllVisible = true
				this.item = item
				this.index = index
				//打开弹窗 默认展示费率tab
				this.editAllName = 'feeTab'
				this.addAccount('fee',this.item)
			},
			//费率提交
			onSubmitFee(){
				let errorString = ''
					var pattern = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/

					for (let i = 0; i < this.feeData.length; i++) {
						if (this.feeData[i].fee === '' || (!pattern.test(this.feeData[i].fee) && this.feeData[i].fee != 0 && this.feeData[
								i].fee != '0')) {
							errorString = '手续费只能是数字，最多两位小数'
							break;
						} else if (this.feeData[i].fee < this.feeData[i].agent_fee) {
							errorString = this.feeData[i].name + ': 商家手续费不能低于代理手续费!'
							break;
						}
					}
					if (errorString != '') {
						this.$message({
							type: 'error',
							message: errorString
						})
						return
					}
					let feeData = this.feeData.map(item => {
						return {
							"pay_type": item.pay_type,
							"fee": item.fee,
							"fee_num": item.fee_num
						}
					})
					this.dialogloading = true
					//设置手续费

					let data = {
						trader_id: this.id,
						data: JSON.stringify(feeData)
					}
					this.$api.setTraderFee(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: '手续费设置成功'
							})
							//手续费设置成功刷新数据
							this.traderFeeList()
							// this.close()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
			},
			//冻结金额提交
			onSubmitFreeze(type){
				this.$confirm(`确认冻结金额?`, `提示`, {
						confirmButtonText: `确认`,
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						if (!isMoney(this.amount, '余额只能是数字，最多输入两位小数')) {
							return
						}
						let data = {
							id: this.id,
							business_no: this.business_no,
							amount: this.amount,
							type: type
						}
						this.dialogloading = true
						this.$api.setAmount(data).then(res => {
							this.dialogloading = false
							if (res.status === 1) {
								this.$message({
									type: 'success',
									message: res.msg
								})
								this.traderListtList()
				 				// this.addAccount('freeze',this.item)
								 this.amount = ''
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						}).catch(error => {
							this.dialogloading = false
						})
					}).catch((error) => {
						console.log(error)
						this.$message({
							type: 'info',
							message: `已取消冻结金额}`
						});
					});
			},
			//增减冻结余额
			
			onSubmitInOutUnMoney(){
				this.$prompt('请输入谷歌验证码', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(({ value }) => {
					this.inOutUnMoneyFun(value)
				}).catch(() => {
					this.$message({
					type: 'info',
					message: '取消输入'
					});       
				});
			},
			//编辑商家
			onSubmitEdit(){
				//编辑
				if (isEmpty(this.form.business_name, '请输入商家名称')) {
						return
					}
					let data = {
						trader_id: this.id,
						note:this.form.name,
						// agent: this.form.agent,						
						business_name: this.form.business_name,
						status: this.status ? 0 : 1,
						status_order: this.status_order ? 0 : 1,
						status_pay: this.status_pay ? 0 : 1,
						// status_pay_api: this.status_pay_api ? 0 : 1,
						status_gold_limit: this.status_gold_limit ? 0 : 1,
						float_money: this.float_money ? 1 : 2,
					}
					this.dialogloading = true
					this.$api.editTrader(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							// this.close();
							this.traderListtList()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
						// console.log(res)
					}).catch(error => {
						this.dialogloading = false
					})
			},
			//匹配模式
			onSubmitMatch(){
				let data = {
						id:this.item.id,
						type:this.form.pay_match_type,
						business_no:this.item.business_no
						// repay_ip:this.item.repay_whitelist
					}
				// console.log(data)
				this.dialogloading = true;
				this.$api.setTraderMatch(data).then(res=>{
					this.dialogloading = false
					if(res.status==1){
						this.traderListtList()
						this.$message({type:"success",message:"设置成功"})
						// this.close()
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.dialogloading = false
				})
			},
			//设置单笔限额
			onSubmitSingleLimit(){
				let errorString = ''
					var pattern = /^[0-9]*$/

					for (let i = 0; i < this.singleLimitData.length; i++) {
						if (this.singleLimitData[i].min_gold === '' || !pattern.test(this.singleLimitData[i].min_gold)) {
							errorString = '最小限额只能是数字'
							break;
						} 
						if (Number(this.singleLimitData[i].min_gold)<0) {
							errorString = '最小限额不能小于0'
							break;
						} 
						if (this.singleLimitData[i].max_gold === '' || !pattern.test(this.singleLimitData[i].max_gold)) {
							errorString = '最大限额只能是数字'
							break;
						} 
						if (Number(this.singleLimitData[i].max_gold)<0) {
							errorString = '最大限额不能小于0'
							break;
						} 
						if (Number(this.singleLimitData[i].max_gold)<Number(this.singleLimitData[i].min_gold)) {
							errorString = '最大限额不能小于最小限额'
							break;
						} 
					}
					if (errorString != '') {
						this.$message({
							type: 'error',
							message: errorString
						})
						return
					}
					let singleLimitData = this.singleLimitData.map(item => {
						return {
							"pay_type": item.pay_type,
							"min_gold": item.min_gold,
							"max_gold": item.max_gold
						}
					})
					this.dialogloading = true
					//设置手续费

					let data = {
						trader_id: this.id,
						data: JSON.stringify(singleLimitData)
					}
					// console.log('feedata', data)
					this.$api.setTraderGold(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: '单笔限额设置成功'
							})
							// this.close()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
			},
			//支付权限配置
			onSubmitPayPower(){
				let pay_type = this.payWay.filter(item => {
						if (item.state) {
							return item
						}
					}).map(item2 => {
						return item2.id
					})
					let data = {
						pay_type: JSON.stringify(pay_type),
						trader_id: this.id,
						business_no: this.business_no,
					}
					this.dialogloading = true;
					this.$api.setTraderPayWay(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: '支付权限配置成功'
							})
							// this.close()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
			},
			//余额留存上线
			onSubmitKeepGold(){
				this.$confirm(`确认设置留存上限`, `提示`, {
						confirmButtonText: `确认`,
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						if (!isMoney(this.item.trader_keep_gold, '余额只能是数字，最多输入两位小数')) {
							return
						}
						let data = {
							id: this.id,
							amount: this.item.trader_keep_gold
						}
						this.dialogloading = true
						this.$api.setTraderKeepGold(data).then(res => {
							this.dialogloading = false
							if (res.status === 1) {
								this.$message({
									type: 'success',
									message: res.msg
								})
								this.traderListtList()
								// this.close()
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						}).catch(error => {
							this.dialogloading = false
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: `已取消设置留存上限`
						});
					});
			}
			
		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - 100
				})
			}
		},
		
	}
</script>

<style lang="scss" scoped="scoped">
	.formBox {
		width: 80%;
		margin: 0 auto;
	}
	
 /* 滚动条宽度 */
	::-webkit-scrollbar {
		width: 0px;
	}
	table tbody {
		display: block;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		border-bottom: solid 1px #ddd;
	}	 
	table tbody tr:last-child td{
		border-bottom: none;
	}	 
	table thead tr, table tbody tr, table tfoot tr {
		box-sizing: border-box;
		table-layout: fixed;
		display: table;
		width: 100%;
	}
	table td{
		word-break:break-all;
	}
	.maxwidth{
		width: 240px;
	}
</style>
