<template>
	<div>
		<el-table :max-height="height" :data="tableData"  stripe v-loading="loading" style="width: 700px;"  size="mini">
			<el-table-column prop="name" label="支付类型" ></el-table-column>
			<el-table-column  label="状态" >
				<template slot-scope="scope">
					<span :class="scope.row.state== 0?'csuccess':'cdanger'">{{scope.row.state== 0?'启用':'停用'}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="" label="操作" >
				<template slot-scope="scope">
					  <el-button :type="scope.row.state== 0 ? 'danger':'primary'" size="mini" @click="payTypeSetState(scope.row)">{{scope.row.state== 0?'停用':'启用'}}</el-button>
					  <el-button size="mini" @click="openSetFee(scope.row)">设置通道</el-button>
					  
				</template>
			</el-table-column>
		</el-table>
		<div>
			<el-dialog class="dialog" top="200px" :title="title" :visible.sync="dialogVisible" @close="close"
			 :close-on-click-modal="false" center>
				<div class="formBox" v-loading="dialogloading">
					<div class="mb20">
						<el-form :label-position="labelPosition" label-width="80px">
							<el-form-item label="支付类型">
								<span>{{item.name}}</span>
								<!-- <el-input :disabled="type != 'new'" v-model="item.name" placeholder="请输入代理账号"></el-input> -->
							</el-form-item>
							<el-form-item label="手续费率(‰)">
								<el-input v-model="fee" placeholder="请输入手续费率"></el-input>
							</el-form-item>
							<el-form-item label="单笔手续费用">
								<el-input v-model="fee_num" placeholder="请输入单笔手续费用"></el-input>
							</el-form-item>
							<el-form-item label="派单频率(秒)">
								<el-input v-model="frequency" placeholder="请输入派单频率(秒)"></el-input>
							</el-form-item>
							
						</el-form>
					</div>
					<div class="textCenter"><el-button type="primary" size="mini" @click="payTypeSetFee()">确定</el-button></div>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				tableData:[],
				item:{},
				title:'设置费率',
				dialogVisible:false,
				dialogloading:false,
				labelPosition:'right',
				fee:'',
				fee_num:'',
				height:0,
				frequency:'',
			}
		},
		created() {
			this.payTypeAll()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 
			})
		},
		methods:{
			//查看列表
			payTypeAll() {
				let data = {}
				// this.loading = true
				this.$api.payTypeAll(data).then(res => {
					this.loading = false
					if (res.status == 1) {
						this.tableData = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
			
				}).catch(error => {
					this.loading = false
				})
			},
			payTypeSetState(item){
				let data = {
					pay_type:item.id,
					state:item.state==0?1:0,
				}
				this.$confirm(`确认${item.state ==0?'停用':'启用'}${item.name}`, '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true
					this.$api.payTypeSetState(data).then(res => {
						this.loading = false
						console.log(res)
						if (res.status === 1) {
							this.$alert(`${item.name}已${item.state ==0?'停用':'启用'}`, `提示`, {
								confirmButtonText: '确定',
							});
							this.payTypeAll()
						} else {
							this.$message({
								type: 'info',
								message: res.msg
							});
						}
				
					}).catch(error=>{
						this.loading = false
					})
					
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消${item.state ==0?'停用':'启用'}${item.name}`
					});
				});
			},
			close(){
				this.item = {};
				this.dialogVisible = false;
				this.fee = ''
				this.fee_num = ''
				this.frequency = ''
			},
			openSetFee(item){
				this.dialogVisible = true;
				this.item = item
				this.fee = item.fee
				this.fee_num = item.fee_num
				this.frequency = item.frequency
			},
			payTypeSetFee(item){
				if (isEmpty(this.fee, '请输入手续费')) {
					return
				}
				if (isEmpty(this.fee_num, '请输入单笔手续费用')) {
					return
				}
				if(!isMoney(this.fee,'手续费只能是数字，最多输入两位小数')){
					return
				}
				this.dialogloading = true;
				let data = {
					pay_type:this.item.id,
					fee:this.fee,
					fee_num: this.fee_num,
					frequency: this.frequency
				}
				this.$api.payTypeSetFee(data).then(res=>{
					this.dialogloading = false;
					if(res.status == 1){
						this.$message({type:'success',message:'费率设置成功'})
						this.close()
						this.payTypeAll()
					}else{
						this.$message({type:'success',message:res.msg})
					}
				}).catch(error=>{
					this.dialogloading = false;
				})
			}
		}
	}
</script>

<style>
</style>
